<ng-container>
    <div>
        <div class="card-body row py-3 px-3 user-notes-hldr">
            <form [formGroup]="addNoteForm" (ngSubmit)="onSubmit()" class="w-100">
                <div class="form-group mb-3">
                    <p-editor formControlName="addNote" [style]="{ height: '150px' }" [ngClass]="{ 'is-invalid': submitted && f.addNote.errors }"></p-editor>

                    <!-- <textarea formControlName="addNote" class="form-control form-control-solid py-4 px-6 rounded-lg" rows="10" [ngClass]="{ 'is-invalid': submitted && f.addNote.errors }"></textarea> -->
                    <div *ngIf="submitted && f.addNote.errors" class="invalid-feedback">
                        <div *ngIf="f.addNote.errors.required">Note is required</div>
                    </div>
                </div>
                <div class="flex flex-column justify-content-end align-items-end">
                    <button pButton pRipple label="Save" class="p-button-primary" [disabled]="loading"></button>
                </div>
            </form>
            <div *ngIf="!userLoader" class="w-100">
                <div class="flex flex-column w-100 note-list-view" *ngIf="noteList && noteList.length">
                    <h4 class="mb-0">Notes History:</h4>
                    <ul class="px-0 mx-0">
                        <li *ngFor="let note of noteList" class="flex flex-row justify-content-between py-3 w-100">
                            <div class="flex flex-row w-100">
                                <div style="width: 90%">
                                    <h4 class="font-weight-bold font-size-lg">
                                        {{ note.createdBy }} - <span class="font-size-sm">{{ note.createdDate | utcToLocal: 'MM-DD-YYYY h:mm A' }} </span>
                                    </h4>
                                    <div class="font-size-lg editor-tags-alignment" [innerHTML]="note.value"></div>
                                </div>

                                <div class="pl-5" style="width: 10%">
                                    <i class="pi pi-pencil blue-icon mx-2" (click)="editUserNote(note)"></i>
                                    <i class="pi pi-trash red-icon" (click)="deleteUserNote(note.id)"></i>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="text-center w-100" style="min-height: 200px" *ngIf="userLoader">
                <app-inline-loader></app-inline-loader>
            </div>
        </div>
    </div>
</ng-container>

<div *ngIf="showModal">
    <p-dialog header="Header" appendTo="body" (onHide)="cancel()" [(visible)]="display" [modal]="true" [style]="{ width: '50vw' }" [baseZIndex]="1000" [draggable]="false" [resizable]="false">
        <ng-template pTemplate="header" class="py-3">
            <h3 class="m-0">Update User Note</h3>
        </ng-template>
        <form (ngSubmit)="(f.form.valid)" #f="ngForm" novalidate class="w-100 mx-auto relative">
            <div class="row mx-0">
                <div class="col-12">
                    <div class="form-group">
                        <label class="text-dark">Notes</label>
                        <p-editor [(ngModel)]="this.selectedRow.value" name="value" [style]="{ height: '150px' }" required></p-editor>
                        <!-- <textarea type="text" name="value" [(ngModel)]="selectedRow.value" #name="ngModel" class="form-control form-control-lg form-control-solid" maxlength="200" required></textarea> -->
                    </div>
                </div>
            </div>

            <div class="mt-5 mb-2 d-flex flex-row justify-content-end">
                <button pButton pRipple label="Cancel" icon="pi pi-times" class="p-button-text text-secondary" (click)="cancel()"></button>
                <button pButton pRipple [disabled]="!f.valid" label="Update" icon="pi pi-check" class="p-button-text" (click)="updateUserNote()"></button>
            </div>
        </form>
        <div *ngIf="showLoader" class="spinner-view">
            <app-inline-loader></app-inline-loader>
        </div>
    </p-dialog>
</div>

<p-confirmDialog appendTo="body" [style]="{ width: '50vw' }" key="leadNotesDelete" [baseZIndex]="10000"></p-confirmDialog>
