import { Injectable } from '@angular/core';
import { HttpUtility } from '@utility/httpUtility';
import { environment } from '@environments/environment';

import { LuPhoneTypeModel } from '../models/lu-phone-type-model';
import { Cacheable } from 'ts-cacheable';

@Injectable({ providedIn: 'root' })
export class LuPhoneTypeService {
    constructor(private httpUtility: HttpUtility) {}
    @Cacheable()
    getPhoneTypes() {
        return this.httpUtility.get<LuPhoneTypeModel[]>(`${environment.apiUrl}/lu-phone-types`);
    }
}
