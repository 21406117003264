import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '@environments/environment';
import { HttpUtility } from '@utility/httpUtility';
import { DialerStateService, DialerState } from '@app/shared/services/dialer-service';
import { StringResponse } from '@shared/model/string-response';
import { Bearertoken } from '@shared/authentication/model/bearer-token-model';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
    userSubject = new BehaviorSubject<Bearertoken>(null);
    user: Observable<Bearertoken>;
    userToken: Bearertoken;

    constructor(private router: Router, private readonly httpUtility: HttpUtility, private dialerService: DialerStateService) {
        const bearerToken = JSON.parse(localStorage.getItem('token')) as Bearertoken;
        if (bearerToken) {
            this.userSubject.next(JSON.parse(localStorage.getItem('token')) as Bearertoken);
        }
        this.user = this.userSubject.asObservable();
        this.user.subscribe((x) => {
            return (this.userToken = x);
        });
    }
    get isUserLoggedIn(): boolean {
        return this.userValue !== null;
    }
    get userValue(): Bearertoken {
        return this.userSubject.value;
    }
    updateBearerToken(): void {
        const bearerToken = JSON.parse(localStorage.getItem('token')) as Bearertoken;
        console.log(bearerToken);
        // this.userSubject.next(bearerToken);
    }
    login(email: string, password: string): Observable<void> {
        return this.httpUtility.post<Bearertoken>(`${environment.apiUrl}/authenticate`, { email, password }).pipe(
            map((bearerToken) => {
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                localStorage.setItem('token', JSON.stringify(bearerToken));
                this.userSubject.next(bearerToken);
                // return user;
            }),
        );
    }

    changePassword(userId: string, password: string): Observable<StringResponse> {
        return this.httpUtility.post<StringResponse>(`${environment.apiUrl}/user-passwords`, { userId, password }).pipe(
            map((response) => {
                return response;
            }),
        );
    }

    sendForgotPasswordEmail(id: string): Observable<boolean> {
        return this.httpUtility.get<boolean>(`${environment.apiUrl}/user-passwords/forgot-password-email?id=${id}`).pipe(
            map((response) => {
                return response;
            }),
        );
    }

    logout(): void {
        // remove user from local storage to log user out
        this.dialerService.set(new DialerState({ showDialerBar: false }));
        localStorage.removeItem('Launch_Dialer');
        localStorage.removeItem('token');
        localStorage.removeItem('LastUserImpersonate');
        this.userSubject.next(null);
        void this.router.navigate(['/login']);
        localStorage.removeItem('userImpersonate');
        localStorage.removeItem('leadFilters');
        localStorage.removeItem('clientFilters');
        localStorage.removeItem('DialerType');
        localStorage.removeItem('calendarFilters');
    }

    getUserToken(): Bearertoken {
        return this.userSubject.value;
    }

    loggedInUser(): User {
        const bearerToken = this.getUserToken();
        return JSON.parse(JSON.parse(atob(bearerToken.token.split('.')[1])).User) as User;
    }

    getLoggedInId(): string {
        const user = this.loggedInUser();
        return user.Identity.UserId;
    }

    getLoggedInUserName(): string {
        const user = this.loggedInUser();
        return user.Identity.FirstName + user.Identity.LastName;
    }

    getLoggedInRole(): string {
        if (this.loggedInUser()?.Roles && this.loggedInUser()?.Roles.length > 0) {
            return this.loggedInUser()?.Roles[0];
        }
        return '';
    }

    isAdmin(): boolean {
        const userRoles = this.getLoggedInRole();
        if (userRoles.includes('Lead Manager') || userRoles.includes('Pre-Call Manager') || userRoles.includes('Owner') || userRoles.includes('Inside Sales Manager') || userRoles.includes('Admin')) {
            return true;
        } else {
            return false;
        }
    }
    isOwner(): boolean {
        const userRoles = this.getLoggedInRole();
        if (userRoles.includes('Owner')) {
            return true;
        } else {
            return false;
        }
    }
}

export class Identity {
    userName: string;
    FirstName: string;
    LastName: string;
    Email: string;
    UserId: string;
    Id: number;
}
export class User {
    Roles: string[];
    Identity: Identity;
}
