import { Injectable } from '@angular/core';
import { HttpUtility } from '@utility/httpUtility';
import { Observable } from 'rxjs';
import { environment } from '@environments/environment';
import { UserAddressCreateModel, UserAddressViewModel } from '../models/user-addresses-model';

@Injectable({ providedIn: 'root' })
export class UserAdressService {
    constructor(private httpUtility: HttpUtility) {}

    getaddressesById(id: string): Observable<UserAddressViewModel[]> {
        return this.httpUtility.get<UserAddressViewModel[]>(`${environment.apiUrl}/users/${id}/user-addresses`);
    }

    editaddresses(userAddressViewModel: UserAddressCreateModel, id: string) {
        return this.httpUtility.post(`${environment.apiUrl}/users/${id}/user-addresses`, userAddressViewModel);
    }

    addAddresses(userAddressCreateModel: UserAddressCreateModel, id: string) {
        return this.httpUtility.post(`${environment.apiUrl}/users/${id}/user-addresses`, userAddressCreateModel);
    }

    deleteaddressesById(userId: string, Id: string) {
        return this.httpUtility.delete(`${environment.apiUrl}/users/${userId}/user-addresses/${Id}`);
    }
}
