import { AfterViewInit, Component, Input, OnChanges } from '@angular/core';
import moment from 'moment';
import Calendar from 'tui-calendar';
import { CalendarService } from '@app/calendar/services/calendar.service';
import { sortBy } from 'lodash';
import { BaseLookupModel } from '@app/shared/models/base-look-up.model';
import { ViewFilterModel } from '@app/calendar/model/view-filter-model';
import { EditEventModel } from '@app/calendar/model/editEvent-model';
import { MessageService } from 'primeng/api';

@Component({
    selector: 'app-calendar-events-tab',
    templateUrl: './calendar-events-tab.component.html',
    styleUrls: ['./calendar-events-tab.component.scss'],
})
export class CalendarEventsTabComponent implements OnChanges, AfterViewInit {
    @Input() events = [];
    @Input() leadUserId = '';
    @Input() clientUserId = '';
    calendar;
    currentMonth: string;
    selectedSchedule: any;
    modalAction = 'Add';
    modalTitle = '';
    display = false;
    showModal = false;
    agentOptions: any;
    categoryOptions: ViewFilterModel[];
    loading = false;

    constructor(private calendarSvc: CalendarService, private messageService: MessageService) {}

    ngOnChanges() {
        if (this.calendar && this.events.length > 0) {
            this.calendar.clear();
            this.calendar.createSchedules(this.events);
            this.calendar.render();
        }
    }

    ngAfterViewInit() {
        this.getAllAgentsList();
        this.getCategoryList();
        this.calendar = new Calendar('#calendarEventsTab', {
            defaultView: 'month',
            month: {
                workweek: false,
            },
            taskView: ['task'],
            useCreationPopup: false,
            useDetailPopup: false,

            template: {
                monthDayname: (dayname) => {
                    return `<span class="calendar-week-dayname-name">${dayname.label}</span>`;
                },
            },
        });

        this.calendar.on({
            clickSchedule: (e) => {
                this.selectedSchedule = e;
                this.showAddModal();
                this.modalAction = 'Preview';
                this.modalTitle = e.schedule.raw.originalTitle;
            },
            beforeCreateSchedule: () => {},
            beforeUpdateSchedule: (e) => {
                const updateSchedule = e.schedule;
                const eventChanges = e.changes;

                const editCalendarEvent: EditEventModel = {
                    title: updateSchedule.raw.originalTitle,
                    notes: updateSchedule.body,
                    location: updateSchedule.location,
                    multiDay: updateSchedule.isAllDay,
                    allDay: updateSchedule.isAllDay,
                    startTime: moment(eventChanges.start._date).format('YYYY-MM-DDTHH:mm:ss'),
                    endTime: moment(eventChanges.end._date).format('YYYY-MM-DDTHH:mm:ss'),
                    eventCategoryId: updateSchedule.raw?.eventCategory !== null ? updateSchedule.raw?.eventCategory?.id : '',
                    leadUserId: updateSchedule.raw?.leadUser !== null ? updateSchedule.raw?.leadUser?.id : '',
                    clientUserId: updateSchedule.raw?.clientUser !== null ? updateSchedule.raw?.clientUser?.id : '',
                    agentUserId: updateSchedule.raw?.agentUser !== null ? updateSchedule.raw?.agentUser?.id : '',
                    statusId: 1,
                    recurringType: updateSchedule.recurrenceRule,
                };

                this.loading = true;
                this.calendarSvc.updateEvent(updateSchedule.id, editCalendarEvent).subscribe({
                    next: () => {
                        this.loading = false;
                        this.calendarSvc.setCalendarEventUpdate();
                        this.messageService.add({
                            key: 'tst',
                            severity: 'success',
                            summary: 'Success',
                            detail: 'changed event date(s) successfully',
                        });
                    },
                    error: () => {
                        this.loading = false;
                        this.messageService.add({
                            key: 'tst',
                            severity: 'error',
                            summary: 'Error',
                            detail: 'Error in updating event',
                        });
                    },
                });
            },
        });
        this.calendar.clear();
        this.calendar.createSchedules(this.events);
        this.calendar.render();
        this.getCurrentMonth();
    }

    cancel() {
        this.showModal = false;
    }

    getAllAgentsList() {
        this.calendarSvc.getAllAgents().subscribe((res) => {
            const sortedArray = sortBy(res, (o: { value: BaseLookupModel }) => {
                return o.value;
            }) as unknown as BaseLookupModel[];
            this.agentOptions = [];
            sortedArray.forEach((element: BaseLookupModel) => {
                this.agentOptions.push({
                    name: element.value,
                    code: element.id,
                });
            });
        });
    }

    getCategoryList() {
        this.calendarSvc.getAllCategories().subscribe((res) => {
            this.categoryOptions = sortBy(res, (o) => {
                return o.name;
            });
        });
    }

    getCalendarCreatedList() {}

    getCalendarDeletedEvent() {}

    updateModalTitle(data) {
        this.modalTitle = data;
    }

    showAddModal() {
        this.display = true;
        this.showModal = true;
    }

    setToday() {
        this.calendar.today();
        this.getCurrentMonth();
    }

    setPevMonth() {
        this.calendar.prev();
        this.getCurrentMonth();
    }

    setNextMonth() {
        this.calendar.next();
        this.getCurrentMonth();
    }

    getCurrentMonth() {
        const getMonth = this.calendar.getDate();
        this.currentMonth = moment(getMonth._date).format('MMMM, YYYY');
    }
}
