import { Injectable } from '@angular/core';
import { HttpUtility } from '@utility/httpUtility';
import { Observable } from 'rxjs';
import { environment } from '@environments/environment';
import { CountyModel } from '../models/county-model';

@Injectable({ providedIn: 'root' })
export class CountyService {
    constructor(private httpUtility: HttpUtility) {}

    getCounties(id: string): Observable<CountyModel[]> {
        return this.httpUtility.get<CountyModel[]>(`${environment.apiUrl}/counties?stateId=${id}`);
    }
}
