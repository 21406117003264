import { Injectable } from '@angular/core';
import { HttpUtility } from '@utility/httpUtility';
import { Observable } from 'rxjs';
import { environment } from '@environments/environment';
import { UserCreateModel, UserModel } from '../models/user-model';
import { CheckModel } from '@shared/authentication/model/check-model';
import { Bearertoken } from '@shared/authentication/model/bearer-token-model';

@Injectable({ providedIn: 'root' })
export class UsersService {
    constructor(private httpUtility: HttpUtility) {}

    getUsersById(id: string): Observable<UserModel> {
        return this.httpUtility.get<UserModel>(`${environment.apiUrl}/users/${id}`);
    }

    editUsers(users: UserModel) {
        return this.httpUtility.put(`${environment.apiUrl}/users`, users);
    }

    addUsers(user: UserCreateModel) {
        return this.httpUtility.post(`${environment.apiUrl}/users`, user);
    }

    deleteUsersById(id: number) {
        return this.httpUtility.delete(`${environment.apiUrl}/users/${id}`);
    }

    deleteUserById(userId: string, id: string) {
        return this.httpUtility.delete(`${environment.apiUrl}/user/${userId}/user-agents/${id}`);
    }

    getAllUsers(): Observable<UserModel[]> {
        return this.httpUtility.get<UserModel[]>(
            // eslint-disable-next-line max-len
            `${environment.apiUrl}/users?roleDescriptors=ExternalAgent&roleDescriptors=LeadManager&roleDescriptors=SalesManager&roleDescriptors=PreCallManager&roleDescriptors=Owner&roleDescriptors=InternalAgent`,
        );
    }

    getUsersAgents(): Observable<UserModel[]> {
        return this.httpUtility.get<UserModel[]>(`${environment.apiUrl}/users/agents`);
    }

    getUserByEmail(email: string): Observable<CheckModel> {
        return this.httpUtility.get<CheckModel>(`${environment.apiUrl}/users/verify-email?email=${email}`);
    }

    getUserByUserName(userName: string): Observable<CheckModel> {
        return this.httpUtility.get<CheckModel>(`${environment.apiUrl}/users/verify-username?userName=${userName}`);
    }

    userImpersonate(userId: string): Observable<Bearertoken> {
        return this.httpUtility.post(`${environment.apiUrl}/impersonate?id=${userId}`, {});
    }
}
