const CONSTANTS = {
    FILTER_VIEW: [
        { name: 'Day', code: 'DAY' },
        { name: 'Week', code: 'WEEK' },
        { name: 'Month', code: 'MONTH' },
    ],

    FILTER_STATUS: [
        { name: 'Upcoming', code: 'upcoming' },
        { name: 'Overdue', code: 'overdue' },
        { name: 'Completed', code: 'completed' },
        { name: 'Incomplete', code: 'incomplete' },
    ],

    LASTS_DAYS: [
        { name: 'Every one', code: 'Every one' },
        { name: 'My agency', code: 'My agency' },
        { name: 'Test', code: 'Test' },
    ],

    RECURRING: [
        { name: 'Does not repeat', code: 'norepeat' },
        { name: 'Daily', code: 'daily' },
        { name: 'Weekly', code: 'weekly' },
        { name: 'Monthly', code: 'monthly' },
    ],
};

export { CONSTANTS };
