/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
import { Component, OnInit, ViewChild, Output, EventEmitter, Input } from '@angular/core';
import moment from 'moment';
import { ViewFilterModel } from '@app/calendar/model/view-filter-model';
import { CONSTANTS } from '@app/calendar/components/constants';
import { AddEventModel } from '@app/calendar/model/addEvent-model';
import { ConfirmationService, Message } from 'primeng/api';
import { EditEventModel } from '@app/calendar/model/editEvent-model';
import { AddEventService } from '@app/add-calendar-event/service/add-calendar-event';
import { BaseComponentDirective } from '@core/component/base.component';
import { CalendarService } from '../../../calendar/services/calendar.service';
import { AuthenticationService } from '@app/authentication/services/authentication.service';
// import { createEvent } from 'ics';


@Component({
    selector: 'app-add-calendar-event',
    templateUrl: './add-calendar-event.component.html',
    styleUrls: ['./add-calendar-event.component.scss'],
})
export class AddCalendarEventComponent extends BaseComponentDirective implements OnInit {
    @ViewChild('f', { static: true }) ngForm;
    @Output() closeModal = new EventEmitter();
    @Output() updateModalTitle: EventEmitter<any> = new EventEmitter();
    @Output() getCalendarEventsList: EventEmitter<any> = new EventEmitter();
    @Output() getCalendarCreatedList: EventEmitter<any> = new EventEmitter();
    @Output() getCalendarDeletedEvent: EventEmitter<any> = new EventEmitter();

    @Input() categories: any;
    @Input() agentList: any;
    @Input() selectedSchedule: any;
    @Input() modalAction: any;
    @Input() leadUserId: string;
    @Input() userType: string;
    @Input() clientUserId: string;
    submitted = false;
    allDayOption = false;
    loading = false;
    eventDate: Date;

    description: string;
    startTime: any;
    endTime: any;
    startMins: any;
    endMins: any;
    repeatDate: any;
    location: string;
    notes: string;
    chooseRepeatDate = false;

    lastViewOptions: ViewFilterModel[];
    selectedLastFilter: any;

    selectedCategory: any;

    reccurOptions: ViewFilterModel[];
    selectedReoccurOption: any;

    startDate;
    selectedDate: string;
    selectedEndDate: string;
    selectedStartTime: string;
    selectedEndTime: string;
    reccuringType: string;

    selectedAgent = [];
    editSelectedAgent: any;
    selectedCategoryOption: string;
    selectedFilterAgent = [];
    editFilterAgent: string;

    addEventBtn = true;
    updateEventBtn = false;
    hideAlldayOptions: boolean;
    msgs: Message[] = [];
    eventDescription: string;
    getStartHoursList = [];
    getEndHoursList = [];

    getStartMinsList = [];
    getEndMinsList = [];

    startSelectedTime: any;
    endSelectedTime: any;
    storeStartTime: string;
    storeEndTime: string;
    selecteDateFormat: any;
    slots = [];
    selectedStartSlot: any;
    selectedEndSlot: any;
    timeSlotsObj: any;
    userId = this.authService.getLoggedInId();
    constructor(
        private addEventService: AddEventService,
        private confirmationService: ConfirmationService,
        private calendarService: CalendarService,
        private authService: AuthenticationService,
    ) {
        super();
        // this.startTime.setMinutes(0, 0, 0);
        // this.endTime.setMinutes(0, 0, 0);
    }

    ngOnInit(): void {
        this.selectedDate = moment(this.selectedSchedule?.schedule?.start?._date).format('MM-DD-YYYY');
        this.selectedEndDate = moment(this.selectedSchedule?.schedule?.end?._date).format('MM-DD-YYYY');
        this.selectedStartTime = moment(this.selectedSchedule?.schedule?.start?._date).format('hh:mm A');
        this.selectedEndTime = moment(this.selectedSchedule?.schedule?.end?._date).format('hh:mm A');

        this.reccurOptions = CONSTANTS.RECURRING;
        this.hideAlldayOptions = true;
        this.getHours();
        this.getMins();
        this.slots = [
            { name: 'AM', code: 'AM' },
            { name: 'PM', code: 'PM' },
        ];
        this.timeSlotsObj = { start: this.selectedSchedule?.schedule?.start?._date, end: this.selectedSchedule?.schedule?.end?._date };
        this.selectedLastFilter = [this.userId];
    }

    onSelectUpdateDate(e) {
        this.startSelectedTime = e;
        this.endSelectedTime = e;
        this.timeSlotsObj.start = e;
        this.timeSlotsObj.end = e;
        this.startTime = '';
        this.endMins = '';
        this.selectedStartSlot = '';
        this.selectedEndSlot = '';
    }

    onSubmit(): void {
        this.submitted = true;
        this.startTime = this.timeSlotsObj.start;
        this.endTime = this.timeSlotsObj.end;
        const allDayStartTime = this.allDayOption === true ? moment(this.selecteDateFormat).format('YYYY-MM-DDTHH:mm') : moment(this.startTime).format('YYYY-MM-DDTHH:mm');
        let allDayEndTime = '';
        if (this.allDayOption && this.reccuringType === undefined) {
            allDayEndTime = moment(allDayStartTime).format('YYYY-MM-DDTHH:mm');
        } else if (this.allDayOption && this.reccuringType !== 'norepeat') {
            allDayEndTime = moment(this.repeatDate).endOf('day').format('YYYY-MM-DDTHH:mm');
        } else if (this.allDayOption) {
            allDayEndTime = moment(allDayStartTime).format('YYYY-MM-DDTHH:mm');
        } else if (!this.allDayOption && (this.reccuringType === 'norepeat' || this.reccuringType === undefined)) {
            allDayEndTime = moment(this.endTime).format('YYYY-MM-DDTHH:mm');
        } else if (!this.allDayOption && this.reccuringType !== 'norepeat') {
            const getHours = moment(this.endTime).format('HH:mm');
            const getDate = moment(this.repeatDate).format('YYYY-MM-DDT');
            allDayEndTime = moment(`${getDate} ${getHours}`, 'YYYY-MM-DDTHH:mm').format('YYYY-MM-DDTHH:mm');
        } else {
            allDayEndTime = moment(this.endTime).format('YYYY-MM-DDTHH:mm');
        }

        const addEventCreateModel: AddEventModel = {
            title: this.eventDescription,
            notes: this.selectedSchedule?.schedule.body,
            location: this.selectedSchedule?.schedule.location,
            multiDay: this.chooseRepeatDate,
            allDay: this.allDayOption,
            startTime: allDayStartTime,
            endTime: allDayEndTime,
            eventCategoryId: this.selectedCategoryOption,
            leadUserId: this.userType === 'lead' ? this.leadUserId : '',
            clientUserId: this.userType === 'client' ? this.clientUserId : '',
            agentUses: this.selectedLastFilter,
            statusId: 1,
            recurringType: this.reccuringType,
        };
        console.log(addEventCreateModel);
        if (!this.allDayOption) {
            const start = moment(allDayStartTime).format('HH:mm');
            const end = moment(allDayEndTime).format('HH:mm');
            if (start > end) {
                this.messageService.add({
                    key: 'tst',
                    severity: 'error',
                    summary: 'Error',
                    detail: 'Start time is greater than end time',
                });
                return;
            }
        }

        if (this.reccuringType !== 'norepeat') {
            if (moment(allDayStartTime).isAfter(allDayEndTime)) {
                this.messageService.add({
                    key: 'tst',
                    severity: 'error',
                    summary: 'Error',
                    detail: 'Start date is greater than end date',
                });
                return;
            }
        }
        this.loading = true;
        this.addEventService
            .addCalendarEvent(addEventCreateModel)
            .pipe()
            .subscribe({
                next: () => {
                    this.calendarService.setCalendarEventUpdate();
                    this.onCancel();
                    this.getCalendarCreatedList.emit();
                    this.loading = false;
                    this.updateModalTitle.emit('CREATING TASK');
                    this.ngForm.reset();
                },
                error: () => {
                    this.messageService.add({
                        key: 'tst',
                        severity: 'error',
                        summary: 'Error',
                        detail: 'Error in adding event.',
                    });
                },
            });
    }

    onCancel() {
        this.closeModal.emit('close');
    }

    onUpdate() {
        this.submitted = true;

        const startEditTime = moment(this.timeSlotsObj.start).format('YYYY-MM-DDTHH:mm');
        const endEditTime = moment(this.timeSlotsObj.end).format('YYYY-MM-DDTHH:mm');

        const allDayStartTime = this.allDayOption === true ? moment(this.selecteDateFormat).format('YYYY-MM-DDTHH:mm') : moment(startEditTime).format('YYYY-MM-DDTHH:mm');
        let allDayEndTime = '';
        if ((this.allDayOption && this.reccuringType === undefined) || this.reccuringType === '') {
            allDayEndTime = moment(endEditTime).format('YYYY-MM-DDTHH:mm');
        } else if (this.allDayOption && this.reccuringType !== 'norepeat') {
            allDayEndTime = moment(this.repeatDate).endOf('day').format('YYYY-MM-DDTHH:mm');
        } else if (this.allDayOption) {
            allDayEndTime = moment(startEditTime).format('YYYY-MM-DDTHH:mm');
        } else if (!this.allDayOption && (this.reccuringType === 'norepeat' || this.reccuringType === undefined)) {
            allDayEndTime = moment(endEditTime).format('YYYY-MM-DDTHH:mm');
        } else {
            allDayEndTime = moment(endEditTime).format('YYYY-MM-DDTHH:mm');
        }

        const editCalendarEvent: EditEventModel = {
            title: this.eventDescription,
            notes: this.selectedSchedule?.schedule.body,
            location: this.selectedSchedule?.schedule.location,
            multiDay: this.chooseRepeatDate,
            allDay: this.allDayOption,
            startTime: allDayStartTime,
            endTime: allDayEndTime,
            eventCategoryId: this.selectedCategoryOption,
            leadUserId: this.selectedSchedule?.schedule.raw?.leadUser?.id,
            clientUserId: this.selectedSchedule?.schedule.raw?.clientUser?.id,
            agentUserId: this.editFilterAgent,
            statusId: 1,
            recurringType: this.selectedReoccurOption?.code,
        };
        if (!this.allDayOption) {
            const start = moment(allDayStartTime).format('HH:mm');
            const end = moment(allDayEndTime).format('HH:mm');
            if (start > end) {
                this.messageService.add({
                    key: 'tst',
                    severity: 'error',
                    summary: 'Error',
                    detail: 'Start time is greater than end time',
                });
                return;
            }
        }

        if (this.reccuringType !== 'norepeat') {
            if (moment(allDayStartTime).isAfter(allDayEndTime)) {
                this.messageService.add({
                    key: 'tst',
                    severity: 'error',
                    summary: 'Error',
                    detail: 'Start date is greater than end date',
                });
                return;
            }
        }
        this.loading = true;
        this.addEventService.updateEvent(this.selectedSchedule?.schedule.id, editCalendarEvent).subscribe({
            next: () => {
                this.onCancel();
                this.getCalendarEventsList.emit();
                this.loading = false;
                this.updateModalTitle.emit('CREATING TASK');
                this.calendarService.setCalendarEventUpdate();
            },
            error: () => {
                this.loading = false;
                this.messageService.add({
                    key: 'tst',
                    severity: 'error',
                    summary: 'Error',
                    detail: 'Error in updating event',
                });
            },
        });
    }

    selectRepeatOption(event) {
        if (event.value.code !== 'norepeat') {
            this.chooseRepeatDate = true;
        } else {
            this.chooseRepeatDate = false;
        }
        this.reccuringType = event.value.code;
    }
    updateSchedule() {
        this.addEventBtn = false;
        this.updateEventBtn = true;
        this.modalAction = 'Add';
        this.updateModalTitle.emit('UPDATING TASK');
        this.timeSlotsObj = { start: this.selectedSchedule?.schedule?.start?._date, end: this.selectedSchedule?.schedule?.end?._date };
        const getReccurVal = this.selectedSchedule?.schedule?.recurrenceRule;

        const reoccurFilter = this.reccurOptions.filter((obj) => {
            return obj.code === getReccurVal;
        });

        if (reoccurFilter.length > 0 && reoccurFilter[0].name !== 'norepeat') {
            this.chooseRepeatDate = true;
        } else {
            this.chooseRepeatDate = false;
        }
        this.startSelectedTime = this.selectedSchedule?.schedule?.start?._date;
        this.endSelectedTime = this.selectedSchedule?.schedule?.end?._date;

        this.startTime = {
            name: moment(this.timeSlotsObj.start, ['h']).format('h'),
            code: moment(this.timeSlotsObj.end, ['h']).format('h'),
        };
        this.startMins = {
            name: moment(this.selectedSchedule?.schedule?.start?._date, ['mm']).format('mm'),
            code: moment(this.selectedSchedule?.schedule?.start?._date, ['mm']).format('mm'),
        };
        this.endTime = {
            name: moment(this.selectedSchedule?.schedule?.end?._date, ['h']).format('h'),
            code: moment(this.selectedSchedule?.schedule?.end?._date, ['h']).format('h'),
        };
        this.endMins = {
            name: moment(this.selectedSchedule?.schedule?.end?._date, ['mm']).format('mm'),
            code: moment(this.selectedSchedule?.schedule?.end?._date, ['mm']).format('mm'),
        };
        this.selectedStartSlot = moment(this.timeSlotsObj.start, ['h']).format('A') === 'AM' ? this.slots[0] : this.slots[1];
        this.selectedEndSlot = moment(this.timeSlotsObj.end, ['h']).format('A') === 'AM' ? this.slots[0] : this.slots[1];
        this.allDayOption = this.selectedSchedule?.schedule?.isAllDay;
        this.selectedCategory = this.selectedSchedule?.schedule?.raw?.eventCategory;
        this.editSelectedAgent = this.selectedSchedule?.schedule?.raw?.agentUser;
        this.repeatDate = this.selectedSchedule?.schedule?.end?._date;
        this.hideAlldayOptions = this.selectedSchedule?.schedule?.isAllDay;
        this.editFilterAgent = this.selectedSchedule?.schedule?.raw?.agentUser?.id;
        this.selectedCategoryOption = this.selectedSchedule?.schedule?.raw?.eventCategory.id;
        this.selectedReoccurOption = reoccurFilter[0];
        this.reccuringType = this.selectedSchedule?.schedule?.recurrenceRule;
        this.eventDescription = this.selectedSchedule?.schedule.raw.originalTitle;
        this.selecteDateFormat = this.selectedSchedule?.schedule?.start?._date;
    }

    getSelectedAgent(e) {
        this.selectedAgent = e.value;
        this.editFilterAgent = e.value.code;
    }

    getFilterAgents() {
        this.selectedFilterAgent = [];
        this.selectedAgent.forEach((element) => {
            if (this.selectedFilterAgent.includes(element.id) === false) {
                this.selectedFilterAgent.sort().push(element.code);
            }
        });
    }

    getSelectedCategory(e) {
        this.selectedCategoryOption = e.value.id;
    }

    completeSelectedEvent() {
        this.addEventService.completeEvent(this.selectedSchedule?.schedule.id).subscribe((_res) => {
            this.onCancel();
            this.getCalendarEventsList.emit();
            this.calendarService.setCalendarEventUpdate();
        });
    }

    deleteSelectedEvent() {
        this.addEventService.deleteEvent(this.selectedSchedule?.schedule.id).subscribe((_res) => {
            this.onCancel();
            this.getCalendarDeletedEvent.emit();
            this.calendarService.setCalendarEventUpdate();
        });
    }

    confirmDelete() {
        this.confirmationService.confirm({
            message: 'Are you sure that you want to proceed?',
            header: 'Confirmation',
            icon: 'pi pi-exclamation-triangle',
            accept: () => {
                this.deleteSelectedEvent();
                this.messageService.add({
                    key: 'tst',
                    severity: 'success',
                    summary: 'Success',
                    detail: 'Successfully deleted event',
                });
            },
        });
    }

    getHours() {
        for (let hour = 1; hour < 13; hour++) {
            this.getStartHoursList.push({ name: moment({ hour }).format('h'), code: moment({ hour }).format('h') });
        }
        this.getEndHoursList = [...this.getStartHoursList];
    }

    getMins() {
        for (let minute = 0; minute < 60; minute = minute + 15) {
            this.getStartMinsList.push({ name: moment({ minute }).format('mm'), code: moment({ minute }).format('mm') });
        }
        this.getStartMinsList = [...this.getStartMinsList];
        this.getEndMinsList = [...this.getStartMinsList];
    }

    getSelectedStartHours(e) {
        const time = moment(e.value.code, ['h:mm A']).format('HH:mm');
        const selectedDate = moment(this.timeSlotsObj.start).format('YYYY-MM-DDT');
        this.timeSlotsObj.start = `${selectedDate}${time}`;
        console.log('updated Object', this.timeSlotsObj);
        this.startMins = '';
        this.selectedStartSlot = '';
    }

    getSelectedStartMins(e) {
        const getSelectedMins = moment(e.value.code, ['m A']).format('mm');
        const getSelectedHours = moment(this.timeSlotsObj.start).format('YYYY-MM-DDTHH:');
        this.timeSlotsObj.start = `${getSelectedHours}${getSelectedMins}`;
        console.log('updated start mins', this.timeSlotsObj);
    }

    getSelectedEndHours(e) {
        const time = moment(e.value.code, ['h:mm A']).format('HH:mm');
        const selectedDate = moment(this.timeSlotsObj.end).format('YYYY-MM-DDT');
        this.timeSlotsObj.end = `${selectedDate}${time}`;
        console.log('updated end mins', this.timeSlotsObj);
        this.endMins = '';
        this.selectedEndSlot = '';
    }

    getSelectedEndMins(e) {
        const getSelectedMins = moment(e.value.code, ['m A']).format('mm');
        const getSelectedHours = moment(this.timeSlotsObj.end).format('YYYY-MM-DDTHH:');
        this.timeSlotsObj.end = `${getSelectedHours}${getSelectedMins}`;
        console.log('updated end mins', this.timeSlotsObj);
    }

    getSelectedStartSlot(e) {
        let formatedStartTime;
        if (e.value.code === 'PM') {
            formatedStartTime = moment(this.timeSlotsObj.start).add('hours', 12).format('YYYY-MM-DDTHH:mm');
        } else {
            formatedStartTime = this.timeSlotsObj.start;
        }
        this.timeSlotsObj.start = formatedStartTime;
        console.log(this.timeSlotsObj);
    }

    getSelectedEndSlot(e) {
        let formatedEndTime;
        if (e.value.code === 'PM') {
            formatedEndTime = moment(this.timeSlotsObj.end).add('hours', 12).format('YYYY-MM-DDTHH:mm');
        } else {
            if (moment(this.timeSlotsObj.start, ['h']).format('A') === 'AM') {
                formatedEndTime = moment(this.timeSlotsObj.end).subtract('hours', 12).format('YYYY-MM-DDTHH:mm');
            } else {
                formatedEndTime = this.timeSlotsObj.end;
            }
        }
        this.timeSlotsObj.end = formatedEndTime;
        console.log(this.timeSlotsObj);
    }

    // async handleDownloadIcs() {
    //     let checkStartDate = moment(this.selectedSchedule?.schedule?.start?._date, 'YYYY-MM-DDTHH:mm');
    //     let checkEndDate = moment(this.selectedSchedule?.schedule?.end?._date, 'YYYY-MM-DDTHH:mm');
    //     console.log(this.selectedSchedule?.schedule);
    //     const filename = `${this.selectedSchedule?.schedule?.title}.ics`;
    //     const file: any = await new Promise((resolve, reject) => {
    //         createEvent(
    //             {
    //                 start: [
    //                     Number(checkStartDate.format('YYYY')),
    //                     Number(checkStartDate.format('M')),
    //                     Number(checkStartDate.format('D')),
    //                     Number(checkStartDate.format('H')),
    //                     Number(checkStartDate.format('m')),
    //                 ],
    //                 end: [Number(checkEndDate.format('YYYY')), Number(checkEndDate.format('M')), Number(checkEndDate.format('D')), Number(checkEndDate.format('H')), Number(checkEndDate.format('m'))],
    //                 // duration: { hours: 6, minutes: 30 },
    //                 title: this.selectedSchedule?.schedule?.title,
    //                 description: this.selectedSchedule?.schedule?.body,
    //                 location: this.selectedSchedule?.schedule?.location,
    //                 url: 'http://www.bolderboulder.com/',
    //                 categories: ['10k races', 'Memorial Day Weekend', 'Boulder CO'],
    //                 status: 'CONFIRMED',
    //                 busyStatus: 'BUSY',
    //                 organizer: { name: 'Admin', email: 'Race@BolderBOULDER.com' },
    //                 // attendees: [
    //                 //     { name: 'Adam Gibbons', email: 'adam@example.com', rsvp: true, partstat: 'ACCEPTED', role: 'REQ-PARTICIPANT' },
    //                 //     { name: 'Brittany Seaton', email: 'brittany@example2.org', dir: 'https://linkedin.com/in/brittanyseaton', role: 'OPT-PARTICIPANT' },
    //                 // ],
    //             },
    //             (error, value) => {
    //                 if (error) {
    //                     reject(error);
    //                 }
    //                 resolve(new File([value], filename, { type: 'text/calendar' }));
    //             },
    //         );
    //     });
    //     const url = URL.createObjectURL(file);
    //     // trying to assign the file URL to a window could cause cross-site
    //     // issues so this is a workaround using HTML5
    //     const anchor = document.createElement('a');
    //     anchor.href = url;
    //     anchor.download = filename;
    //     document.body.appendChild(anchor);
    //     anchor.click();
    //     document.body.removeChild(anchor);
    //     URL.revokeObjectURL(url);
    // }
}
