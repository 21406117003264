import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class DialerStateService {
    showDialerBar = new BehaviorSubject<DialerState>({ showDialerBar: false });
    protected showDialerBar$ = this.showDialerBar.asObservable();
    constructor() {}

    set(showDialerBar: DialerState): void {
        this.showDialerBar.next(showDialerBar);
    }
    get(): Observable<DialerState> {
        return this.showDialerBar$;
    }
}

export class DialerState {
    showDialerBar: boolean;
    constructor(init?: Partial<DialerState>) {
        Object.assign(this, init);
    }
}
