enum BulkActionsEnum {
    AddTags = 'AddTags',
    DeleteTags = 'DeleteTags',
    AddCallQueue = 'AddCallQueue',
    DeleteCallQueue = 'DeleteCallQueue',
    ChangeStatus = 'ChangeStatus',
    ChangeAgent = 'ChangeAgent',
    AddNotes = 'AddNotes',
}

export default BulkActionsEnum;
