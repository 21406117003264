<ng-container>
    <div class="card-body row py-3 px-3">
        <div class="form-group mb-3 w-100">
            <textarea [(ngModel)]="addNote" [rows]="2" placeholder="Add Notes" class="form-control form-control-solid h-auto py-4 px-6 rounded-lg"></textarea>
        </div>
        <div class="flex flex-column justify-content-end align-items-end w-100">
            <button (click)="onSubmit()" pButton pRipple label="Save" icon="pi pi-check" class="p-button-text" [loading]="loading"></button>
        </div>
    </div>
</ng-container>
<!-- <p-toast key="tst"></p-toast> -->
