import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DialerContentService } from '@app/shared/services/dialer-content-service';
import { BaseComponentDirective } from '@core/component/base.component';
import { Subscription } from 'rxjs';
import { SoaGenreatedListService } from '../services/soa-generate-list.service';

@Component({
    selector: 'app-soa-generated-list',
    templateUrl: './soa-generated-list.component.html',
    styles: [
        `
            ::ng-deep .soa-list-table table {
                width: 1500px !important;
            }
        `,
    ],
})
export class SoaGeneratedListComponent extends BaseComponentDirective implements OnInit {
    @Input() leadId: string;
    loading: boolean;
    soaList = [];
    dialerSubscribe: Subscription;
    constructor(private soaListService: SoaGenreatedListService, private router: Router, private dialerContentService: DialerContentService) {
        super();
        this.dialerSubscribe = this.dialerContentService.getUpdate().subscribe((message) => {
            this.leadId = message.text;
            this.ngOnInit();
            const currentUrl = this.router.url;
            void this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
                void this.router.navigate([currentUrl]);
            });
        });
    }

    ngOnInit(): void {
        this.getAllGeneratedList();
    }

    getAllGeneratedList() {
        const payload = {
            userId: this.leadId,
        };
        this.loading = true;
        this.soaListService.getAllList(payload).subscribe({
            next: (res) => {
                this.loading = false;
                this.soaList = res;
            },
            error: () => {
                this.messageService.add({
                    key: 'tst',
                    severity: 'error',
                    summary: 'Error',
                    detail: 'something went wrong, please try again',
                });
            },
        });
    }
}
