import { Injectable } from '@angular/core';
import { HttpUtility } from '@utility/httpUtility';
import { environment } from '@environments/environment';
import { SoaAddModel } from '../models/soa-list-model';

@Injectable({ providedIn: 'root' })
export class SoaListSerive {
    constructor(private httpUtility: HttpUtility) {}

    getSoaList(id) {
        return this.httpUtility.get<any[]>(`${environment.apiUrl}/user-personas/getbyuser/${id}`);
    }

    createSoaList(userId: string, SoaAddModel: SoaAddModel) {
        return this.httpUtility.post(`${environment.apiUrl}/user-personas/${userId}/add-agent-sales-id`, SoaAddModel);
    }

    deleteSoaList(userId: string, payload: any = []) {
        return this.httpUtility.put(`${environment.apiUrl}/user-personas/${userId}/update-agent-sales-id`, payload);
    }

    createContract(payload) {
        return this.httpUtility.post(`${environment.apiUrl}/soa-contract`, payload);
    }
}
