<div class="text-right pb-3">
    <i class="pi pi-plus-circle gray-icon plus-medium" (click)="showModal = true"></i>
</div>
<p-table
    #dt
    [value]="userFiles"
    [rows]="10"
    [loading]="loading"
    [paginator]="true"
    [globalFilterFields]="['name', 'displayOrder', 'description']"
    [rowHover]="true"
    dataKey="id"
    styleClass="p-datatable-users"
>
    <ng-template pTemplate="header">
        <tr>
            <th style="width: 15%" pSortableColumn=" name">Name<p-sortIcon field="name"></p-sortIcon></th>
            <th style="width: 10%" pSortableColumn="createdDate">Created At<p-sortIcon field="createdDate"> </p-sortIcon></th>
            <th style="width: 10%">Action</th>
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-file>
        <tr class="p-selectable-row">
            <td>
                <span class="p-column-title">Name</span>
                {{ file.name }}
            </td>
            <td>
                {{ file.createdDate | utcToLocal: 'MM-DD-YYYY h:mm A' }}
            </td>
            <td class="d-flex">
                <a [href]="file.path" target="_blank" download="{{ file.path }}" class="download-btn mx-2">
                    <i class="pi pi-eye" style="font-size: 1rem"></i>
                </a>
                <button pButton pRipple icon="p-button-icon pi pi-trash" class="p-button-rounded p-button-danger" (click)="confirmDelete(file)"></button>
            </td>
        </tr>
    </ng-template>
    <ng-template pTemplate="emptymessage">
        <tr>
            <td colspan="8">No user files found.</td>
        </tr>
    </ng-template>
</p-table>

<div *ngIf="showModal">
    <p-dialog header="Header" appendTo="body" (onHide)="cancel()" [(visible)]="showModal" [modal]="true" [style]="{ width: '50vw' }" [baseZIndex]="10000" [draggable]="false" [resizable]="false">
        <ng-template pTemplate="header" class="py-3">
            <h3 class="m-0">Add File</h3>
        </ng-template>
        <form (ngSubmit)="(f.form.valid)" #f="ngForm" novalidate class="w-100 mx-auto relative">
            <div class="row mx-0">
                <div class="col-6">
                    <div class="form-group">
                        <label for="password" class="text-dark">Name <span class="text-danger">*</span></label>
                        <input type="text" name="name" [(ngModel)]="selectedRow.name" #name="ngModel" class="form-control form-control-lg form-control-solid" maxlength="50" required />
                    </div>
                </div>
            </div>
            <div class="row mx-0 mt-3">
                <div class="col-lg-12 col-xl-12">
                    <input type="file" value="" name="filePath" [(ngModel)]="selectedRow.filePath" #filePath="ngModel" (change)="fileUploadChange($event)" required />
                </div>
            </div>
            <div class="mt-5 mb-2 d-flex flex-row justify-content-end">
                <button pButton pRipple label="Cancel" icon="pi pi-times" class="p-button-text text-secondary" (click)="cancel()"></button>
                <button pButton pRipple [disabled]="!f.valid" label="Submit" icon="pi pi-check" class="p-button-text" (click)="createAndUpdateFile()"></button>
            </div>
        </form>
        <div *ngIf="showLoader" class="spinner-view">
            <app-inline-loader></app-inline-loader>
        </div>
    </p-dialog>
</div>

<p-confirmDialog appendTo="body" [style]="{ width: '50vw' }" key="userFileDelele" [baseZIndex]="10000"></p-confirmDialog>
<!-- <p-toast key="tst"></p-toast> -->
