import { Component, Input, OnInit } from '@angular/core';
import { UserCallQueueViewModel } from '@app/lead/model/user-call-queue-view.model';
import { LeadListService } from '@app/lead/services/leadlist-service';
import { BaseComponentDirective } from '@core/component/base.component';
import { ConfirmationService } from 'primeng/api';
import { sortBy } from 'lodash';
import { DialerContentService } from '@app/shared/services/dialer-content-service';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';

@Component({
    selector: 'app-lead-call-queues',
    templateUrl: './lead-call-queues.component.html',
    styleUrls: ['./lead-call-queues.component.scss'],
})
export class LeadCallQueuesComponent extends BaseComponentDirective implements OnInit {
    @Input() leadId: string;
    selectedCall: string;
    callOptions = [];
    userCallsList: UserCallQueueViewModel[] = [];
    dialerSubscribe: Subscription;
    constructor(private leadService: LeadListService, private router: Router, private confirmationService: ConfirmationService, private dialerContentService: DialerContentService) {
        super();
        this.dialerSubscribe = this.dialerContentService.getUpdate().subscribe((message) => {
            this.leadId = message.text;
            this.ngOnInit();
            const currentUrl = this.router.url;
            void this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
                void this.router.navigate([currentUrl]);
            });
        });
    }

    ngOnInit(): void {
        super.ngOnInit();
        this.getCallQueueList();
        this.getUserCallQueues();
    }

    addCallQueue(id: string, tagId: string) {
        this.leadService.addCallQueue(id, tagId).subscribe({
            next: () => {
                this.getUserCallQueues();
                this.selectedCall = '';
                this.messageService.add({
                    key: 'tst',
                    severity: 'success',
                    summary: 'Success',
                    detail: 'Call Queue added successfully',
                });
            },
            error: () => {
                this.messageService.add({
                    key: 'tst',
                    severity: 'error',
                    summary: 'Error',
                    detail: 'something went wrong, please try again',
                });
            },
        });
    }

    getCallQueueList() {
        this.leadService.getCallQueues().subscribe({
            next: (res) => {
                const sortedArray = sortBy(res, (o) => {
                    return o.name;
                });
                sortedArray.forEach((element) => {
                    this.callOptions.push({
                        name: element.name,
                        code: element.id,
                    });
                });
            },
            error: () => {
                this.messageService.add({
                    key: 'tst',
                    severity: 'error',
                    summary: 'Error',
                    detail: 'something went wrong to load user tags, please try again',
                });
            },
        });
    }

    getUserCallQueues() {
        this.leadService.getUserCallQueue(this.leadId).subscribe({
            next: (res) => {
                this.userCallsList = res;
                this.selectedCall = '';
            },
            error: () => {
                this.messageService.add({
                    key: 'tst',
                    severity: 'error',
                    summary: 'Error',
                    detail: 'something went wrong to load user call Queues, please try again',
                });
            },
        });
    }

    confirmAddCall(e) {
        const fitleredObj = this.userCallsList.find((val) => {
            return val.callQueue.id === e.value.code;
        });
        if (fitleredObj === undefined) {
            this.confirmationService.confirm({
                key: 'leadCallQueues',
                message: 'Are you sure that you want to proceed?',
                header: 'Confirmation',
                icon: 'pi pi-exclamation-triangle',
                accept: () => {
                    this.addCallQueue(this.leadId, e.value.code);
                },
                reject: () => {
                    this.selectedCall = '';
                },
            });
        } else {
            this.confirmationService.confirm({
                key: 'leadCallQueues',
                message: 'Call Queue is already added, select someother call Queue',
                header: 'Confirmation',
                icon: 'pi pi-exclamation-triangle',
                accept: () => {
                    this.selectedCall = '';
                },
            });
        }
    }

    confirmCallsDelete(e: UserCallQueueViewModel) {
        this.confirmationService.confirm({
            key: 'leadCallQueues',
            message: 'Are you sure that you want to delete the Call Queue?',
            header: 'Confirmation',
            icon: 'pi pi-exclamation-triangle',
            accept: () => {
                this.deleteSelectedCall(e.id);
            },
        });
    }

    deleteSelectedCall(id: string) {
        this.leadService.deleteLeadCall(this.leadId, id).subscribe({
            next: () => {
                this.getUserCallQueues();
                this.messageService.add({
                    key: 'tst',
                    severity: 'success',
                    summary: 'Success',
                    detail: 'Call Queue deleted successfully',
                });
            },
            error: () => {
                this.messageService.add({
                    key: 'tst',
                    severity: 'error',
                    summary: 'Error',
                    detail: 'something went wrong, please try again',
                });
            },
        });
    }
}
