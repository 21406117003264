/* eslint-disable @typescript-eslint/member-ordering */
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { ClientViewModel } from '@app/client/model/client-view.model';
import { ClientService } from '@app/client/services/client.service';
import { leadFilterModel as LeadFilterModel } from '@app/lead/model/lead-filter-model';
import { LeadViewModel } from '@app/lead/model/lead-view.model';
import { LeadListService } from '@app/lead/services/leadlist-service';
import { BulkAddNotesComponent } from '@app/shared/bulk-actions/bulk-add-notes/bulk-add-notes.component';
import { BulkAddRemoveCallqueueComponent } from '@app/shared/bulk-actions/bulk-add-remove-callqueue/bulk-add-remove-callqueue.component';
import { BulkAddRemoveTagComponent } from '@app/shared/bulk-actions/bulk-add-remove-tag/bulk-add-remove-tag.component';
import { BulkChangeAgentComponent } from '@app/shared/bulk-actions/bulk-change-agent/bulk-change-agent.component';
import { BulkChangeStatusComponent } from '@app/shared/bulk-actions/bulk-change-status/bulk-change-status.component';
import { DialerStateService, DialerState } from '@app/shared/services/dialer-service';
import { BaseComponentDirective } from '@core/component/base.component';
import { ConfirmationService, MenuItem } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { takeUntil } from 'rxjs';

@Component({
    selector: 'app-search',
    templateUrl: './search.component.html',
    styleUrls: ['./search.component.scss'],
    providers: [DialogService],
})
export class SearchComponent extends BaseComponentDirective implements OnInit {
    leadLoading: boolean;
    clientLoading: boolean;
    selectedLead = 'Lead';
    selectedClient = 'Client';
    tabOptions: any[];
    activePageCount = 1;
    paginateCount = 10;
    leads: LeadViewModel[] = [];
    clients: ClientViewModel[] = [];
    totalLeadRecordSize: number;
    totalClientRecordSize: number;
    searchval = '';
    nameSearch = '';
    phoneSearch = '';
    inlineSearchText = '';
    leadViewModel: LeadViewModel;
    leadItems: MenuItem[];
    clientItems: MenuItem[];
    contentStyle = { 'max-height': '500px', overflow: 'visible' };
    selectedItem: LeadViewModel[] = [];
    selectedList: string[] = [];
    selectedClientItem: ClientViewModel[] = [];
    selectedClientList: string[] = [];
    isChecked = false;
    isClientChecked = false;
    constructor(
        private readonly route: ActivatedRoute,
        private router: Router,
        private leadService: LeadListService,
        private clientService: ClientService,
        private dailerService: DialerStateService,
        private dialogService: DialogService,
        private confirmationService: ConfirmationService,
    ) {
        super();
        this.tabOptions = [
            { label: 'Leads', value: 'Leads' },
            { label: 'Clients', value: 'Clients' },
        ];
    }

    ngOnInit(): void {
        this.route.queryParams.subscribe((queryParams: Params) => {
            this.searchval = queryParams.searchKey as string;
            const filteredSearchValue = this.searchval.replace(/-/g, '');
            if (this.checkSearchType(filteredSearchValue)) {
                this.phoneSearch = filteredSearchValue;
                this.nameSearch = '';
            } else {
                this.nameSearch = this.searchval;
                this.phoneSearch = '';
            }
            this.getLeadSearchedList();
            this.getClientSearchedList();
        });

        this.leadItems = [
            {
                label: 'Bulk Delete',
                icon: 'pi pi-trash',
                command: () => {
                    this.bulkLeadDelete();
                },
            },
            {
                label: 'Add Tags',
                icon: 'pi pi-tag',
                command: () => {
                    if (this.checkForSelectedItems()) {
                        const ref = this.dialogService.open(BulkAddRemoveTagComponent, {
                            data: {
                                selectedList: this.selectedList,
                                isAdd: true,
                            },
                            header: 'Add Tags',
                            width: '40%',
                            contentStyle: this.contentStyle,
                            baseZIndex: 10000,
                        });
                        ref.onClose.subscribe(() => {
                            this.getLeadSearchedList();
                        });
                    }
                },
            },
            {
                label: 'Delete Tags',
                icon: 'pi pi-trash',
                command: () => {
                    if (this.checkForSelectedItems()) {
                        const ref = this.dialogService.open(BulkAddRemoveTagComponent, {
                            data: {
                                selectedList: this.selectedList,
                                isAdd: false,
                            },
                            header: 'Delete Tags',
                            width: '40%',
                            contentStyle: this.contentStyle,
                            baseZIndex: 10000,
                        });
                        ref.onClose.subscribe(() => {
                            this.getLeadSearchedList();
                        });
                    }
                },
            },
            {
                label: 'Add Notes',
                icon: 'pi pi-stop',
                command: () => {
                    if (this.checkForSelectedItems()) {
                        const ref = this.dialogService.open(BulkAddNotesComponent, {
                            data: {
                                selectedList: this.selectedList,
                            },
                            header: 'Add Notes',
                            width: '50%',
                            contentStyle: this.contentStyle,
                            baseZIndex: 10000,
                        });
                        ref.onClose.subscribe(() => {
                            this.getLeadSearchedList();
                        });
                    }
                },
            },
            {
                label: 'Add To CallQueues',
                icon: 'pi pi-phone',
                command: () => {
                    if (this.checkForSelectedItems()) {
                        const ref = this.dialogService.open(BulkAddRemoveCallqueueComponent, {
                            data: {
                                selectedList: this.selectedList,
                                isAdd: true,
                            },
                            header: 'Add to CallQueues',
                            width: '40%',
                            contentStyle: this.contentStyle,
                            baseZIndex: 10000,
                        });
                        ref.onClose.subscribe(() => {
                            this.getLeadSearchedList();
                        });
                    }
                },
            },
            {
                label: 'Delete CallQueues',
                icon: 'pi pi-trash',
                command: () => {
                    if (this.checkForSelectedItems()) {
                        const ref = this.dialogService.open(BulkAddRemoveCallqueueComponent, {
                            data: {
                                selectedList: this.selectedList,
                                isAdd: false,
                            },
                            header: 'Delete CallQueues',
                            width: '40%',
                            contentStyle: this.contentStyle,
                            baseZIndex: 10000,
                        });
                        ref.onClose.subscribe(() => {
                            this.getLeadSearchedList();
                        });
                    }
                },
            },
            {
                label: 'Change Status',
                icon: 'pi pi-circle-fill',
                command: () => {
                    if (this.checkForSelectedItems()) {
                        const ref = this.dialogService.open(BulkChangeStatusComponent, {
                            data: {
                                selectedList: this.selectedList,
                            },
                            header: 'Change Status',
                            width: '40%',
                            contentStyle: this.contentStyle,
                            baseZIndex: 10000,
                        });
                        ref.onClose.subscribe(() => {
                            this.getLeadSearchedList();
                        });
                    }
                },
            },
            {
                label: 'Change Agent',
                icon: 'pi pi-user',
                command: () => {
                    if (this.checkForSelectedItems()) {
                        const ref = this.dialogService.open(BulkChangeAgentComponent, {
                            data: {
                                selectedList: this.selectedList,
                            },
                            header: 'Change Agent',
                            width: '40%',
                            contentStyle: this.contentStyle,
                            baseZIndex: 10000,
                        });
                        ref.onClose.subscribe(() => {
                            this.getLeadSearchedList();
                        });
                    }
                },
            },
        ];

        this.clientItems = [
            {
                label: 'Bulk Delete',
                icon: 'pi pi-trash',
                command: () => {
                    this.bulkClientDelete();
                },
            },
            {
                label: 'Add Tags',
                icon: 'pi pi-tag',
                command: () => {
                    if (this.checkForSelectedClientItems()) {
                        const ref = this.dialogService.open(BulkAddRemoveTagComponent, {
                            data: {
                                selectedList: this.selectedClientList,
                                isAdd: true,
                            },
                            header: 'Add Tags',
                            width: '40%',
                            contentStyle: this.contentStyle,
                            baseZIndex: 10000,
                        });
                        ref.onClose.subscribe(() => {
                            this.getClientSearchedList();
                        });
                    }
                },
            },
            {
                label: 'Delete Tags',
                icon: 'pi pi-trash',
                command: () => {
                    if (this.checkForSelectedClientItems()) {
                        const ref = this.dialogService.open(BulkAddRemoveTagComponent, {
                            data: {
                                selectedList: this.selectedClientList,
                                isAdd: false,
                            },
                            header: 'Delete Tags',
                            width: '40%',
                            contentStyle: this.contentStyle,
                            baseZIndex: 10000,
                        });
                        ref.onClose.subscribe(() => {
                            this.getClientSearchedList();
                        });
                    }
                },
            },
            {
                label: 'Add Notes',
                icon: 'pi pi-stop',
                command: () => {
                    if (this.checkForSelectedClientItems()) {
                        const ref = this.dialogService.open(BulkAddNotesComponent, {
                            data: {
                                selectedList: this.selectedClientList,
                            },
                            header: 'Add Notes',
                            width: '50%',
                            contentStyle: this.contentStyle,
                            baseZIndex: 10000,
                        });
                        ref.onClose.subscribe(() => {
                            this.getClientSearchedList();
                        });
                    }
                },
            },
            {
                label: 'Add To CallQueues',
                icon: 'pi pi-phone',
                command: () => {
                    if (this.checkForSelectedClientItems()) {
                        const ref = this.dialogService.open(BulkAddRemoveCallqueueComponent, {
                            data: {
                                selectedList: this.selectedClientList,
                                isAdd: true,
                            },
                            header: 'Add to CallQueues',
                            width: '40%',
                            contentStyle: this.contentStyle,
                            baseZIndex: 10000,
                        });
                        ref.onClose.subscribe(() => {
                            this.getClientSearchedList();
                        });
                    }
                },
            },
            {
                label: 'Delete CallQueues',
                icon: 'pi pi-trash',
                command: () => {
                    if (this.checkForSelectedClientItems()) {
                        const ref = this.dialogService.open(BulkAddRemoveCallqueueComponent, {
                            data: {
                                selectedList: this.selectedClientList,
                                isAdd: false,
                            },
                            header: 'Delete CallQueues',
                            width: '40%',
                            contentStyle: this.contentStyle,
                            baseZIndex: 10000,
                        });
                        ref.onClose.subscribe(() => {
                            this.getClientSearchedList();
                        });
                    }
                },
            },
            {
                label: 'Change Status',
                icon: 'pi pi-circle-fill',
                command: () => {
                    if (this.checkForSelectedClientItems()) {
                        const ref = this.dialogService.open(BulkChangeStatusComponent, {
                            data: {
                                selectedList: this.selectedClientList,
                            },
                            header: 'Change Status',
                            width: '40%',
                            contentStyle: this.contentStyle,
                            baseZIndex: 10000,
                        });
                        ref.onClose.subscribe(() => {
                            this.getClientSearchedList();
                        });
                    }
                },
            },
            {
                label: 'Change Agent',
                icon: 'pi pi-user',
                command: () => {
                    if (this.checkForSelectedClientItems()) {
                        const ref = this.dialogService.open(BulkChangeAgentComponent, {
                            data: {
                                selectedList: this.selectedClientList,
                            },
                            header: 'Change Agent',
                            width: '40%',
                            contentStyle: this.contentStyle,
                            baseZIndex: 10000,
                        });
                        ref.onClose.subscribe(() => {
                            this.getClientSearchedList();
                        });
                    }
                },
            },
        ];
    }

    onSelect(event: { target: { checked: boolean } }, leadViewModel: LeadViewModel) {
        if (!event.target.checked) {
            this.isChecked = false;
        }
        leadViewModel.isChecked = event.target.checked;
    }

    onClientSelect(event: { target: { checked: boolean } }, leadViewModel: LeadViewModel) {
        if (!event.target.checked) {
            this.isClientChecked = false;
        }
        leadViewModel.isChecked = event.target.checked;
    }

    checkForSelectedItems() {
        this.selectedList = [];
        this.selectedItem = this.leads.filter((request) => {
            return request.isChecked === true;
        });

        this.selectedItem.forEach((ele) => {
            this.selectedList.push(ele.id);
        });
        if (this.selectedItem.length) {
            return true;
        } else {
            this.messageService.add({
                key: 'tst',
                severity: 'warn',
                summary: 'Warn',
                detail: 'Please select at least one lead',
            });
            return false;
        }
    }

    checkForSelectedClientItems() {
        this.selectedClientList = [];
        this.selectedClientItem = this.clients.filter((request) => {
            return request.isChecked === true;
        });

        this.selectedClientItem.forEach((ele) => {
            this.selectedClientList.push(ele.id);
        });
        if (this.selectedClientItem.length) {
            return true;
        } else {
            this.messageService.add({
                key: 'tst',
                severity: 'warn',
                summary: 'Warn',
                detail: 'Please select at least one lead',
            });
            return false;
        }
    }

    bulkLeadDelete() {
        if (this.checkForSelectedItems()) {
            this.confirmationService.confirm({
                message: 'Are you sure that you want to proceed to delete Lead(s)?',
                header: 'Confirmation',
                icon: 'pi pi-exclamation-triangle',
                accept: () => {
                    this.deleteLead(this.selectedList);
                },
            });
        }
    }

    bulkClientDelete() {
        if (this.checkForSelectedClientItems()) {
            this.confirmationService.confirm({
                message: 'Are you sure that you want to proceed to delete Lead(s)?',
                header: 'Confirmation',
                icon: 'pi pi-exclamation-triangle',
                accept: () => {
                    this.deleteClientLead(this.selectedClientList);
                },
            });
        }
    }

    deleteLead(list: string[]) {
        this.leadService.deleteLead(list).subscribe(() => {
            this.getLeadSearchedList();
            this.messageService.add({
                key: 'tst',
                severity: 'success',
                summary: 'Success',
                detail: 'Lead deleted successfully',
            });
        });
    }

    deleteClientLead(list: string[]) {
        this.clientService
            .deleteClient(list)
            .pipe(takeUntil(this.onDestroy$))
            .subscribe(() => {
                this.isClientChecked = false;
                this.getClientSearchedList();
                this.messageService.add({
                    key: 'tst',
                    severity: 'success',
                    summary: 'Success',
                    detail: 'Client(s) deleted successfully',
                });
            });
    }

    selectAllCategories(event: { target: { checked: boolean } }) {
        this.selectedList = [];
        this.leads.forEach((ele) => {
            ele.isChecked = event.target.checked;
        });

        this.selectedItem = this.leads.filter((request) => {
            return request.isChecked === true;
        });
        this.selectedItem.forEach((ele) => {
            this.selectedList.push(ele.id);
        });
    }

    selectAllClientCategories(event: { target: { checked: boolean } }) {
        this.selectedClientList = [];
        this.clients.forEach((ele) => {
            ele.isChecked = event.target.checked;
        });

        this.selectedClientItem = this.clients.filter((request) => {
            return request.isChecked === true;
        });
        this.selectedClientItem.forEach((ele) => {
            this.selectedClientList.push(ele.id);
        });
        console.log(this.selectedClientItem);
    }

    checkSearchType(val) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        return !isNaN(val);
    }

    getLeadSearchedList() {
        this.leadLoading = true;
        const searchFilterModel: LeadFilterModel = {
            pageNumber: this.activePageCount,
            pageSize: this.paginateCount,
            orderBy: '',
            orderByDirection: '',
            name: this.nameSearch,
            agents: [],
            leadStatuses: [],
            tags: [],
            birthdayFilter: {
                start: '',
                end: '',
            },
            leadCallDateFilter: {
                start: '',
                end: '',
            },
            includeInActive: false,
            stateId: '',
            countyId: '',
            callQueueId: '',
            phoneNumber: this.phoneSearch,
        };
        this.leadService.getAllLeads(searchFilterModel).subscribe((leadData) => {
            this.leads = leadData.data;
            this.totalLeadRecordSize = leadData.totalRecords;
            this.leadLoading = false;
        });
    }

    getClientSearchedList() {
        this.clientLoading = true;
        const searchFilterModel: LeadFilterModel = {
            pageNumber: this.activePageCount,
            pageSize: this.paginateCount,
            orderBy: '',
            orderByDirection: '',
            name: this.nameSearch,
            agents: [],
            leadStatuses: [],
            tags: [],
            birthdayFilter: {
                start: '',
                end: '',
            },
            leadCallDateFilter: {
                start: '',
                end: '',
            },
            includeInActive: false,
            stateId: '',
            countyId: '',
            callQueueId: '',
            phoneNumber: this.phoneSearch,
        };
        this.clientService.getAllClients(searchFilterModel).subscribe((clientData) => {
            this.clients = clientData.data;
            this.totalClientRecordSize = clientData.totalRecords;
            this.clientLoading = false;
        });
    }

    getLeadpageCount(event) {
        this.activePageCount = event.page + 1;
        this.paginateCount = event.rows;
        this.getLeadSearchedList();
    }

    getClientpageCount(event) {
        this.activePageCount = event.page + 1;
        this.paginateCount = event.rows;
        this.getClientSearchedList();
    }

    routeUser(id: string) {
        void this.router.navigate([`leads/lead-details/${id}`]);
        window.scroll(0, 0);
    }

    routeClient(id: string) {
        void this.router.navigate([`clients/client-details/${id}`]);
        window.scroll(0, 0);
    }

    gotoLeadDialer(leadViewModel: LeadViewModel, e): void {
        const singleLead = [];
        this.leadViewModel = leadViewModel;
        singleLead.push(this.leadViewModel);
        this.dailerService.set(new DialerState({ showDialerBar: false }));
        if (e.target.getAttribute('data-dialer-Type') === 'Lead') {
            localStorage.setItem('DialerType', 'Lead');
        } else {
            localStorage.setItem('DialerType', 'Client');
        }
        if (e.target.id === 'Mobile Phone') {
            const updatedData = { id: leadViewModel['id'], cellPhone: leadViewModel['cellPhone'] };
            singleLead.push(updatedData);
        } else if (e.target.id === 'Work Phone') {
            const updatedData = { id: leadViewModel['id'], cellPhone: leadViewModel['workPhone'] };
            singleLead.push(updatedData);
        } else if (e.target.id === 'Home Phone') {
            const updatedData = { id: leadViewModel['id'], cellPhone: leadViewModel['homePhone'] };
            singleLead.push(updatedData);
        }

        localStorage.removeItem('Launch_Dialer');
        localStorage.setItem('callType', e.target.id);

        setTimeout(() => {
            localStorage.setItem('Launch_Dialer', JSON.stringify(singleLead));
            const body = document.getElementsByTagName('body')[0];
            body.classList.add('dialer-open');
            this.dailerService.set(new DialerState({ showDialerBar: true }));
            window.scroll(0, 0);
        }, 2);
    }
}
