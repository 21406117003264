<ng-container>
    <div class="container-fluid">
        <div class="row">
            <div class="mt-3 card col-xl-12 px-0">
                <div>
                    <div class="my-3">
                       
                        <p-toolbar>
                            <ng-template pTemplate="left">
                                <h2 class="px-3">Leads</h2>
                                <p-splitButton label="Bulk Actions" icon="pi pi-cog" class="ml-2" [model]="leadItems" appendTo="body"></p-splitButton>
                            </ng-template>
                           
                        </p-toolbar>
                        <p-table
                            #dt
                            [value]="leads"
                            styleClass="p-datatable-striped"
                            [rowHover]="true"
                            [rows]="10"
                            [paginator]="false"
                            [rowsPerPageOptions]="[10, 20, 30, 40, 50]"
                            [loading]="leadLoading"
                            responsiveLayout="scroll"
                            [showCurrentPageReport]="true"
                            [lazy]="true"
                        >
                            <ng-template pTemplate="header">
                                <tr>
                                    <th width="5%">
                                        <input [(ngModel)]="isChecked" id="selectAll" type="checkbox" (click)="selectAllCategories($event)" />
                                    </th>
                                    <th style="width: 10%">Name</th>
                                    <th style="width: 10%">Address</th>
                                    <th style="width: 15%">Phone</th>
                                    <th style="width: 10%">Lead Status</th>
                                    <th style="width: 10%">Active Tags</th>
                                    <th style="width: 10%">Agent</th>
                                    <th style="width: 10%">Date of Birth</th>
                                    <th style="width: 10%">Last call date</th>
                                    <th style="width: 10%">Action</th>
                                </tr>
                            </ng-template>

                            <ng-template pTemplate="body" let-i="rowIndex" let-lead>
                                <tr>
                                    <td>
                                        <input type="checkbox" [checked]="lead.isChecked" (change)="onSelect($event, lead)" id="{{ lead.id }}" value="{{ lead.id }}" />
                                    </td>
                                    <td>
                                        <a (click)="routeUser(lead.id)" class="nav-link">{{ lead.firstName }} {{ lead.lastName }}</a>
                                    </td>
                                    <td>
                                        <div class="flex flex-column">
                                            <div>{{ lead.address }}</div>
                                            <div class="flex flex-column">
                                                <div class="pr-1">{{ lead.city }} {{ lead.state }}</div>
                                                <div>{{ lead.address.zipCode }}</div>
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        <div class="py-1" *ngIf="lead.cellPhone !== null">
                                            <span [ngStyle]="{ backgroundColor: 'var(--green-500)', color: 'white', borderRadius: '50px', padding: '2px 6px', fontSize: '13px' }" class="mr-2">C</span>
                                            <a (click)="gotoLeadDialer(lead, $event)" [attr.data-dialer-Type]="selectedLead" id="cellPhone" class="dialer-btn">
                                                {{ lead.cellPhone | mask: '(000) 000-0000' }}</a
                                            >
                                        </div>
                                        <div class="py-1" *ngIf="lead.homePhone !== null">
                                            <span [ngStyle]="{ backgroundColor: 'var(--yellow-500)', color: 'white', borderRadius: '50px', padding: '2px 6px', fontSize: '13px' }" class="mr-2">H</span>
                                            <a (click)="gotoLeadDialer(lead, $event)" [attr.data-dialer-Type]="selectedLead" id="homePhone" class="dialer-btn">
                                                {{ lead.homePhone | mask: '(000) 000-0000' }}</a
                                            >
                                        </div>
                                    </td>
                                    <td>{{ lead.leadStatus }}</td>
                                    <td>
                                        <span *ngFor="let tag of lead.tags">
                                            <span class="tag-btn" *ngIf="tag !== null">{{ tag }}</span>
                                        </span>
                                    </td>
                                    <td>{{ lead.agent }}</td>
                                    <td>{{ lead.dateOfBirth | date: 'MM/dd/yyyy' }}</td>
                                    <td>{{ lead.lastCallDate | date: 'MM-dd-YYYY h:mm:ss' }}</td>
                                    <td>
                                        <button *ngIf="lead.leadCardUrl !== ''" (click)="op.toggle($event)" pButton pRipple icon="pi pi-image" class="p-button-rounded p-button-help mx-2"></button>
                                        <p-overlayPanel [showTransitionOptions]="'0ms'" [hideTransitionOptions]="'0ms'" #op [dismissable]="true" [showCloseIcon]="false">
                                            <ng-template pTemplate>
                                                <img [src]="lead.leadCardUrl" alt="{{ lead.leadCardUrl }}" title="{{ lead.leadCardUrl }}" width="500" height="340" />
                                            </ng-template>
                                        </p-overlayPanel>
                                    </td>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="emptymessage">
                                <tr>
                                    <td colspan="11" class="text-center">No data found.</td>
                                </tr>
                            </ng-template>
                        </p-table>
                        <p-paginator
                            [showCurrentPageReport]="true"
                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                            (onPageChange)="getLeadpageCount($event)"
                            [rows]="10"
                            [totalRecords]="totalLeadRecordSize"
                            [rowsPerPageOptions]="[10, 20, 30, 50]"
                        ></p-paginator>
                    </div>

                    <div class="mt-3">
                        <p-toolbar>
                            <ng-template pTemplate="left">
                                <h2 class="px-3">Clients</h2>
                                <p-splitButton label="Bulk Actions" icon="pi pi-cog" class="ml-2" [model]="clientItems" appendTo="body"></p-splitButton>
                            </ng-template>
                           
                        </p-toolbar>
                        <p-table
                            #dt
                            [value]="clients"
                            styleClass="p-datatable-striped"
                            [rowHover]="true"
                            [rows]="10"
                            [paginator]="false"
                            [rowsPerPageOptions]="[10, 20, 30, 40, 50]"
                            [loading]="clientLoading"
                            responsiveLayout="scroll"
                            [lazy]="true"
                            [showCurrentPageReport]="true"
                        >
                            <ng-template pTemplate="header">
                                <tr>
                                    <th width="5%">
                                        <input [(ngModel)]="isClientChecked" id="selectClientAll" type="checkbox" (click)="selectAllClientCategories($event)" />
                                    </th>
                                    <th style="width: 10%">Name</th>
                                    <th style="width: 10%">Address</th>
                                    <th style="width: 15%">Phone</th>
                                    <th style="width: 10%">Lead Status</th>
                                    <th style="width: 10%">Active Tags</th>
                                    <th style="width: 10%">Agent</th>
                                    <th style="width: 10%">Date of Birth</th>
                                    <th style="width: 10%">Last call date</th>
                                    <th style="width: 10%">Action</th>
                                </tr>
                            </ng-template>

                            <ng-template pTemplate="body" let-i="rowIndex" let-data>
                                <tr>
                                    <td>
                                        <input type="checkbox" [checked]="data.isChecked" (change)="onClientSelect($event, data)" id="{{ data.id }}" value="{{ data.id }}" />
                                    </td>
                                    <td>
                                        <a class="nav-link" (click)="routeClient(data.id)">{{ data.firstName }} {{ data.lastName }}</a>
                                    </td>
                                    <td>
                                        <div class="flex flex-column">
                                            <div>{{ data.address }}</div>
                                            <div class="flex flex-column">
                                                <div class="pr-1">{{ data.city }} {{ data.state }}</div>
                                                <div>{{ data.address.zipCode }}</div>
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        <div class="py-1" *ngIf="data.cellPhone !== null">
                                            <span [ngStyle]="{ backgroundColor: 'var(--green-500)', color: 'white', borderRadius: '50px', padding: '2px 6px', fontSize: '13px' }" class="mr-2">C</span>
                                            <a (click)="gotoLeadDialer(data, $event)" [attr.data-dialer-Type]="selectedClient" id="cellPhone" class="dialer-btn">
                                                {{ data.cellPhone | mask: '(000) 000-0000' }}</a
                                            >
                                        </div>
                                        <div class="py-1" *ngIf="data.homePhone !== null">
                                            <span [ngStyle]="{ backgroundColor: 'var(--yellow-500)', color: 'white', borderRadius: '50px', padding: '2px 6px', fontSize: '13px' }" class="mr-2">H</span>
                                            <a (click)="gotoLeadDialer(data, $event)" [attr.data-dialer-Type]="selectedClient" id="homePhone" class="dialer-btn">
                                                {{ data.homePhone | mask: '(000) 000-0000' }}</a
                                            >
                                        </div>
                                    </td>
                                    <td>{{ data.leadStatus }}</td>
                                    <td>
                                        <span *ngFor="let tag of data.tags">
                                            <span class="tag-btn" *ngIf="tag !== null">{{ tag }}</span>
                                        </span>
                                    </td>
                                    <td>{{ data.agent }}</td>
                                    <td>{{ data.dateOfBirth | date: 'MM/dd/yyyy' }}</td>
                                    <td>{{ data.lastCallDate }}</td>
                                    <td>
                                        <button *ngIf="data.leadCardUrl !== ''" (click)="opp.toggle($event)" pButton pRipple icon="pi pi-image" class="p-button-rounded p-button-help mx-2"></button>
                                        <p-overlayPanel [showTransitionOptions]="'0ms'" [hideTransitionOptions]="'0ms'" #opp [dismissable]="true" [showCloseIcon]="false">
                                            <ng-template pTemplate>
                                                <img [src]="data.leadCardUrl" alt="{{ data.leadCardUrl }}" title="{{ data.leadCardUrl }}" width="500" height="340" />
                                            </ng-template>
                                        </p-overlayPanel>
                                    </td>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="emptymessage">
                                <tr>
                                    <td colspan="11" class="text-center">No data found.</td>
                                </tr>
                            </ng-template>
                        </p-table>
                        <p-paginator
                            [showCurrentPageReport]="true"
                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                            (onPageChange)="getClientpageCount($event)"
                            [rows]="10"
                            [totalRecords]="totalClientRecordSize"
                            [rowsPerPageOptions]="[10, 20, 30, 50]"
                        ></p-paginator>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-container>
<p-confirmDialog [style]="{ width: '50vw' }" [baseZIndex]="10000"></p-confirmDialog>