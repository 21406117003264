import { Component, Input, OnInit } from '@angular/core';
import { BaseComponentDirective } from '@core/component/base.component';
import { CallLogViewModel } from '@app/shared/models/call-log-model';
import { CalllogService } from '@app/shared/services/call-log-service';

@Component({
    selector: 'app-lead-call-history',
    templateUrl: './lead-call-history.component.html',
    styleUrls: ['./lead-call-history.component.scss'],
})
export class LeadCallHistoryComponent extends BaseComponentDirective implements OnInit {
    @Input() leadId: string;
    selectedUsers: CallLogViewModel;
    callLogs: CallLogViewModel[];

    constructor(private readonly calllogService: CalllogService) {
        super();
    }

    ngOnInit(): void {
        super.ngOnInit();
        this.getCallLogsList();
    }

    getCallLogsList() {
        this.calllogService.getCallLog(this.leadId).subscribe({
            next: (res) => {
                this.callLogs = res;
            },
            error: (_error) => {
                console.log(_error);
            },
        });
    }
}
