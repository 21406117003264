import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '@app/authentication/services/authentication.service';
import { LeadListService } from '@app/lead/services/leadlist-service';
import { BaseComponentDirective } from '@core/component/base.component';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import BulkActionsEnum from '../bulk-actions.enum';
import { BulkActionsService } from '../bulk-actions.service';

@Component({
    selector: 'app-bulk-add-remove-callqueue',
    templateUrl: './bulk-add-remove-callqueue.component.html',
})
export class BulkAddRemoveCallqueueComponent extends BaseComponentDirective implements OnInit {
    selectedList: Array<string>;
    selectedCallQueues = [];
    callQueues = [];
    userId = this.authService.getLoggedInId();
    loading = false;
    isAdd = false;

    constructor(
        public config: DynamicDialogConfig,
        private leadService: LeadListService,
        private authService: AuthenticationService,
        private bulkActionsService: BulkActionsService,
        public ref: DynamicDialogRef,
    ) {
        super();
    }

    ngOnInit() {
        super.ngOnInit();
        this.selectedList = this.config.data.selectedList as string[];
        this.isAdd = this.config.data.isAdd as boolean;
        this.getCallQueues();
    }

    getCallQueues() {
        this.leadService.getCallQueues().subscribe((res) => {
            res.forEach((element) => {
                this.callQueues.push({
                    name: element.name,
                    code: element.id,
                });
            });
        });
    }

    onSubmit() {
        if (this.selectedCallQueues.length === 0) {
            this.loading = false;
            return;
        }
        this.loading = true;
        this.bulkActionsService
            .bulkActions(this.userId, {
                action: this.isAdd ? BulkActionsEnum.AddCallQueue : BulkActionsEnum.DeleteCallQueue,
                userIds: this.selectedList,
                entityIds: this.selectedCallQueues,
            })
            .subscribe({
                next: () => {
                    this.selectedCallQueues = [];
                    this.loading = false;
                    this.messageService.add({
                        key: 'tst',
                        severity: 'success',
                        summary: 'Success',
                        detail: this.isAdd ? 'Call Queues added successfully' : 'Call Queues deleted successfully',
                    });
                    this.ref.close();
                },
                error: () => {
                    this.loading = false;
                },
            });
    }
}
