export class UserMedicationModel {
    id?: string;
    statusId?: number;
    value: string;
    userId: string;

    constructor(init?: Partial<UserMedicationModel>) {
        Object.assign(this, init);
    }
}
