export class UserNoteModel {
    id?: string;
    userId?: string;
    value: string;
    noteTypeId: number;
    statusId: number;
    createdDate?: Date;
    createdBy?: string;

    constructor(init?: Partial<UserNoteModel>) {
        Object.assign(this, init);
    }
}
