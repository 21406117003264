import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '@app/authentication/services/authentication.service';
import { LeadListService } from '@app/lead/services/leadlist-service';
import { BaseComponentDirective } from '@core/component/base.component';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import BulkActionsEnum from '../bulk-actions.enum';
import { BulkActionsService } from '../bulk-actions.service';

@Component({
    selector: 'app-bulk-change-agent',
    templateUrl: './bulk-change-agent.component.html',
})
export class BulkChangeAgentComponent extends BaseComponentDirective implements OnInit {
    selectedList: Array<string>;
    selectedAgent: string;
    agentsList = [];
    loading = false;
    getUserId = this.authService.getLoggedInId();

    constructor(
        public config: DynamicDialogConfig,
        private leadService: LeadListService,
        private authService: AuthenticationService,
        private bulkActionsService: BulkActionsService,
        public ref: DynamicDialogRef,
    ) {
        super();
    }

    ngOnInit() {
        super.ngOnInit();
        this.selectedList = this.config.data.selectedList as string[];
        this.getAgentsList();
    }

    getAgentsList() {
        this.leadService.getAgentList().subscribe((baseLookupModel) => {
            this.agentsList = [];
            baseLookupModel.forEach((element) => {
                this.agentsList.push({
                    name: element.value,
                    code: element.id,
                });
            });
        });
    }

    onSubmit() {
        if (!this.selectedAgent) {
            this.loading = false;
            return;
        }
        this.loading = true;
        this.bulkActionsService
            .bulkActions(this.getUserId, {
                action: BulkActionsEnum.ChangeAgent,
                userIds: this.selectedList,
                entityId: this.selectedAgent,
            })
            .subscribe({
                next: () => {
                    this.selectedAgent = '';
                    this.loading = false;
                    this.messageService.add({
                        key: 'tst',
                        severity: 'success',
                        summary: 'Success',
                        detail: 'Agent changed successfully',
                    });
                    this.ref.close();
                },
                error: () => {
                    this.loading = false;
                },
            });
    }
}
