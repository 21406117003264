/* eslint-disable @typescript-eslint/unbound-method */
import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ClientViewModel } from '@app/client/model/client-view.model';
import { ClientService } from '@app/client/services/client.service';
import { LeadListService } from '@app/lead/services/leadlist-service';
import { BaseComponentDirective } from '@core/component/base.component';
import { first } from 'rxjs';
import { sortBy } from 'lodash';
import moment from 'moment';

@Component({
    selector: 'app-lead-edit',
    templateUrl: './lead-edit.component.html',
    styleUrls: ['./lead-edit.component.scss'],
})
export class LeadEditComponent extends BaseComponentDirective implements OnInit {
    @Input() contactInfo: ClientViewModel;
    @Input() type = 'Client';
    @Input() userId: string;
    displayEditModal = false;
    formGroup: UntypedFormGroup;
    leadsList: string[] = [];
    loading = false;

    constructor(private formBuilder: UntypedFormBuilder, private clientService: ClientService, private leadListService: LeadListService) {
        super();
    }

    ngOnInit() {
        super.ngOnInit();
        this.formGroup = this.formBuilder.group({
            firstName: ['', Validators.required],
            lastName: ['', Validators.required],
            email: [''],
            dateOfBirth: [''],
            leadSource: [''],
        });

        this.getLeadSourceList();
    }

    getLeadSourceList() {
        this.clientService.getLeadSource().subscribe((res) => {
            this.leadsList = [];
            const sortedArray = sortBy(res, (o) => {
                return o.value;
            });
            sortedArray.forEach((element) => {
                this.leadsList.push(element.value);
            });
        });
    }

    openEdit() {
        this.displayEditModal = true;
        this.formGroup.patchValue({
            firstName: this.contactInfo?.firstName,
            lastName: this.contactInfo?.lastName,
            email: this.contactInfo?.email,
            dateOfBirth: this.contactInfo?.dateOfBirth ? new Date(this.contactInfo.dateOfBirth) : null,
            leadSource: this.contactInfo?.leadSource,
        });
    }

    updateLead() {
        this.loading = true;
        if (this.formGroup.invalid) {
            this.loading = false;
            return;
        }

        if (this.type === 'Client') {
            this.clientService
                .updateClient(
                    {
                        firstName: this.formGroup.value.firstName,
                        lastName: this.formGroup.value.lastName,
                        email: this.formGroup.value.email,
                        dateOfBirth: moment(this.formGroup.value.dateOfBirth).format('YYYY-MM-DDTHH:mm:ss'),
                        leadSource: this.formGroup.value.leadSource,
                    },
                    this.userId,
                )
                .pipe(first())
                .subscribe({
                    next: () => {
                        this.contactInfo.firstName = this.formGroup.value.firstName as string;
                        this.contactInfo.lastName = this.formGroup.value.lastName as string;
                        this.contactInfo.email = this.formGroup.value.email as string;
                        this.contactInfo.dateOfBirth = this.formGroup.value.dateOfBirth;
                        this.contactInfo.leadSource = this.formGroup.value.leadSource;
                        this.displayEditModal = false;
                        this.messageService.add({
                            key: 'tst',
                            severity: 'success',
                            summary: 'Success',
                            detail: 'Contact info updated successfully',
                        });
                    },
                    error: () => {
                        this.loading = false;
                    },
                });
        } else {
            this.leadListService
                .updateLead(
                    {
                        firstName: this.formGroup.value.firstName,
                        lastName: this.formGroup.value.lastName,
                        email: this.formGroup.value.email,
                        dateOfBirth: moment(this.formGroup.value.dateOfBirth).format('YYYY-MM-DDTHH:mm:ss'),
                        leadSource: this.formGroup.value.leadSource,
                    },
                    this.userId,
                )
                .pipe(first())
                .subscribe({
                    next: () => {
                        this.contactInfo.firstName = this.formGroup.value.firstName as string;
                        this.contactInfo.lastName = this.formGroup.value.lastName as string;
                        this.contactInfo.email = this.formGroup.value.email as string;
                        this.contactInfo.dateOfBirth = this.formGroup.value.dateOfBirth;
                        this.contactInfo.leadSource = this.formGroup.value.leadSource;
                        this.displayEditModal = false;
                        this.messageService.add({
                            key: 'tst',
                            severity: 'success',
                            summary: 'Success',
                            detail: 'Contact info updated successfully',
                        });
                    },
                    error: () => {
                        this.loading = false;
                    },
                });
        }
    }

    isControlValid(controlName: string): boolean {
        const control = this.formGroup.controls[controlName];
        return control.valid && (control.dirty || control.touched);
    }

    isControlInvalid(controlName: string): boolean {
        const control = this.formGroup.controls[controlName];
        return control.invalid && (control.dirty || control.touched);
    }
}
