<div class="d-flex w-100 bg-white justify-content-between border-bottom">
    <div class="d-flex flex-row align-items-center">
        <a href="" class="pl-3">
            <img src="./assets/media/msis/main-logo.png" class="logo" />
        </a>
        <p-megaMenu [model]="items"></p-megaMenu>
    </div>
    <div class="px-3 py-3 d-flex flex-row align-items-center">
        <div class="d-flex justify-content-between align-items-center mx-4">
            <div class="p-inputgroup">
                <input type="text" pInputText class="p-inputtext p-component p-element" placeholder="search" (keydown.enter)="search()" [(ngModel)]="searchText" maxlength="30" name="navsearch" />
                <button type="button" pButton pRipple icon="pi pi-search" styleClass="p-button-warn px-2" (click)="search()"></button>
            </div>
        </div>
        <!-- <a routerLink="coming-soon" class="mr-4"><i class="pi pi-bell p-mr-4 p-text-secondary" pBadge style="font-size: 2rem;" value="2" severity="danger"></i></a> -->
        <p-avatar icon="pi pi-user" styleClass="p-mr-2" size="xlarge" (click)="menu.toggle($event)"> </p-avatar>
    </div>
</div>
<p-menu #menu [popup]="true" [model]="profileItems" styleClass="header-alignment"></p-menu>
<div *ngIf="showDialer">
    <app-dialer-bar></app-dialer-bar>
</div>
<div class="p-3 d-flex flex-row align-items-center justify-content-between" style="background-color: white; border-top: 1px solid #eef0f8" *ngIf="showImpersonate">
    <p class="m-0">you are impersonating user: {{ impersonatingUser }}</p>
    <button pButton type="button" icon="pi pi-sign-out" label="Exit Impersonate" class="p-button-outlined p-button-danger" (click)="revertUserImpersonate()"></button>
</div>
