<ng-container>
    <div *ngIf="loading" class="spinner-view">
        <app-inline-loader></app-inline-loader>
    </div>
    <div class="container-fluid px-0">
        <div class="p-0 card card-custom col-xl-12 calendar-events-tab">
            <div class="calendar-block">
                <div class="py-3 px-5 text-right flex flex-row align-items-center justify-content-between">
                    <h3>{{ currentMonth }}</h3>
                    <div>
                        <button pButton type="button" label="Today" (click)="setToday()"
                            class="p-button-outlined p-button-rounded px-5"></button>
                        <button pButton type="button" icon="pi pi-angle-left" (click)="setPevMonth()"
                            class="p-button-outlined p-button-rounded px-2 mx-3"></button>
                        <button pButton type="button" icon="pi pi-angle-right" (click)="setNextMonth()"
                            class="p-button-outlined p-button-rounded px-2"></button>
                    </div>
                </div>
                <div id="calendarEventsTab"></div>
            </div>
        </div>
    </div>
</ng-container>

<div *ngIf="showModal">
    <p-dialog header="Header" appendTo="body" (onHide)="cancel()" [(visible)]="display" [modal]="true"
        [style]="{ width: '50vw' }" [baseZIndex]="10000" [draggable]="false" [resizable]="false">
        <ng-template pTemplate="header" class="py-3">
            <h6 class="m-0">{{ modalTitle }}</h6>
        </ng-template>

        <app-add-calendar-event (getCalendarEventsList)="events" (getCalendarCreatedList)="getCalendarCreatedList()"
            (getCalendarDeletedEvent)="getCalendarDeletedEvent()" (updateModalTitle)="updateModalTitle($event)"
            [agentList]="agentOptions" [categories]="categoryOptions" [selectedSchedule]="selectedSchedule"
            [modalAction]="modalAction" (closeModal)="cancel()" [leadUserId]="leadUserId" [clientUserId]="clientUserId">
        </app-add-calendar-event>
    </p-dialog>
</div>