import { Injectable } from '@angular/core';
import { CallerIdModel } from '@app/communication/model/caller-id.model';
import { TwiMLResult } from '@app/communication/model/twiml-result.model';
import { UserPhoneDialerViewModel, UserPhoneViewModel } from '@app/user-settings/models/phone-model';
import { environment } from '@environments/environment';
import { StringResponse } from '@shared/model/string-response';
import { HttpUtility } from '@utility/httpUtility';
import { Observable } from 'rxjs';
import { CallerModel } from '../model/call.model';
import { VoiceMailModel } from '../model/voice-mail.model';
import { BaseLookupModel } from '@app/user-settings/models/agent-lookup-model';

@Injectable({ providedIn: 'root' })
export class DialerService {
    constructor(private httpUtility: HttpUtility) {}

    getLeadDailer(includeType: boolean, includePrecallerAgents: boolean): Observable<UserPhoneDialerViewModel[]> {
        return this.httpUtility.get(`${environment.apiUrl}/dialer-phones?includeEveryone=${includeType}&includePrecallerAgents=${includePrecallerAgents}`);
    }

    getVoiceMailList(): Observable<BaseLookupModel[]> {
        return this.httpUtility.get(`${environment.apiUrl}/voicegreeting?includeAll=false`);
    }

    getUserPhones(id: string): Observable<UserPhoneViewModel[]> {
        return this.httpUtility.get(`${environment.apiUrl}/users/${id}/user-phones`);
    }

    getToken(): Observable<string> {
        return this.httpUtility.get(`${environment.apiUrl}/phone-calls/token`);
    }

    connect(callModel: CallerModel): Observable<TwiMLResult> {
        return this.httpUtility.post(`${environment.apiUrl}/phone-calls/connect`, callModel);
    }
    validateCallerId(callerIdModel: CallerIdModel): Observable<StringResponse> {
        return this.httpUtility.post(`${environment.apiUrl}/phone-calls/validate-caller-id`, callerIdModel);
    }
    sendVoiceMail(voiceMailModel: VoiceMailModel): Observable<StringResponse> {
        return this.httpUtility.post(`${environment.apiUrl}/phone-calls/voice-mail`, voiceMailModel);
    }
}
