<p-table #dt [value]="soaList" styleClass="p-datatable-striped soa-list-table" [rowHover]="true" [rows]="10"
    [paginator]="true" [rowsPerPageOptions]="[10, 20, 30, 40, 50]" [loading]="loading" responsiveLayout="scroll">
    <ng-template pTemplate="header">
        <tr>
            <th width="15%">Agent</th>
            <th width="15%">Appointment Date</th>
            <th width="20%">Delivery Methods</th>
            <th width="10%">Sign Date</th>
            <th width="10%">Sign Name</th>
            <th width="10%">Relationship</th>
            <th width="10%" class="text-center">View Document</th>
        </tr>
    </ng-template>

    <ng-template pTemplate="body" let-i="rowIndex" let-data>
        <tr>
            <td>{{ data?.agent?.firstName }} {{ data?.agent?.lastName }}</td>
            <td>{{ data.appointmentDate | date: 'MM/dd/yyyy' }}</td>
            <td>
                <div class="my-2 d-flex flex-row align-items-center" *ngFor="let type of data.deliveryMethods">
                    <span *ngIf="type.type === 'EMAIL'" [ngStyle]="{
                            backgroundColor: 'var(--green-500)',
                            color: 'white',
                            borderRadius: '50px',
                            padding: '2px 6px',
                            fontSize: '13px',
                            fontWeight: '600',
                            width: '23px',
                            textAlign: 'center'
                        }" class="mr-2">E</span>
                    <span *ngIf="type.type === 'SMS'" [ngStyle]="{
                            backgroundColor: 'var(--blue-500)',
                            color: 'white',
                            borderRadius: '50px',
                            padding: '2px 6px',
                            fontSize: '13px',
                            fontWeight: '600',
                            width: '23px',
                            textAlign: 'center'
                        }" class="mr-2">S
                    </span>
                    {{ type.value }}
                </div>
            </td>
            <td>{{ data.signDate | utcToLocal: 'MM-DD-YYYY h:mm A'}}</td>
            <td>{{ data.signName }}</td>
            <td>{{ data.relationshipToBeneficiary }}</td>
            <td class="text-center">
                <a *ngIf="data.signedPdfPath !== null" pButton icon="pi pi-file-pdf"
                    class="p-button-success p-button-rounded" href="{{ data.signedPdfPath }}" target="_blank"></a>
            </td>
        </tr>
    </ng-template>
    <ng-template pTemplate="emptymessage">
        <tr>
            <td colspan="11" class="text-center">No data found.</td>
        </tr>
    </ng-template>
</p-table>
