import { CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './header/header.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { TabMenuModule } from 'primeng/tabmenu';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { AvatarModule } from 'primeng/avatar';
import { MenuService } from './services/menu-service';
import { BadgeModule } from 'primeng/badge';
import { MenuModule } from 'primeng/menu';
import { MessagesComponent } from './messages/messages.component';
import { ComingSoonComponent } from './coming-soon/coming-soon.component';
import { MegaMenuModule } from 'primeng/megamenu';
import { InlineLoaderComponent } from './inline-loader/inline-loader.component';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { NoNavHeaderComponent } from './no-nav-header/no-nav-header.component';
import { SpinnerComponent } from './spinner/spinner.component';
import { FooterComponent } from '@app/shared/footer/footer.component';
import { LeadAddressComponent } from './user/lead-address/lead-address.component';
import { LeadCallHistoryComponent } from './user/lead-call-history/lead-call-history.component';
import { LeadCallQueuesComponent } from './user/lead-call-queues/lead-call-queues.component';
import { LeadMedicationsComponent } from './user/lead-medications/lead-medications.component';
import { LeadPhonesComponent } from './user/lead-phones/lead-phones.component';
import { LeadTagsComponent } from './user/lead-tags/lead-tags.component';
import { ButtonModule } from 'primeng/button';
import { DropdownModule } from 'primeng/dropdown';
import { DialerBarComponent } from './dialer/dialer-bar/dialer-bar.component';
import { TableModule } from 'primeng/table';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DialogModule } from 'primeng/dialog';
import { LeadEditComponent } from './user/lead-edit/lead-edit.component';
import { CalendarModule } from 'primeng/calendar';
import { UserFileComponent } from './user/user-file/user-file.component';
import { LeadStatusHistoryComponent } from '@app/shared/user/lead-status-history/lead-status-history.component';
import { DateAgoPipe } from '@shared/date-ago.pipe';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ToastModule } from 'primeng/toast';
import { UserNoteComponent } from './user-note/user-note.component';
import { BulkAddNotesComponent } from './bulk-actions/bulk-add-notes/bulk-add-notes.component';
import { BulkAddRemoveTagComponent } from './bulk-actions/bulk-add-remove-tag/bulk-add-remove-tag.component';
import { MultiSelectModule } from 'primeng/multiselect';
import { BulkAddRemoveCallqueueComponent } from './bulk-actions/bulk-add-remove-callqueue/bulk-add-remove-callqueue.component';
import { BulkChangeAgentComponent } from './bulk-actions/bulk-change-agent/bulk-change-agent.component';
import { BulkChangeStatusComponent } from './bulk-actions/bulk-change-status/bulk-change-status.component';
import { GenerateSoaComponent } from './generate-soa/generate-soa/generate-soa.component';
import { MessagesModule } from 'primeng/messages';
import { RadioButtonModule } from 'primeng/radiobutton';
import { SearchComponent } from './search/search/search.component';
import { SelectButtonModule } from 'primeng/selectbutton';
import { UnauthorizedComponent } from './unauthorized/unauthorized.component';
import { SoaGeneratedListComponent } from './user/soa-generated-list/soa-generated-list.component';
import { MomentUtcToLocalPipe } from '@shared/utc-to-local.pipe';
import { UserMedicareComponent } from './user/user-medicare/user-medicare.component';
import { SidebarModule } from 'primeng/sidebar';
import { EditorModule } from 'primeng/editor';
import { PaginatorModule } from 'primeng/paginator';
import { CheckboxModule } from 'primeng/checkbox';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { CalendarEventsTabComponent } from './user/calendar-events-tab/calendar-events-tab.component';
import { AddCalendarEventComponent } from '@app/add-calendar-event/components/add-calendar-event/add-calendar-event.component';
import { ToolbarModule } from 'primeng/toolbar';
import { SplitButtonModule } from 'primeng/splitbutton';
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';

@NgModule({
    declarations: [
        HeaderComponent,
        FooterComponent,
        PageNotFoundComponent,
        MessagesComponent,
        ComingSoonComponent,
        InlineLoaderComponent,
        NoNavHeaderComponent,
        SpinnerComponent,
        LeadAddressComponent,
        LeadCallHistoryComponent,
        LeadCallQueuesComponent,
        LeadMedicationsComponent,
        LeadPhonesComponent,
        LeadTagsComponent,
        DialerBarComponent,
        LeadEditComponent,
        UserFileComponent,
        LeadStatusHistoryComponent,
        UserNoteComponent,
        DateAgoPipe,
        BulkAddNotesComponent,
        BulkAddRemoveTagComponent,
        BulkAddRemoveCallqueueComponent,
        BulkChangeAgentComponent,
        BulkChangeStatusComponent,
        GenerateSoaComponent,
        SearchComponent,
        UnauthorizedComponent,
        SoaGeneratedListComponent,
        MomentUtcToLocalPipe,
        UserMedicareComponent,
        CalendarEventsTabComponent,
        AddCalendarEventComponent,
    ],
    imports: [
        CommonModule,
        CalendarModule,
        FormsModule,
        ReactiveFormsModule,
        TabMenuModule,
        BreadcrumbModule,
        AvatarModule,
        BadgeModule,
        MenuModule,
        MegaMenuModule,
        ProgressSpinnerModule,
        TableModule,
        NgxMaskDirective,
        NgxMaskPipe,
        ButtonModule,
        DropdownModule,
        DialogModule,
        ConfirmDialogModule,
        ToastModule,
        MultiSelectModule,
        MessagesModule,
        RadioButtonModule,
        SidebarModule,
        SelectButtonModule,
        ToolbarModule,
        EditorModule,
        PaginatorModule,
        CheckboxModule,
        OverlayPanelModule,
        SplitButtonModule,
    ],
    exports: [
        HeaderComponent,
        FooterComponent,
        ComingSoonComponent,
        InlineLoaderComponent,
        NoNavHeaderComponent,
        SpinnerComponent,
        LeadAddressComponent,
        LeadCallHistoryComponent,
        LeadCallQueuesComponent,
        LeadMedicationsComponent,
        LeadPhonesComponent,
        LeadTagsComponent,
        DialerBarComponent,
        LeadEditComponent,
        UserFileComponent,
        LeadStatusHistoryComponent,
        UserNoteComponent,
        DateAgoPipe,
        BulkAddNotesComponent,
        BulkAddRemoveTagComponent,
        BulkAddRemoveCallqueueComponent,
        BulkChangeAgentComponent,
        BulkChangeStatusComponent,
        GenerateSoaComponent,
        UnauthorizedComponent,
        SoaGeneratedListComponent,
        MomentUtcToLocalPipe,
        UserMedicareComponent,
        CalendarEventsTabComponent,
        AddCalendarEventComponent,
    ],
    providers: [MenuService, provideNgxMask()],
    schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
})
export class SharedModule {}
