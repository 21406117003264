import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class DialerContentService {
    private subjectName = new Subject<{ text: string }>();

    sendUpdate(message: string) {
        this.subjectName.next({ text: message });
    }

    getUpdate(): Observable<{ text: string }> {
        return this.subjectName.asObservable(); // it returns as an observable to which the receiver funtion will subscribe
    }
}
