import { Injectable } from '@angular/core';
import { HttpUtility } from '@utility/httpUtility';
import { environment } from '@environments/environment';

@Injectable({ providedIn: 'root' })
export class SoaGenreatedListService {
    constructor(private httpUtility: HttpUtility) {}

    getAllList(payload) {
        return this.httpUtility.post<any>(`${environment.apiUrl}/soa-contract/filter`, payload);
    }
}
