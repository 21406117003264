import { Injectable } from '@angular/core';
import { HttpUtility } from '@utility/httpUtility';
import { Observable } from 'rxjs';
import { environment } from '@environments/environment';
import { UserPhoneModel, UserPhoneViewModel } from '../models/phone-model';
import { UserPhoneCreateModel } from '@app/user-settings/models/phone-model';

@Injectable({ providedIn: 'root' })
export class PhoneService {
    constructor(private httpUtility: HttpUtility) {}

    getPhonesById(userId: string): Observable<UserPhoneViewModel[]> {
        return this.httpUtility.get<UserPhoneViewModel[]>(`${environment.apiUrl}/users/${userId}/user-phones`);
    }

    editPhones(userPhoneModel: UserPhoneModel, userId: string) {
        return this.httpUtility.put(`${environment.apiUrl}/users/${userId}/user-phones`, userPhoneModel);
    }

    addPhones(userPhoneCreateModel: UserPhoneCreateModel, userId: string) {
        return this.httpUtility.post(`${environment.apiUrl}/users/${userId}/user-phones`, userPhoneCreateModel);
    }

    deletePhonesById(userId: string, id: string) {
        return this.httpUtility.delete(`${environment.apiUrl}/users/${userId}/user-phones/${id}`);
    }
}
