<form (ngSubmit)="(f.form.valid)" #f="ngForm" novalidate class="px-3 soa-form">
    <div class="row d-flex align-items-center" *ngIf="showProfileError">
        <h4 class="align-items-center" style="color: red; margin: auto">
            Agent profile is missing either Digital Signature or Sales Rep Id to generate SOA. <br />
            Please set up missing profile settings to be able to generate SOA.
        </h4>
    </div>
    <div class="row d-flex align-items-center">
        <div class="col-4">
            <label class="m-0">{{ titleType }} Name</label>
        </div>
        <div class="col-8">
            <span class="font-medium">{{ leadActiveStatus?.agent?.firstName }} {{ leadActiveStatus?.agent?.lastName }}</span>
        </div>
    </div>
    <div class="row d-flex align-items-center">
        <div class="col-4">
            <label class="m-0">Sales Rep Id</label>
        </div>
        <div class="col-8">
            <p-dropdown
                appendTo="body"
                name="salesRepIds"
                [options]="saleRepIds"
                placeholder="Choose Sales Rep Id"
                optionLabel="name"
                optionValue="name"
                styleClass="w-100 input-group input-group-lg input-group-solid"
                [filter]="true"
                filterBy="name"
                [(ngModel)]="createSoaContract.agentSalesId"
                #salesRepIds="ngModel"
                optionValue="code"
            ></p-dropdown>
        </div>
    </div>
    <div class="row d-flex align-items-center">
        <div class="col-4">
            <label class="m-0">Initial Method of contact</label>
        </div>
        <div class="col-8">
            <input
                type="text"
                name="methodOfContact"
                #methodOfContact="ngModel"
                class="form-control form-control-lg form-control-solid"
                placeholder=""
                [(ngModel)]="createSoaContract.methodOfContact"
                required
            />
        </div>
    </div>
    <div class="row d-flex align-items-center">
        <div class="col-4">
            <label class="m-0">Plan(s) the Licensed Sales Representative will Represent During the meeting</label>
        </div>
        <div class="col-8">
            <input
                type="text"
                name="medicarePlans"
                #medicarePlans="ngModel"
                class="form-control form-control-lg form-control-solid"
                placeholder=""
                [(ngModel)]="createSoaContract.medicarePlans"
                required
            />
        </div>
    </div>
    <div class="row d-flex align-items-center">
        <div class="col-4">
            <label class="m-0">Date Appointment will be completed</label>
        </div>
        <div class="col-8">
            <p-calendar
                [required]="true"
                appendTo="body"
                #appointment="ngModel"
                name="appointment"
                dateFormat="mm/dd/yy"
                inputId="icon"
                class="select-date-picker"
                [(ngModel)]="createSoaContract.appointmentDate"
            ></p-calendar>
        </div>
    </div>
    <div class="row d-flex align-items-center">
        <div class="col-4">
            <label class="m-0">Delivery methods</label>
        </div>
        <div class="col-8">
            <label class="d-flex flex-row align-items-center" *ngIf="contactInfo.email !== ''">
                <input type="checkbox" id="email" name="EMAIL" value="{{ contactInfo.email }}" [(ngModel)]="emailDelivery" (change)="emailDeliveryMethods($event)" />
                <span for="emal" class="px-2"> {{ contactInfo.email }} </span>
            </label>
            <label class="d-flex flex-row align-items-center" *ngIf="contactInfo?.cellPhone !== null || contactInfo?.workPhone !== null || contactInfo?.homePhone !== null">
                <input
                    type="checkbox"
                    id="mobile"
                    name="SMS"
                    value="{{ contactInfo?.cellPhone || contactInfo?.workPhone || contactInfo?.homePhone }}"
                    [(ngModel)]="mobileDelivery"
                    (change)="textDeliveryMethods($event)"
                />
                <span for="mobile" class="px-2">{{ contactInfo?.cellPhone || contactInfo?.workPhone || contactInfo?.homePhone | mask: '(000) 000-0000' }}</span>
            </label>
        </div>
    </div>
    <div class="row d-flex align-items-center pt-4">
        <div class="col-4">
            <label class="m-0">{{ titleType }} Information</label>
        </div>
        <div class="col-8">
            <span class="gray-text">Beneficiary Information</span>
        </div>
    </div>
    <div class="row d-flex align-items-center">
        <div class="col-4">
            <label class="m-0">{{ contactInfo.agent }}</label>
        </div>
        <div class="col-8">
            <span>{{ contactInfo.firstName }} {{ contactInfo.lastName }}</span>
        </div>
    </div>
    <div class="row d-flex align-items-center">
        <div class="col-4">
            <label class="m-0">{{ leadActiveStatus?.agent?.phone | mask: '(000) 000-0000' }}</label>
        </div>
        <div class="col-8">
            <span class="block">Phone: {{ contactInfo?.cellPhone || contactInfo?.workPhone || contactInfo?.homePhone | mask: '(000) 000-0000' }}</span>
            <span class="block">{{ contactInfo?.address }}, {{ contactInfo?.city }}, {{ contactInfo?.state }}, {{ contactInfo?.country }} {{ contactInfo?.zipCode }}</span>
        </div>
    </div>
    <div class="flex flex-row align-items-center justify-content-end border-top mt-4 pt-3 pb-0 px-5 mx-n8">
        <button pButton pRipple label="Cancel" icon="pi pi-times" class="p-button-text text-secondary" (click)="onCancel()"></button>
        <button [disabled]="!f.valid" pButton pRipple label="Submit" icon="pi pi-check" class="p-button-text" (click)="createContract()"></button>
    </div>
</form>
