<div>
    <div>
        <div class="text-right pb-3">
            <i class="pi pi-pencil blue-icon" (click)="showEditModal()" *ngIf="showEditBtn"></i>
        </div>
        <p-table [value]="leadStatusHistory" [rows]="10" [paginator]="true" [rowsPerPageOptions]="[10, 20, 30, 40, 50]">
            <ng-template pTemplate="header">
                <tr>
                    <th width="25%">Agent Name</th>
                    <th width="25%">Lead Status</th>
                    <th width="35%">Activity Date</th>
                    <th width="15%">Status</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-i="rowIndex" let-data>
                <tr>
                    <td>{{ data.agent?.firstName }} {{ data.agent?.lastName }}</td>
                    <td>{{ data?.leadStatus?.description }}</td>
                    <td>{{ data?.activityDate | utcToLocal: 'MM-DD-YYYY h:mm A' }}</td>
                    <td>{{ data?.status }}</td>
                </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage" *ngIf="!loading">
                <tr>
                    <td colspan="8">No history found.</td>
                </tr>
            </ng-template>
        </p-table>
    </div>
</div>

<div *ngIf="showEditLeadModal">
    <p-dialog
        header="Header"
        appendTo="body"
        (onHide)="cancelEventModal()"
        [(visible)]="display"
        [modal]="true"
        [style]="{ width: '30vw' }"
        [baseZIndex]="10000"
        [draggable]="false"
        [resizable]="false"
    >
        <ng-template pTemplate="header" class="py-3">
            <h3 class="m-0">EDIT LEAD STATUS</h3>
        </ng-template>
        <div class="row px-4">
            <form class="w-100" (ngSubmit)="(f.form.valid)" #f="ngForm">
                <div class="col-12">
                    <label>Agent <span class="text-danger">*</span></label>
                    <div class="">
                        <p-dropdown
                            appendTo="body"
                            [options]="agentsList"
                            [(ngModel)]="selectedAgent"
                            optionLabel="name"
                            [showClear]="false"
                            [filter]="true"
                            filterBy="name"
                            name="agentList"
                            dataKey="name"
                            styleClass="w-100 input-group input-group-lg input-group-solid"
                        >
                            <ng-template pTemplate="selectedItem">
                                <div class="country-item country-item-value">
                                    <div>{{ selectedAgent.name }}</div>
                                </div>
                            </ng-template>
                            <ng-template let-agentsList pTemplate="item">
                                <div class="country-item">
                                    <div>{{ agentsList.name }}</div>
                                </div>
                            </ng-template>
                        </p-dropdown>
                    </div>
                </div>
                <div class="col-12">
                    <label>Lead Status <span class="text-danger">*</span></label>

                    <div class="">
                        <p-dropdown
                            appendTo="body"
                            name="leadStatusList"
                            [options]="leadStatusList"
                            [(ngModel)]="selectedStatusLead"
                            optionLabel="label"
                            styleClass="w-100 input-group input-group-lg input-group-solid"
                            [group]="true"
                            [filter]="true"
                            filterBy="label"
                            dataKey="label"
                        >
                            <ng-template pTemplate="selectedItem">
                                <div class="country-item country-item-value">
                                    <div>{{ selectedStatusLead.label }}</div>
                                </div>
                            </ng-template>
                            <ng-template let-leadStatusList pTemplate="group">
                                <div class="p-d-flex p-ai-center">
                                    <h6>{{ leadStatusList.label }}</h6>
                                    <span>{{ leadStatusList.items.label }}</span>
                                </div>
                            </ng-template>
                        </p-dropdown>
                    </div>
                </div>
                <div class="pt-3 mt-3 flex flex-row align-items-center justify-content-end border-top w-100">
                    <button pButton pRipple label="Cancel" icon="pi pi-times" class="p-button-text text-secondary" (click)="cancelEventModal()"></button>
                    <button pButton pRipple label="Update" icon="pi pi-check" class="p-button-text" [disabled]="!f.valid" (click)="onSubmit()"></button>
                </div>
            </form>
        </div>
    </p-dialog>
</div>
