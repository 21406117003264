import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { UserAgentViewModel } from '@app/user-settings/models/user-agent.model';
import { BaseComponentDirective } from '@core/component/base.component';
import { LeadStatusEditModel } from '@app/lead/model/lead-status-edit-model';
import { LeadListService } from '@app/lead/services/leadlist-service';
import { sortBy } from 'lodash';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { DialerContentService } from '@app/shared/services/dialer-content-service';

@Component({
    selector: 'app-lead-status-history',
    templateUrl: './lead-status-history.component.html',
    styleUrls: ['./lead-status-history.component.scss'],
})
export class LeadStatusHistoryComponent extends BaseComponentDirective implements OnInit {
    @ViewChild('f', { static: true }) ngForm: NgForm;
    @Input() leadId: string;
    @Output() onDatePicked = new EventEmitter<any>();

    // @Input() leadStatus: any;

    leadStatusHistory = [];
    showEditLeadModal = false;
    display = false;
    showEditBtn: boolean;
    agentsList: { name: string; code: string }[] = [];
    leadStatusList: {
        label: string;
        value: string;
        items: { label: string; value: string }[];
    }[] = [];
    loading = false;

    selectedAgent: any;
    selectedStatusLead: { label: string; value: string };

    submitted = false;
    userAgents: UserAgentViewModel[];
    dialerSubscribe: Subscription;
    constructor(private leadListService: LeadListService, private router: Router, private dialerContentService: DialerContentService) {
        super();
        this.dialerSubscribe = this.dialerContentService.getUpdate().subscribe((message) => {
            this.leadId = message.text;
            this.ngOnInit();
            const currentUrl = this.router.url;
            void this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
                void this.router.navigate([currentUrl]);
            });
        });
    }
    ngOnInit(): void {
        this.getAllLeadStatus();
    }

    getAllLeadStatus() {
        this.leadListService.getLeadStatusHistory(this.leadId).subscribe((res) => {
            this.leadStatusHistory = res;
            this.userAgents = res;
            this.showEditBtn = true;
        });
    }

    showEditModal() {
        this.showEditLeadModal = true;
        this.display = true;
        if (this.userAgents && this.userAgents.length > 0) {
            this.selectedAgent = {
                name: `${this.userAgents[0]?.agent?.firstName} ${this.userAgents[0]?.agent?.lastName} (${this.userAgents[0]?.agent?.email})`,
                code: this.userAgents[0]?.agent?.id,
            };
            this.selectedStatusLead = { label: this.userAgents[0]?.leadStatus?.name, value: this.userAgents[0]?.leadStatus?.id };
        } else {
            this.selectedAgent = {
                name: 'Please select Agent',
                code: '',
            };
            this.selectedStatusLead = { label: 'Please select lead status', value: '' };
        }
        this.getAgentsList();
        this.getStatusLeadList();
    }

    cancelEventModal() {
        this.display = false;
        this.showEditLeadModal = false;
    }

    getAgentsList() {
        this.leadListService.getAgentList().subscribe((res) => {
            this.agentsList = [];
            const sortedArray = sortBy(res, (o) => {
                return o.value;
            });
            sortedArray.forEach((element) => {
                this.agentsList.push({
                    name: element.value,
                    code: element.id,
                });
            });
        });
    }
    getStatusLeadList() {
        this.leadListService.leadStatusGroup().subscribe((res) => {
            this.leadStatusList = [];

            res.forEach((element) => {
                const filteredLeadStatus = element.leadStatuses.map((children) => {
                    return {
                        label: children.name,
                        value: children.id,
                    };
                });

                this.leadStatusList.push({
                    label: element.name,
                    value: element.id,
                    items: filteredLeadStatus,
                });
            });
        });
    }

    onSubmit() {
        this.submitted = true;
        const leadStatusEditModel: LeadStatusEditModel = {
            userId: this.leadId,
            agentUserId: this.selectedAgent.code,
            leadStatusId: this.selectedStatusLead.value || this.selectedStatusLead.value,
        };
        this.leadListService.updateLeadStatusDetails(leadStatusEditModel).subscribe(() => {
            this.getAllLeadStatus();
            this.cancelEventModal();
        });
    }
}
