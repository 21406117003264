<ng-container>
    <div class="container-fluid">
        <div class="card-body row py-3 px-0 medications-hldr">
            <form [formGroup]="addMedicationForm" (ngSubmit)="onSubmit()" class="w-100">
                <div class="form-group mb-3">
                    <!-- <textarea
                        formControlName="addMedication"
                        [rows]="10"
                        class="form-control form-control-solid h-auto py-4 px-6 rounded-lg"
                        [ngClass]="{ 'is-invalid': submitted && f.addMedication.errors }"
                    ></textarea> -->
                    <p-editor formControlName="addMedication" [style]="{ height: '150px' }" [ngClass]="{ 'is-invalid': submitted && f.addMedication.errors }"></p-editor>
                    <div *ngIf="submitted && f.addMedication.errors" class="invalid-feedback">
                        <div *ngIf="f.addMedication.errors.required">Medication is required</div>
                    </div>
                </div>
                <div class="flex flex-column justify-content-end align-items-end">
                    <button pButton pRipple label="Submit" class="p-button-primary" [disabled]="loading"><span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span></button>
                </div>
            </form>
            <div class="w-100">
                <app-inline-loader *ngIf="loadingData"></app-inline-loader>
                <div *ngIf="!loadingData">
                    <div class="flex flex-column w-100 lead-medication" *ngIf="leadMedications && leadMedications.length">
                        <h4 class="mb-0">Medications History:</h4>
                        <ul class="px-0 mx-0">
                            <li *ngFor="let note of leadMedications" class="flex flex-row align-items-center justify-content-between py-3">
                                <div class="font-size-lg editor-tags-alignment" [innerHTML]="note.value"></div>
                                <div class="px-2">
                                    <i class="pi pi-pencil blue-icon mx-2" (click)="editUserMedication(note)"></i>
                                    <i class="pi pi-trash red-icon" (click)="deleteUserMedication(note.id)"></i>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-container>

<div *ngIf="showModal">
    <p-dialog header="Header" appendTo="body" (onHide)="cancel()" [(visible)]="display" [modal]="true" [style]="{ width: '50vw' }" [baseZIndex]="1000" [draggable]="false" [resizable]="false">
        <ng-template pTemplate="header" class="py-3">
            <h3 class="m-0">Update User Medication</h3>
        </ng-template>
        <form (ngSubmit)="(f.form.valid)" #f="ngForm" novalidate class="w-100 mx-auto relative">
            <div class="row mx-0">
                <div class="col-12">
                    <div class="form-group">
                        <label class="text-dark">Notes</label>
                        <p-editor [(ngModel)]="selectedRow.value" [style]="{ height: '150px' }"></p-editor>
                        <!-- <textarea type="text" name="value" [(ngModel)]="selectedRow.value" #name="ngModel" class="form-control form-control-lg form-control-solid" maxlength="200" required></textarea> -->
                    </div>
                </div>
            </div>

            <div class="mt-5 mb-2 d-flex flex-row justify-content-end">
                <button pButton pRipple label="Cancel" icon="pi pi-times" class="p-button-text text-secondary" (click)="cancel()"></button>
                <button pButton pRipple [disabled]="!f.valid" label="Update" icon="pi pi-check" class="p-button-text" (click)="updateUserMedication()"></button>
            </div>
        </form>
        <div *ngIf="showLoader" class="spinner-view">
            <app-inline-loader></app-inline-loader>
        </div>
    </p-dialog>
</div>

<p-confirmDialog appendTo="body" [style]="{ width: '50vw' }" key="leadMedicationDelete" [baseZIndex]="10000"></p-confirmDialog>
