import moment from 'moment';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'utcToLocal', pure: false })
export class MomentUtcToLocalPipe implements PipeTransform {
    transform(value: Date | moment.Moment, dateFormat: string): any {
        const Formatter = value !== null ? moment.utc(value).local().format(dateFormat) : '';
        return Formatter;
    }
}
