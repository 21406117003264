import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '@app/authentication/services/authentication.service';
import { LeadListService } from '@app/lead/services/leadlist-service';
import { BaseComponentDirective } from '@core/component/base.component';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import BulkActionsEnum from '../bulk-actions.enum';
import { BulkActionsService } from '../bulk-actions.service';

@Component({
    selector: 'app-bulk-change-status',
    templateUrl: './bulk-change-status.component.html',
})
export class BulkChangeStatusComponent extends BaseComponentDirective implements OnInit {
    selectedList: Array<string>;
    selectedStatus: string;
    leadStatusList: {
        label: string;
        value: string;
        items: { label: string; value: string }[];
    }[] = [];
    loading = false;
    getUserId = this.authService.getLoggedInId();

    constructor(
        public config: DynamicDialogConfig,
        private leadService: LeadListService,
        private authService: AuthenticationService,
        private bulkActionsService: BulkActionsService,
        public ref: DynamicDialogRef,
    ) {
        super();
    }

    ngOnInit() {
        super.ngOnInit();
        this.selectedList = this.config.data.selectedList as string[];
        this.getStatusLeadList();
    }

    getStatusLeadList() {
        this.leadService.leadStatusGroup().subscribe((leadStatusGroups) => {
            this.leadStatusList = [];

            leadStatusGroups.forEach((element) => {
                const filteredLeadStatus = element.leadStatuses.map((children) => {
                    return {
                        label: children.name,
                        value: children.id,
                    };
                });

                this.leadStatusList.push({
                    label: element.name,
                    value: element.id,
                    items: filteredLeadStatus,
                });
            });
        });
    }

    onSubmit() {
        if (!this.selectedStatus) {
            this.loading = false;
            return;
        }
        this.loading = true;
        this.bulkActionsService
            .bulkActions(this.getUserId, {
                action: BulkActionsEnum.ChangeStatus,
                userIds: this.selectedList,
                entityId: this.selectedStatus,
            })
            .subscribe({
                next: () => {
                    this.selectedStatus = '';
                    this.loading = false;
                    this.messageService.add({
                        key: 'tst',
                        severity: 'success',
                        summary: 'Success',
                        detail: 'Status changed successfully',
                    });
                    this.ref.close();
                },
                error: () => {
                    this.loading = false;
                },
            });
    }
}
