<ng-container>
    <div class="container-fluid my-5">
        <div class="row">
            <div class="col-xl-12">
                <div class="card">
                    <div>
                        <p-table
                            #dt
                            [value]="greetingsList"
                            styleClass="p-datatable-striped"
                            [rows]="10"
                            [loading]="loading"
                            [paginator]="true"
                            [globalFilterFields]="['name', 'displayOrder', 'description']"
                            [rowHover]="true"
                            dataKey="id"
                        >
                            <ng-template pTemplate="caption">
                                <div class="d-flex flex-row-reverse align-items-center">
                                    <i class="pi pi-plus-circle gray-icon mx-2 plus-medium" (click)="createGreetVoiceModal()"></i>
                                    <span class="p-input-icon-left">
                                        <i class="pi pi-search"></i>
                                        <input
                                            pInputText
                                            type="text"
                                            (input)="dt.filterGlobal($event.target.value, 'contains')"
                                            placeholder="Search..."
                                            class="form-control h-auto py-2 px-6 rounded-lg"
                                        />
                                    </span>
                                </div>
                            </ng-template>

                            <ng-template pTemplate="header">
                                <tr>
                                    <th width="15%" pSortableColumn="name">Name<p-sortIcon field="name"></p-sortIcon></th>
                                    <th width="15%" pSortableColumn="visibleTo">Users</th>
                                    <th width="15%" pSortableColumn="displayOrder">Sort Order<p-sortIcon field="displayOrder"></p-sortIcon></th>
                                    <th width="15%" pSortableColumn="description">Description<p-sortIcon field="description"></p-sortIcon></th>
                                    <th width="30%">Audio</th>
                                    <th width="20%">Action</th>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="body" let-greet>
                                <tr class="p-selectable-row">
                                    <td>
                                        <span class="p-column-title">Name</span>
                                        {{ greet.name }}
                                    </td>
                                    <td>
                                        <div *ngIf="greet.allowAllUsers === false">
                                            <ul *ngIf="greet?.users" class="list-unstyled">
                                                <li *ngFor="let user of greet.users">{{ user.value }}</li>
                                            </ul>
                                            <span *ngIf="greet?.users?.length === 0">-</span>
                                        </div>
                                        <span *ngIf="greet.allowAllUsers === true"> All Users </span>
                                    </td>
                                    <td>
                                        <span class="p-column-title">Display Order</span>
                                        {{ greet.displayOrder }}
                                    </td>

                                    <td>
                                        <span class="p-column-title">Description</span>
                                        {{ greet.description }}
                                    </td>
                                    <td>
                                        <div class="d-flex flex-row align-items-center">
                                            <audio controls>
                                                <source [src]="greet.filePath" type="audio/ogg" />
                                            </audio>
                                        </div>
                                    </td>
                                    <td>
                                        <i class="pi pi-pencil blue-icon mx-2" (click)="editVoiceGreeting(greet)"></i>
                                        <i class="pi pi-trash red-icon" (click)="confirmDelete(greet)"></i>
                                    </td>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="emptymessage">
                                <tr>
                                    <td colspan="8">No queues found.</td>
                                </tr>
                            </ng-template>
                        </p-table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-container>

<div *ngIf="showModal">
    <p-dialog header="Header" (onHide)="cancel()" [(visible)]="display" [modal]="true" [style]="{ width: '50vw' }" [baseZIndex]="10000" [draggable]="false" [resizable]="false">
        <ng-template pTemplate="header" class="py-3">
            <h3 class="m-0">{{ ModalTitle }} Voice Greeting</h3>
        </ng-template>
        <form (ngSubmit)="(f.form.valid)" #f="ngForm" novalidate class="w-100 mx-auto relative">
            <div class="row mx-0">
                <div class="col-6">
                    <div class="form-group">
                        <label for="password" class="text-dark">Name</label>
                        <input type="text" name="name" [(ngModel)]="selectedRow.name" #name="ngModel" class="form-control form-control-lg form-control-solid" maxlength="50" required />
                    </div>
                </div>
                <div class="col-6">
                    <div class="form-group">
                        <label for="password" class="text-dark">Description</label>
                        <input
                            type="text"
                            name="description"
                            [(ngModel)]="selectedRow.name"
                            #description="ngModel"
                            [ngClass]="{ 'is-invalid': f.submitted && description.invalid }"
                            class="form-control form-control-lg form-control-solid"
                            maxlength="50"
                            required
                        />
                    </div>
                </div>
            </div>
            <div class="row mx-0 d-flex flex-row align-items-center">
                <div class="col-6">
                    <div class="form-group">
                        <label for="password" class="text-dark">Display Order</label>
                        <input
                            type="number"
                            name="displayOrder"
                            [(ngModel)]="selectedRow.displayOrder"
                            #displayOrder="ngModel"
                            [ngClass]="{ 'is-invalid': f.submitted && displayOrder.invalid }"
                            class="form-control form-control-lg form-control-solid"
                            maxlength="50"
                            required
                        />
                    </div>
                </div>
                <div class="col-6" *ngIf="isAdmin">
                    <p-checkbox name="allowAllUsers" label="Allow all users" [(ngModel)]="selectedRow.allowAllUsers" [binary]="true" #allowAllUsers="ngModel"></p-checkbox>
                </div>
            </div>
            <div class="row mx-0" *ngIf="isAdmin">
                <div class="col-lg-12 col-xl-12" *ngIf="selectedRow.allowAllUsers !== true">
                    <label class="text-dark block">Pick Users</label>
                    <p-multiSelect
                        [options]="agents"
                        [(ngModel)]="selectedAgents"
                        optionValue="id"
                        styleClass="w-100 col-form-label"
                        name="users"
                        #users="ngModel"
                        optionLabel="value"
                        display="chip"
                        [virtualScroll]="true"
                        [itemSize]="34"
                        [selectedItemsLabel]="'{0} items selected'"
                        (onChange)="getSelectedAgents($event)"
                    >
                    </p-multiSelect>
                </div>
            </div>
            <div class="row mx-0 mt-4" *ngIf="showAudioUpload">
                <div class="col-lg-12 col-xl-12">
                    <label class="text-dark block">Voice Message</label>
                    <input type="file" value="" name="filePath" [(ngModel)]="selectedRow.filePath" #filePath="ngModel" (change)="fileUploadChange($event)" required />
                </div>
            </div>
            <div class="mt-5 mb-2 d-flex flex-row justify-content-end">
                <button pButton pRipple label="Cancel" icon="pi pi-times" class="p-button-text text-secondary" (click)="cancel()"></button>
                <button pButton pRipple [disabled]="!f.valid" label="{{ ButtonText }}" icon="pi pi-check" class="p-button-text" (click)="createAndUpdateGreetings()"></button>
            </div>
        </form>
        <div *ngIf="showLoader" class="spinner-view">
            <app-inline-loader></app-inline-loader>
        </div>
    </p-dialog>
</div>

<p-confirmDialog appendTo="body" [style]="{ width: '50vw' }" [baseZIndex]="10000"></p-confirmDialog>
