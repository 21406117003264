<div class="pt-0 pl-0 pb-2 pr-0">
    <div class="text-right">
        <i class="pi pi-plus-circle gray-icon plus-medium" (click)="openNew()"></i>
    </div>
    <div *ngIf="!loading">
        <ng-container *ngFor="let userPhones of userPhonesViewModelDetails" class="mb-3">
            <div class="border-bottom-secondary border-bottom-1 py-2 align-items-center">
                <label class="font-weight-bold"> {{ userPhones?.phoneType?.description }} </label>
                <div class="w-100 flex flex-row align-items-center justify-content-between">
                    <div class="fw-bolder fs-6 text-dark flex flex-column">
                        <a [routerLink]="[]" (click)="enableDialer($event)" style="cursor: pointer" id="{{ userPhones?.phoneType?.description }}">{{ userPhones?.number | mask: '(000) 000-0000' }}</a>
                        <span *ngIf="userPhones?.extension"> Ext :{{ userPhones?.extension }}</span>
                    </div>
                    <div class="text-right">
                        <i class="pi pi-pencil blue-icon mr-2" (click)="openEdit(userPhones.id)"></i>
                        <i class="pi pi-trash red-icon" (click)="confirmDelete(userPhones.id)"></i>
                    </div>
                </div>
            </div>
        </ng-container>
    </div>
    <app-inline-loader *ngIf="loading"></app-inline-loader>
    <ng-container *ngIf="userPhonesViewModelDetails?.length === 0">
        <p class="py-4">No Phone numbers found.</p>
    </ng-container>
</div>

<p-confirmDialog appendTo="body" [style]="{ width: '50vw' }" key="leadDelete" [baseZIndex]="10000"></p-confirmDialog>
<p-dialog appendTo="body" [(visible)]="showDialog" appendTo="body" [style]="{ width: '650px' }" [modal]="true" styleClass="p-fluid">
    <ng-template pTemplate="header"> Phone Details </ng-template>

    <ng-template pTemplate="content">
        <div [formGroup]="formGroup">
            <div class="card-body">
                <div class="form-group row">
                    <label class="col-xl-3 col-lg-3 col-form-label">Phone Type</label>
                    <div class="col-lg-9 col-xl-6">
                        <div class="input-group input-group-lg input-group-solid">
                            <select class="form-control form-control-lg form-control-solid" formControlName="phoneType" [(ngModel)]="selectedPhoneType">
                                <option value="">Select Phone Type</option>
                                <option *ngFor="let phoneType of phoneTypes" value="{{ phoneType.id }}">{{ phoneType.description }}</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-xl-3 col-lg-3 col-form-label">Phone number</label>
                    <div class="col-lg-6 col-xl-6">
                        <div class="input-group input-group-lg input-group-solid">
                            <input
                                type="text"
                                class="form-control form-control-lg form-control-solid"
                                placeholder="Phone"
                                formControlName="phone"
                                [class.is-invalid]="isControlInvalid('phone')"
                                [class.is-valid]="isControlValid('phone')"
                                ngDefaultControl
                                mask="(000) 000 0000"
                            />
                        </div>
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-xl-3 col-lg-3 col-form-label">Extension</label>
                    <div class="col-lg-6 col-xl-6">
                        <div class="input-group input-group-lg input-group-solid">
                            <input type="text" class="form-control form-control-lg form-control-solid" placeholder="Extension" formControlName="extension" maxLength="5" ngDefaultControl />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </ng-template>

    <ng-template pTemplate="footer">
        <button pButton pRipple label="Cancel" icon="pi pi-times" class="p-button-text text-secondary" (click)="hideDialog()"></button>
        <button pButton pRipple label="Save" icon="pi pi-check" class="p-button-text" [disabled]="!formGroup.valid" *ngIf="dialogType === 'create'" (click)="savePhones()"></button>
        <button pButton pRipple label="Save" icon="pi pi-check" class="p-button-text" [disabled]="!formGroup.valid" *ngIf="dialogType === 'update'" (click)="saveEditedPhones()"></button>
    </ng-template>
    <div *ngIf="modalLoader" class="spinner-view">
        <app-inline-loader></app-inline-loader>
    </div>
</p-dialog>
