import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { BaseComponentDirective } from '@core/component/base.component';
import { AuthenticationService } from '@app/authentication/services/authentication.service';
import { SoaListSerive } from '../../../user-settings/services/soalist-service';
import { SoaAddModel, SoaListModel } from '../../../user-settings/models/soa-list-model';
import { sortBy } from 'lodash';
import { LeadListService } from '@app/lead/services/leadlist-service';
import { SoaContractModel } from '@app/shared/models/soa-contract-model';
import moment from 'moment';

@Component({
    selector: 'app-generate-soa',
    templateUrl: './generate-soa.component.html',
    styleUrls: ['./generate-soa.component.scss'],
})
export class GenerateSoaComponent extends BaseComponentDirective implements OnInit {
    @ViewChild('f', { static: true })
    ngForm: NgForm;
    @Input() leadId: string;
    @Input() contactInfo: any;
    @Input() titleType: string;
    @Output() closeModal = new EventEmitter();
    loggedInUserId: string;
    requestResponse: SoaListModel[] = [];
    request: SoaAddModel[] = [];
    saleRepIds = [];
    leadActiveStatus: any;
    createSoaContract: SoaContractModel;
    emailDelivery = '';
    mobileDelivery = '';
    deliveryMethod = [];
    showProfileError = false;
    constructor(
        private soaListSvc: SoaListSerive,
        private leadListService: LeadListService,
        private authenticationService: AuthenticationService,
    ) {
        super();
    }

    ngOnInit(): void {
        this.createSoaContract = new SoaContractModel();
        this.loggedInUserId = this.authenticationService.getLoggedInId();
        this.getSoaList();
        this.getAllLeadStatus();
    }

    getAllLeadStatus() {
        this.leadListService.getLeadStatusHistory(this.leadId).subscribe((res) => {
            this.leadActiveStatus = res[0];
        });
    }

    getSoaList() {
        this.soaListSvc.getSoaList(this.contactInfo.agentId).subscribe((res) => {
            this.showProfileError = false;
            const parsedData = JSON.parse(res && res['value']);
            this.request = parsedData?.AgentSalesIds;
            if ((this.request && this.request.length === 0) || this.request === undefined) {
                this.showProfileError = true;
            } else if ((parsedData && parsedData.ESignature === '') || this.request === undefined) {
                this.showProfileError = true;
            }

            this.saleRepIds = [];
            const sortedArray = sortBy(this.request, (o) => {
                return o.name;
            });
            sortedArray.forEach((element) => {
                this.saleRepIds.push({
                    name: element.name,
                    code: element.value,
                });
            });
        });
    }

    onCancel() {
        this.closeModal.emit('close');
    }

    emailDeliveryMethods(e) {
        if (e.target.checked) {
            this.emailDelivery = e.target.name;
            const emailObj = { type: e.target.name, value: e.target.value };
            this.deliveryMethod.push(emailObj);
        }
    }

    textDeliveryMethods(e) {
        if (e.target.checked) {
            this.mobileDelivery = e.target.name;
            const textObj = { type: e.target.name, value: e.target.value };
            this.deliveryMethod.push(textObj);
        }
    }

    createContract() {
        const createSoaContract: SoaContractModel = {
            userId: this.leadId,
            agentUserId: this.leadActiveStatus.agent.id,
            appointmentDate: this.createSoaContract.appointmentDate ? moment(this.createSoaContract.appointmentDate).format('YYYY-MM-DDTHH:mm:ss') : '',
            agentSalesId: this.createSoaContract.agentSalesId,
            methodOfContact: this.createSoaContract.methodOfContact,
            medicarePlans: this.createSoaContract.medicarePlans,
            deliveryMethods: this.deliveryMethod,
        };
        this.soaListSvc.createContract(createSoaContract).subscribe({
            next: () => {
                this.onCancel();
                this.createSoaContract = new SoaContractModel();
                this.messageService.add({
                    key: 'tst',
                    severity: 'success',
                    summary: 'Success',
                    detail: 'Contract created successfully',
                });
            },
            error: () => {
                this.messageService.add({
                    key: 'tst',
                    severity: 'error',
                    summary: 'Error',
                    detail: 'Error in Creating contract',
                });
            },
        });
    }
}
