import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { DialerContentService } from '@app/shared/services/dialer-content-service';
import { UserFile } from '@app/shared/user/model/user-file.model';
import { FileUploadModel } from '@app/user-settings/components/voice-greetings/voice-greetings.component';
import { BaseComponentDirective } from '@core/component/base.component';
import { ConfirmationService } from 'primeng/api';
import { Subscription } from 'rxjs';
import { UserService } from '../user.service';

@Component({
    selector: 'app-user-file',
    templateUrl: './user-file.component.html',
    styleUrls: ['./user-file.component.scss'],
})
export class UserFileComponent extends BaseComponentDirective implements OnInit {
    @Input() userId: string;
    @ViewChild('f', { static: true }) ngForm: NgForm;
    loading = false;
    userFiles: UserFile[] = [];
    showModal = false;
    selectedRow: FileUploadModel;
    fileName = '';
    selectedFile: File;
    allowValue = true;
    showLoader = false;
    dialerSubscribe: Subscription;
    constructor(private confirmationService: ConfirmationService, private router: Router, private userService: UserService, private dialerContentService: DialerContentService) {
        super();
        this.dialerSubscribe = this.dialerContentService.getUpdate().subscribe((message) => {
            this.userId = message.text;
            this.ngOnInit();
            const currentUrl = this.router.url;
            void this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
                void this.router.navigate([currentUrl]);
            });
        });
    }

    ngOnInit(): void {
        super.ngOnInit();
        this.selectedRow = new FileUploadModel();
        this.getAllUserFiles();
    }

    getAllUserFiles() {
        this.loading = true;
        this.userService.getUserFiles(this.userId).subscribe({
            next: (res) => {
                this.loading = false;
                this.userFiles = res;
            },
            error: () => {
                this.messageService.add({
                    key: 'tst',
                    severity: 'error',
                    summary: 'Error',
                    detail: 'something went wrong, please try again',
                });
            },
        });
    }

    cancel() {
        this.showModal = false;
    }

    fileUploadChange(event: Event) {
        const files = (event.target as HTMLInputElement).files;
        if (files.length) {
            this.selectedFile = files[0];
            this.fileName = files[0].name;
        }
    }

    createAndUpdateFile() {
        const form = new FormData();
        form.append('UploadFile', this.selectedFile);
        form.append('name', this.selectedRow?.name);
        form.append('userId', this.userId);
        form.append('statusId', '1');
        this.showLoader = true;
        this.userService.addUserFile(this.userId, form).subscribe({
            next: () => {
                this.showLoader = false;
                this.cancel();
                this.selectedRow = new FileUploadModel();
                this.getAllUserFiles();
                this.messageService.add({
                    key: 'tst',
                    severity: 'success',
                    summary: 'Success',
                    detail: `User File added Successfully`,
                });
            },
            error: () => {
                this.showLoader = false;
                this.messageService.add({
                    key: 'tst',
                    severity: 'error',
                    summary: 'Error',
                    detail: 'something went wrong, please try again',
                });
            },
        });
    }

    deleteVoiceGreeting(userFile: UserFile) {
        this.userService.deleteUserFile(this.userId, userFile.id).subscribe({
            next: () => {
                this.getAllUserFiles();
                this.selectedRow = new FileUploadModel();
                this.messageService.add({
                    key: 'tst',
                    severity: 'success',
                    summary: 'Success',
                    detail: `${userFile.name} deleted Successfully`,
                });
            },
            error: () => {
                this.messageService.add({
                    key: 'tst',
                    severity: 'error',
                    summary: 'Error',
                    detail: 'something went wrong, please try again',
                });
            },
        });
    }

    confirmDelete(userFile: UserFile) {
        this.confirmationService.confirm({
            key: 'userFileDelele',
            message: 'Are you sure that you want to proceed?',
            header: 'Confirmation',
            icon: 'pi pi-exclamation-triangle',
            accept: () => {
                this.deleteVoiceGreeting(userFile);
            },
            reject: () => {
                this.confirmationService.close();
            },
        });
    }
}
