import { Injectable } from '@angular/core';
import { HttpUtility } from '@utility/httpUtility';
import { environment } from '@environments/environment';
import { UserNoteModel } from '../../lead/model/user-note.model';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class UserNoteService {
    constructor(private httpUtility: HttpUtility) {}

    getAllUserNotes(userId: string): Observable<UserNoteModel[]> {
        return this.httpUtility.get(`${environment.apiUrl}/user/${userId}/user-notes`);
    }

    addUserNote(Id: string, userNoteModel: UserNoteModel) {
        return this.httpUtility.post(`${environment.apiUrl}/user/${Id}/user-notes`, userNoteModel);
    }

    getUserNotesById(userId: string, Id: string): Observable<UserNoteModel> {
        return this.httpUtility.get<UserNoteModel>(`${environment.apiUrl}/user/${userId}/user-notes/${Id}`);
    }

    editUserNotes(userId: string, userNotes: UserNoteModel) {
        return this.httpUtility.put(`${environment.apiUrl}/user/${userId}/user-notes`, userNotes);
    }

    deleteeditUserNotes(userId: string, Id: string) {
        return this.httpUtility.delete(`${environment.apiUrl}/user/${userId}/user-notes/${Id}`);
    }
}
