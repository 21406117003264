import { Injectable } from '@angular/core';
import { HttpUtility } from '@utility/httpUtility';
import { environment } from '@environments/environment';

@Injectable({ providedIn: 'root' })
export class UserMedicareListService {
    constructor(private httpUtility: HttpUtility) {}

    getAllMedicareList(id: string) {
        return this.httpUtility.get<any>(`${environment.apiUrl}/user/${id}/user-medicare`);
    }

    createMedicare(id: string, payload) {
        return this.httpUtility.post<any>(`${environment.apiUrl}/user/${id}/user-medicare`, payload);
    }

    updateMedicare(id: string, payload) {
        return this.httpUtility.put<any>(`${environment.apiUrl}/user/${id}/user-medicare`, payload);
    }

    deleteMedicareById(id: string, rowId: string) {
        return this.httpUtility.delete(`${environment.apiUrl}/user/${id}/user-medicare/${rowId}`);
    }
}
