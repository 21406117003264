import { Injectable } from '@angular/core';
import { HttpUtility } from '@utility/httpUtility';
import { Observable } from 'rxjs';
import { environment } from '@environments/environment';
import { Cacheable } from 'ts-cacheable';
import { LuAddressTypeModel } from '@app/user-settings/models/address-type-model';

@Injectable({ providedIn: 'root' })
export class AddressTypesService {
    constructor(private httpUtility: HttpUtility) {}
    @Cacheable()
    getAddressesTypes(): Observable<LuAddressTypeModel[]> {
        return this.httpUtility.get<LuAddressTypeModel[]>(`${environment.apiUrl}/lu-address-types`);
    }
    @Cacheable()
    getAddressesTypesById(id: string): Observable<LuAddressTypeModel> {
        return this.httpUtility.get<LuAddressTypeModel>(`${environment.apiUrl}/lu-address-types/${id}`);
    }
}
