import { Injectable } from '@angular/core';
import { HttpUtility } from '@utility/httpUtility';
import { Observable } from 'rxjs';
import { environment } from '@environments/environment';
import { BaseLookupModel } from '../models/agent-lookup-model';

@Injectable({ providedIn: 'root' })
export class AgentLookupService {
    constructor(private httpUtility: HttpUtility) {}

    getAgentLookupDetails(): Observable<BaseLookupModel[]> {
        return this.httpUtility.get<BaseLookupModel[]>(`${environment.apiUrl}/users/agent-lookup`);
    }
}
