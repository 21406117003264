import { Injectable } from '@angular/core';
import { HttpUtility } from '@utility/httpUtility';
import { environment } from '@environments/environment';
import { Observable } from 'rxjs';
import { CallLogCreateModel, CallLogUpdateModel, CallLogViewModel, CallLogRecordingPathUpdateModel } from './../models/call-log-model';

@Injectable({ providedIn: 'root' })
export class CalllogService {
    constructor(private httpUtility: HttpUtility) {}

    getCallLog(id: string): Observable<CallLogViewModel[]> {
        return this.httpUtility.get<CallLogViewModel[]>(`${environment.apiUrl}/calllog?LeadUserId=${id}`);
    }

    getLeadCallLog(id: string): Observable<CallLogViewModel[]> {
        return this.httpUtility.get<CallLogViewModel[]>(`${environment.apiUrl}/calllog?AgentUserId=${id}`);
    }
    addCalllog(callLogCreateModel: CallLogCreateModel) {
        return this.httpUtility.post(`${environment.apiUrl}/calllog`, callLogCreateModel);
    }

    editCalllog(callLogUpdateModel: CallLogUpdateModel) {
        return this.httpUtility.put(`${environment.apiUrl}/calllog`, callLogUpdateModel);
    }

    getCalllogById(id: string): Observable<CallLogViewModel> {
        return this.httpUtility.get<CallLogViewModel>(`${environment.apiUrl}/calllog/${id}`);
    }

    deleteCalllogById(id: number) {
        return this.httpUtility.delete(`${environment.apiUrl}/calllog/${id}`);
    }

    editCallRecordingPath(callLogRecordingPathUpdateModel: CallLogRecordingPathUpdateModel) {
        return this.httpUtility.put(`${environment.apiUrl}/call-recording-path`, callLogRecordingPathUpdateModel);
    }
}
