<div class="pt-0 pl-0 pb-3 pr-0 relative">
    <div *ngIf="!loading">
        <div *ngIf="userAddressViewModelDetails?.length === 0" class="text-right button-align">
            <i class="pi pi-plus-circle gray-icon plus-medium" (click)="editAddressesDialog = true"></i>
        </div>
        <ng-container *ngFor="let userAddress of userAddressViewModelDetails" class="mb-3">
            <div class="mb-0">
                <div class="text-right button-align">
                    <i class="pi pi-pencil blue-icon" (click)="openEdit(userAddress.id)"></i>
                </div>
                <div class="py-2">
                    <div class="">
                        <div *ngIf="userAddress?.address?.address1">{{ userAddress?.address?.address1 }},</div>
                        <div *ngIf="userAddress?.address?.address2">{{ userAddress?.address?.address2 }},</div>
                        <div>
                            <ng-container *ngIf="userAddress?.address?.city">{{ userAddress?.address?.city }}, </ng-container>
                            {{ userAddress?.address?.state?.code }}
                            <ng-container *ngIf="userAddress?.address?.postalCode">, </ng-container>
                            {{ userAddress?.address?.postalCode }}
                        </div>
                        <div>
                            <ng-container *ngIf="userAddress?.address?.county?.name"> {{ userAddress?.address?.county?.name }}</ng-container>
                        </div>
                    </div>
                </div>
            </div>
        </ng-container>
    </div>

    <app-inline-loader *ngIf="loading"></app-inline-loader>
    <ng-container *ngIf="userAddressViewModelDetails?.length === 0">
        <p class="py-4">No adresses found.</p>
    </ng-container>
</div>

<p-dialog [(visible)]="editAddressesDialog" appendTo="body" [style]="{ width: '650px' }" [modal]="true" styleClass="p-fluid">
    <ng-template pTemplate="header"> Address Details </ng-template>

    <ng-template pTemplate="content">
        <div [formGroup]="formGroup">
            <div class="card-body">
                <div class="form-group row">
                    <label class="col-xl-3 col-lg-3 col-form-label">Address Type</label>
                    <div class="col-lg-9 col-xl-6">
                        <div class="input-group input-group-lg input-group-solid">
                            <select class="form-control form-control-lg form-control-solid" formControlName="addressTypeId" [(ngModel)]="selectedAddressType">
                                <option value="">Select</option>
                                <option *ngFor="let addressType of addressTypes" value="{{ addressType.id }}">{{ addressType.description }}</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-xl-3 col-lg-3 col-form-label">Address Line 1</label>
                    <div class="col-lg-9 col-xl-6">
                        <div class="input-group input-group-lg input-group-solid">
                            <input type="text" class="form-control form-control-lg form-control-solid" placeholder="Address Line 1" formControlName="address1" ngDefaultControl />
                        </div>
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-xl-3 col-lg-3 col-form-label">Address Line 2</label>
                    <div class="col-lg-9 col-xl-6">
                        <div class="input-group input-group-lg input-group-solid">
                            <input type="text" class="form-control form-control-lg form-control-solid" placeholder="Address Line 2" formControlName="address2" />
                        </div>
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-xl-3 col-lg-3 col-form-label">City</label>
                    <div class="col-lg-9 col-xl-6">
                        <div class="input-group input-group-lg input-group-solid">
                            <input type="text" class="form-control form-control-lg form-control-solid" placeholder="City" formControlName="city" />
                        </div>
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-xl-3 col-lg-3 col-form-label">State</label>
                    <div class="col-lg-9 col-xl-6">
                        <p-dropdown
                            appendTo="body"
                            [options]="states"
                            [(ngModel)]="selectedState"
                            optionLabel="name"
                            [filter]="true"
                            filterBy="name"
                            [showClear]="true"
                            placeholder="Select state"
                            (onChange)="getCountiessService()"
                            formControlName="stateId"
                        >
                            <ng-template pTemplate="selectedItem">
                                <div class="country-item country-item-value">
                                    <div>{{ selectedState.name }}</div>
                                </div>
                            </ng-template>
                            <ng-template let-states pTemplate="item">
                                <div class="country-item">
                                    <div>{{ states.name }}</div>
                                </div>
                            </ng-template>
                        </p-dropdown>
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-xl-3 col-lg-3 col-form-label">Postal Code</label>
                    <div class="col-lg-9 col-xl-6">
                        <div class="input-group input-group-lg input-group-solid">
                            <div class="input-group-prepend">
                                <span class="input-group-text">
                                    <i class="pi pi-phone"></i>
                                </span>
                            </div>
                            <input type="text" maxlength="7" class="form-control form-control-lg form-control-solid" placeholder="Postal Code" formControlName="postalCode" />
                        </div>
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-xl-3 col-lg-3 col-form-label">County</label>
                    <div class="col-lg-9 col-xl-6">
                        <p-dropdown
                            appendTo="body"
                            [options]="counties"
                            [(ngModel)]="selectedCounty"
                            optionLabel="name"
                            [filter]="true"
                            filterBy="name"
                            [showClear]="true"
                            placeholder="Select County"
                            name="CountyId"
                            formControlName="countyId"
                        >
                            <ng-template pTemplate="selectedItem">
                                <div class="country-item country-item-value">
                                    <div>{{ selectedCounty.name }}</div>
                                </div>
                            </ng-template>
                            <ng-template let-counties pTemplate="item">
                                <div class="country-item">
                                    <div>{{ counties.name }}</div>
                                </div>
                            </ng-template>
                        </p-dropdown>
                    </div>
                </div>
            </div>
        </div>
    </ng-template>

    <ng-template pTemplate="footer">
        <button pButton pRipple label="Cancel" icon="pi pi-times" class="p-button-text text-secondary" (click)="hideDialog()"></button>
        <button pButton pRipple label="Save" icon="pi pi-check" class="p-button-text" [disabled]="!formGroup.valid" (click)="saveEditedAddress()"></button>
    </ng-template>
</p-dialog>
