<div class="px-3" *ngIf="modalAction === 'Preview'">
    <div class="row">
        <div [ngClass]="selectedSchedule.schedule.raw?.leadUser === null && selectedSchedule.schedule.raw?.clientUser === null ? 'col-md-12 col-lg-12' : 'col-md-6 col-lg-6'">
            <div class="row py-1 mx-0">
                <div class="col-6 col-md-6 font-weight-bold">Date:</div>
                <div class="col-6 col-md-6">
                    {{ selectedDate }} <span *ngIf="selectedSchedule.schedule.isAllDay">- {{ selectedEndDate }}</span>
                </div>
            </div>
            <div class="row py-1 border-top mx-0">
                <div class="col-6 col-md-6 font-weight-bold">Time:</div>
                <div class="col-6 col-md-6">{{ selectedStartTime }} - {{ selectedEndTime }}</div>
            </div>
            <div class="row py-1 border-top mx-0">
                <div class="col-6 col-md-6 font-weight-bold">Category:</div>
                <div class="col-6 col-md-6">
                    <div class="flex flex-row align-items-center">
                        <span class="rounded-xl mr-2" style="width: 14px; height: 14px; display: inline-block" [ngStyle]="{ background: selectedSchedule.schedule.bgColor }"></span
                        >{{ selectedSchedule.schedule.raw.eventCategory.name }}
                    </div>
                </div>
            </div>
            <div class="row py-1 border-top mx-0">
                <div class="col-6 col-md-6 font-weight-bold">For:</div>
                <div class="col-6 col-md-6">{{ selectedSchedule.schedule.raw.agentUser.firstName }} {{ selectedSchedule.schedule.raw.agentUser.lastName }}</div>
            </div>
        </div>
        <div class="col-md-6 col-lg-6" *ngIf="selectedSchedule.schedule.raw?.leadUser !== null">
            <div class="row py-1 mx-0">
                <div class="col-6 col-md-6 font-weight-bold">Lead Name:</div>
                <div class="col-6 col-md-6 font-weight-bolder" [ngStyle]="{ color: 'var(--blue-500)' }">
                    <a [routerLink]="['/leads/lead-details/', selectedSchedule.schedule.raw?.leadUser.id]">
                        {{ selectedSchedule.schedule.raw?.leadUser?.firstName }}
                        {{ selectedSchedule.schedule.raw?.leadUser?.lastName }}</a
                    >
                </div>
            </div>
            <div class="row py-1 border-top mx-0">
                <div class="col-6 col-md-6 font-weight-bold">Phone:</div>
                <div class="col-6 col-md-6">{{ selectedSchedule.schedule.raw?.leadUser?.cellPhone }}</div>
            </div>
            <div class="row py-1 border-top mx-0">
                <div class="col-6 col-md-6 font-weight-bold">Cell Phone:</div>
                <div class="col-6 col-md-6">{{ selectedSchedule.schedule.raw?.leadUser?.homePhone }}</div>
            </div>
            <div class="row py-1 border-top mx-0">
                <div class="col-6 col-md-6 font-weight-bold">Address:</div>
                <div class="col-6 col-md-6">
                    {{ selectedSchedule.schedule.raw?.leadUser?.address }}
                </div>
            </div>
        </div>
        <div class="col-md-6 col-lg-6" *ngIf="selectedSchedule.schedule.raw?.clientUser !== null">
            <div class="row py-1 mx-0">
                <div class="col-6 col-md-6 font-weight-bold">Client Name:</div>
                <div class="col-6 col-md-6 font-weight-bolder" [ngStyle]="{ color: 'var(--blue-500)' }">
                    <a [routerLink]="['/clients/client-details/', selectedSchedule.schedule.raw?.clientUser.id]">
                        {{ selectedSchedule.schedule.raw?.clientUser?.firstName }}
                        {{ selectedSchedule.schedule.raw?.clientUser?.lastName }}</a
                    >
                </div>
            </div>
            <div class="row py-1 border-top mx-0">
                <div class="col-6 col-md-6 font-weight-bold">Phone:</div>
                <div class="col-6 col-md-6">{{ selectedSchedule.schedule.raw?.clientUser?.cellPhone }}</div>
            </div>
            <div class="row py-1 border-top mx-0">
                <div class="col-6 col-md-6 font-weight-bold">Cell Phone:</div>
                <div class="col-6 col-md-6">{{ selectedSchedule.schedule.raw?.clientUser?.homePhone }}</div>
            </div>
            <div class="row py-1 border-top mx-0">
                <div class="col-6 col-md-6 font-weight-bold">Address:</div>
                <div class="col-6 col-md-6">
                    {{ selectedSchedule.schedule.raw?.clientUser?.address }}
                </div>
            </div>
        </div>
    </div>
    <div class="row py-1 border-top my-2 py-3">
        <div class="d-flex flex-row">
            <div class="font-weight-bold px-3">Notes:</div>
            <div class="">{{ selectedSchedule.schedule.body }}</div>
        </div>
    </div>
    <div class="border-top mt-5 pt-3 flex flex-row justify-content-end">
        <!-- <button pButton pRipple type="button" label="EXPORT ICS" class="p-button" (click)="handleDownloadIcs()"></button> -->
        <button pButton pRipple type="button" label="UPDATE" class="p-button mx-3" (click)="updateSchedule()"></button>
        <button pButton pRipple type="button" label="COMPLETE" class="p-button mx-3" (click)="completeSelectedEvent()"></button>
        <button pButton pRipple type="button" label="DELETE" class="p-button-outlined p-button-danger" (click)="confirmDelete()"></button>
    </div>
</div>

<form (ngSubmit)="(f.form.valid)" #f="ngForm" class="px-3 relative" *ngIf="modalAction === 'Add'">
    <div class="form-group">
        <label>Description <span class="text-danger">*</span></label>
        <input type="text" name="description" [(ngModel)]="eventDescription" class="form-control form-control-solid h-auto py-3 px-4 rounded-lg" maxlength="100" placeholder="Description" required />
    </div>
    <div class="form-group">
        <label class="block">When <span class="text-danger">*</span></label>
        <div class="flex flex-row align-items-center row">
            <div class="col-lg-12">
                <p-calendar
                    [(ngModel)]="selecteDateFormat"
                    name="eventDate"
                    [readonlyInput]="true"
                    dateFormat="mm/dd/yy"
                    [showIcon]="true"
                    inputId="icon"
                    placeholder="when"
                    [required]="true"
                    (onSelect)="onSelectUpdateDate($event)"
                    class="select-date-picker"
                ></p-calendar>
            </div>
            <div *ngIf="!allDayOption" class="col-lg-12 mt-3">
                <div class="row">
                    <div class="col-lg-4">
                        <p-dropdown
                            name="start"
                            [options]="getStartHoursList"
                            [(ngModel)]="startTime"
                            optionLabel="name"
                            [showClear]="false"
                            placeholder="Select start Hour"
                            (onChange)="getSelectedStartHours($event)"
                            dataKey="code"
                            [required]="true"
                        >
                            <ng-template pTemplate="selectedItem">
                                <div class="country-item country-item-value">
                                    <div>{{ startTime?.name }}</div>
                                </div>
                            </ng-template>
                            <ng-template let-getStartHoursList pTemplate="item">
                                <div class="country-item">
                                    <div>{{ getStartHoursList.name }}</div>
                                </div>
                            </ng-template>
                        </p-dropdown>
                    </div>
                    <div class="col-lg-4">
                        <p-dropdown
                            name="startMins"
                            [options]="getStartMinsList"
                            [(ngModel)]="startMins"
                            optionLabel="name"
                            [showClear]="false"
                            placeholder="Select Start Minute"
                            (onChange)="getSelectedStartMins($event)"
                            dataKey="code"
                            [required]="true"
                        >
                            <ng-template pTemplate="selectedItem">
                                <div class="country-item country-item-value">
                                    <div>{{ startMins?.name }}</div>
                                </div>
                            </ng-template>
                            <ng-template let-getStartMinsList pTemplate="item">
                                <div class="country-item">
                                    <div>{{ getStartMinsList.name }}</div>
                                </div>
                            </ng-template>
                        </p-dropdown>
                    </div>
                    <div class="col-lg-4">
                        <p-dropdown
                            [options]="slots"
                            name="selectedStartSlot"
                            (onChange)="getSelectedStartSlot($event)"
                            [(ngModel)]="selectedStartSlot"
                            optionLabel="name"
                            dataKey="code"
                            [required]="true"
                        >
                        </p-dropdown>
                    </div>
                </div>
            </div>
            <div *ngIf="!allDayOption" class="col-lg-12 mt-3">
                <div class="row">
                    <div class="col-lg-4">
                        <p-dropdown
                            name="start"
                            [options]="getEndHoursList"
                            [(ngModel)]="endTime"
                            optionLabel="name"
                            [showClear]="false"
                            placeholder="Select end Hour"
                            (onChange)="getSelectedEndHours($event)"
                            dataKey="code"
                            [required]="true"
                        >
                            <ng-template pTemplate="selectedItem">
                                <div class="country-item country-item-value">
                                    <div>{{ endTime?.name }}</div>
                                </div>
                            </ng-template>
                            <ng-template let-getEndHoursList pTemplate="item">
                                <div class="country-item">
                                    <div>{{ getEndHoursList.name }}</div>
                                </div>
                            </ng-template>
                        </p-dropdown>
                    </div>
                    <div class="col-lg-4">
                        <p-dropdown
                            name="endMins"
                            [options]="getEndMinsList"
                            [(ngModel)]="endMins"
                            optionLabel="name"
                            [showClear]="false"
                            placeholder="Select Start Minute"
                            (onChange)="getSelectedEndMins($event)"
                            dataKey="code"
                            [required]="true"
                        >
                            <ng-template pTemplate="selectedItem">
                                <div class="country-item country-item-value">
                                    <div>{{ endMins?.name }}</div>
                                </div>
                            </ng-template>
                            <ng-template let-getEndMinsList pTemplate="item">
                                <div class="country-item">
                                    <div>{{ getEndMinsList.name }}</div>
                                </div>
                            </ng-template>
                        </p-dropdown>
                    </div>
                    <div class="col-lg-4">
                        <p-dropdown [options]="slots" name="selectedEndSlot" (onChange)="getSelectedEndSlot($event)" [(ngModel)]="selectedEndSlot" optionLabel="name" dataKey="code" [required]="true">
                        </p-dropdown>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="form-group d-flex flex-row align-items-center" *ngIf="hideAlldayOptions">
        <p-checkbox class="ng-checkbox" name="allDayOptions" [(ngModel)]="allDayOption" [binary]="true" label="All day"></p-checkbox>

        <div class="px-3 d-flex flex-row align-items-center w-50">
            <label class="px-3 mb-0">Reccuring:</label>
            <div class="col-md-6">
                <p-dropdown
                    name="reccurOptions"
                    [options]="reccurOptions"
                    [(ngModel)]="selectedReoccurOption"
                    optionLabel="name"
                    placeholder="Choose"
                    (onChange)="selectRepeatOption($event)"
                    dataKey="name"
                >
                    <ng-template pTemplate="selectedItem">
                        <div class="country-item country-item-value">
                            <div>{{ selectedReoccurOption?.name }}</div>
                        </div>
                    </ng-template>
                    <ng-template let-reccurOptions pTemplate="item">
                        <div class="country-item">
                            <div>{{ reccurOptions.name }}</div>
                        </div>
                    </ng-template>
                </p-dropdown>
            </div>
            <div class="col-md-6" *ngIf="chooseRepeatDate">
                <p-calendar [(ngModel)]="repeatDate" name="repeatDate" [readonlyInput]="true" dateFormat="mm/dd/yy" [showIcon]="true" inputId="icon" class="w-100"></p-calendar>
            </div>
        </div>
    </div>
    <div class="form-group">
        <div class="row">
            <div class="col-lg-6">
                <label class="block">Choose Agent <span class="text-danger">*</span></label>
                <p-multiSelect
                    *ngIf="addEventBtn"
                    [options]="agentList"
                    name="dayOptions"
                    [(ngModel)]="selectedLastFilter"
                    optionLabel="name"
                    optionValue="code"
                    placeholder="Choose agent"
                    [filter]="true"
                    filterBy="name"
                    required
                ></p-multiSelect>

                <p-dropdown
                    *ngIf="updateEventBtn"
                    [options]="agentList"
                    [(ngModel)]="editSelectedAgent"
                    optionLabel="name"
                    [showClear]="true"
                    placeholder="Choose agent"
                    (onChange)="getSelectedAgent($event)"
                    name="editSelectedAgent"
                    dataKey="name"
                    [filter]="true"
                    filterBy="name"
                >
                    <ng-template pTemplate="selectedItem">
                        <div class="country-item country-item-value">
                            <div>{{ editSelectedAgent?.name }}</div>
                        </div>
                    </ng-template>
                    <ng-template let-agentList pTemplate="item">
                        <div class="country-item">
                            <div>{{ agentList.name }}</div>
                        </div>
                    </ng-template>
                </p-dropdown>
            </div>
            <div class="col-lg-6">
                <label class="block">Choose Category <span class="text-danger">*</span></label>
                <p-dropdown
                    [options]="categories"
                    [(ngModel)]="selectedCategory"
                    optionLabel="name"
                    [showClear]="true"
                    placeholder="Select Category"
                    (onChange)="getSelectedCategory($event)"
                    name="selectedCategory"
                    dataKey="name"
                    [required]="true"
                    [filter]="true"
                    filterBy="name"

                >
                    <ng-template pTemplate="selectedItem">
                        <div class="country-item country-item-value">
                            <div>{{ selectedCategory?.name }}</div>
                        </div>
                    </ng-template>
                    <ng-template let-categories pTemplate="item">
                        <div class="country-item">
                            <div>{{ categories.name }}</div>
                        </div>
                    </ng-template>
                </p-dropdown>
            </div>
        </div>
    </div>
    <div class="form-group">
        <label>Location </label>
        <input
            type="text"
            name="location"
            [(ngModel)]="selectedSchedule?.schedule.location"
            class="form-control form-control-solid h-auto py-3 px-4 rounded-lg"
            maxlength="200"
            placeholder="Location"
        />
    </div>

    <div class="form-group">
        <label>Notes</label>
        <textarea
            rows="3"
            name="notes"
            [(ngModel)]="selectedSchedule?.schedule.body"
            class="form-control form-control-solid h-auto py-3 px-4 rounded-lg"
            maxlength="200"
            placeholder="Add Appointment Notes"
        ></textarea>
    </div>
    <div class="flex flex-row align-items-center justify-content-end border-top py-3 px-5 mx-n8">
        <button pButton pRipple label="Cancel" icon="pi pi-times" class="p-button-text text-secondary" (click)="onCancel()"></button>
        <button pButton pRipple label="Update" icon="pi pi-check" class="p-button-text" *ngIf="updateEventBtn" [disabled]="!f.valid" (click)="onUpdate()"></button>
        <button pButton pRipple label="Save" icon="pi pi-check" class="p-button-text" *ngIf="addEventBtn" [disabled]="!f.valid" (click)="onSubmit()"></button>
    </div>
</form>
<div *ngIf="loading" class="spinner-view">
    <app-inline-loader></app-inline-loader>
</div>

<p-confirmDialog [style]="{ width: '50vw' }" [baseZIndex]="10000"></p-confirmDialog>
