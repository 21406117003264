import { Injectable } from '@angular/core';
import { HttpUtility } from '@utility/httpUtility';
import { environment } from '../../../environments/environment';

@Injectable({
    providedIn: 'root',
})
export class BulkActionsService {
    constructor(private httpUtility: HttpUtility) {}

    bulkActions(userId: string, postObj) {
        return this.httpUtility.put(`${environment.apiUrl}/leads/${userId}/bulk-actions`, postObj);
    }
}
