/* eslint-disable @typescript-eslint/unbound-method */
import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { BaseComponentDirective } from '@core/component/base.component';
import { UserNoteService } from '../../user-settings/services/user-note.service';
import { first, takeUntil } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { UserNoteModel } from '@app/lead/model/user-note.model';
import { LeadViewModel } from '@app/lead/model/lead-view.model';
import { ConfirmationService } from 'primeng/api';
import { VoiceMailUpdateService } from '@shared/services/voice-mailer-update.services';
import { Subscription } from 'rxjs';
import { DialerContentService } from '../services/dialer-content-service';

@Component({
    selector: 'app-user-note',
    templateUrl: './user-note.component.html',
    styleUrls: ['./user-note.component.scss'],
})
export class UserNoteComponent extends BaseComponentDirective implements OnInit, OnChanges {
    @Input() selectedRowID: LeadViewModel;
    @Input() leadId: string;

    addNoteForm: UntypedFormGroup;
    submitted = false;
    noteList: UserNoteModel[];
    showModal = false;
    showLoader = false;
    userLoader = false;
    selectedRow: UserNoteModel;
    display = false;
    messageReceived: any;
    userId: string;
    dialerSubscribe: Subscription;
    constructor(
        private userNoteService: UserNoteService,
        private confirmationService: ConfirmationService,
        private formBuilder: UntypedFormBuilder,
        private route: ActivatedRoute,
        private voiceMailUpdateService: VoiceMailUpdateService,
        private dialerContentService: DialerContentService,
        private router: Router,
    ) {
        super();

        this.dialerSubscribe = this.dialerContentService.getUpdate().subscribe((message) => {
            this.leadId = message.text;
            this.ngOnInit();
            const currentUrl = this.router.url;
            void this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
                void this.router.navigate([currentUrl]);
            });
        });

        this.voiceMailUpdateService.getVoiceMailUpdate().subscribe((message) => {
            this.messageReceived = message;
            if (this.messageReceived.text === true) {
                this.ngOnInit();
            }
        });
    }
    get f() {
        return this.addNoteForm.controls;
    }
    ngOnInit(): void {
        super.ngOnInit();
        this.getUserNotes();
        this.selectedRow = new UserNoteModel();
        this.addNoteForm = this.formBuilder.group({
            addNote: ['', [Validators.required]],
        });
    }

    ngOnChanges(): void {
        if (this.selectedRowID) {
            console.log('selected Row Data', this.selectedRowID.id);
        } else {
            console.log('passed Data', this.leadId);
        }
    }

    onSubmit(): void {
        const getLatestLead = JSON.parse(localStorage.getItem('Launch_Dialer')) as LeadViewModel[];
        this.submitted = true;
        this.loading = true;
        if (this.addNoteForm.invalid) {
            this.loading = false;
            return;
        }

        const getParams = this.route.snapshot.params;
        if (getParams.hasOwnProperty('id')) {
            this.userId = getParams.id as string;
        } else if (this.selectedRowID) {
            this.userId = this.selectedRowID.id;
        } else if (getLatestLead) {
            this.userId = getLatestLead[0].id;
        } else {
            this.userId = this.leadId;
        }

        this.userNoteService
            .addUserNote(this.userId, {
                userId: this.userId,
                value: this.f.addNote.value as string,
                noteTypeId: 1,
                statusId: 1,
                createdDate: new Date(),
                createdBy: this.userId,
            })
            .pipe(first())
            .subscribe({
                next: () => {
                    this.addNoteForm.reset();
                    this.loading = false;
                    this.addNoteForm.controls.addNote.setErrors(null);
                    this.addNoteForm.updateValueAndValidity();
                    this.getUserNotes();
                    this.messageService.add({
                        key: 'tst',
                        severity: 'success',
                        summary: 'Success',
                        detail: 'user notes added successfully',
                    });
                },
                error: () => {
                    this.loading = false;
                },
            });
    }

    getUserNotes() {
        const getLatestLead = JSON.parse(localStorage.getItem('Launch_Dialer')) as LeadViewModel[];
        const getParams = this.route.snapshot.params;
        this.userLoader = true;
        if (getParams.hasOwnProperty('id')) {
            this.userNoteService.getAllUserNotes(getParams.id).subscribe((_res) => {
                this.noteList = _res;
                this.userLoader = false;
            });
        } else if (this.selectedRowID) {
            this.userNoteService.getAllUserNotes(this.selectedRowID.id).subscribe((_res) => {
                this.noteList = _res;
                this.userLoader = false;
            });
        } else if (getLatestLead) {
            this.userNoteService.getAllUserNotes(getLatestLead[0].id).subscribe((_res) => {
                this.noteList = _res;
                this.userLoader = false;
            });
        } else {
            this.userNoteService.getAllUserNotes(this.leadId).subscribe((_res) => {
                this.noteList = _res;
                this.userLoader = false;
            });
        }
    }

    deleteUserNote(id: string) {
        this.confirmationService.confirm({
            key: 'leadNotesDelete',
            message: 'Are you sure that you want to proceed?',
            header: 'Confirmation',
            icon: 'pi pi-exclamation-triangle',
            accept: () => {
                const getLatestLead = JSON.parse(localStorage.getItem('Launch_Dialer')) as LeadViewModel[];
                const getParams = this.route.snapshot.params;
                if (getParams.hasOwnProperty('id')) {
                    this.userId = getParams.id;
                } else if (this.selectedRowID) {
                    this.userId = this.selectedRowID.id;
                } else if (getLatestLead) {
                    this.userId = getLatestLead[0].id;
                } else {
                    this.userId = this.leadId;
                }
                this.userNoteService
                    .deleteeditUserNotes(this.userId, id)
                    .pipe(takeUntil(this.onDestroy$))
                    .subscribe({
                        next: (data) => {
                            this.messageService.add({
                                key: 'tst',
                                severity: 'success',
                                summary: 'Success',
                                detail: 'Successfully deleted user notes',
                            });
                            this.logger.log('user notes', data);
                            this.getUserNotes();
                        },
                        error: (error) => {
                            this.messageService.add({
                                key: 'tst',
                                severity: 'error',
                                summary: 'Error',
                                detail: 'Error in deleting user notes',
                            });
                            this.logger.log('error user notes', error);
                            this.getUserNotes();
                        },
                    });
            },
        });
    }

    editUserNote(userNoteModel: UserNoteModel) {
        this.selectedRow = JSON.parse(JSON.stringify(userNoteModel));
        console.log(JSON.parse(JSON.stringify(userNoteModel.value)));
        this.display = true;
        this.showModal = true;
    }

    updateUserNote() {
        const getLatestLead = JSON.parse(localStorage.getItem('Launch_Dialer')) as LeadViewModel[];
        const getParams = this.route.snapshot.params;
        if (getParams.hasOwnProperty('id')) {
            this.userId = getParams.id;
        } else if (this.selectedRowID) {
            this.userId = this.selectedRowID.id;
        } else if (getLatestLead) {
            this.userId = getLatestLead[0].id;
        } else {
            this.userId = this.leadId;
        }
        this.userNoteService
            .editUserNotes(
                this.userId,
                new UserNoteModel({
                    id: this.selectedRow.id,
                    value: this.selectedRow.value,
                    noteTypeId: 1,
                    statusId: 1,
                }),
            )
            .pipe(first())
            .subscribe({
                next: () => {
                    this.addNoteForm.reset();
                    this.loading = false;
                    this.addNoteForm.controls.addNote.setErrors(null);
                    this.addNoteForm.updateValueAndValidity();
                    this.cancel();
                    this.getUserNotes();
                    this.messageService.add({
                        key: 'tst',
                        severity: 'success',
                        summary: 'Success',
                        detail: 'user notes updated successfully',
                    });
                },
                error: () => {
                    this.loading = false;
                },
            });
    }

    cancel() {
        this.showModal = false;
        this.display = false;
    }
}
