import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '@app/authentication/services/authentication.service';
import { LeadListService } from '@app/lead/services/leadlist-service';
import { BaseComponentDirective } from '@core/component/base.component';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import BulkActionsEnum from '../bulk-actions.enum';
import { BulkActionsService } from '../bulk-actions.service';

@Component({
    selector: 'app-bulk-add-remove-tag',
    templateUrl: './bulk-add-remove-tag.component.html',
})
export class BulkAddRemoveTagComponent extends BaseComponentDirective implements OnInit {
    selectedList: Array<string>;
    selectedTags = [];
    tagOptions: { name: string; code: string }[] = [];
    getUserId = this.authService.getLoggedInId();
    loading = false;
    isAdd = false;

    constructor(
        public config: DynamicDialogConfig,
        private leadService: LeadListService,
        private authService: AuthenticationService,
        private bulkActionsService: BulkActionsService,
        public ref: DynamicDialogRef,
    ) {
        super();
    }

    ngOnInit() {
        super.ngOnInit();
        this.selectedList = this.config.data.selectedList as Array<string>;
        this.isAdd = this.config.data.isAdd;
        this.getTags();
    }

    getTags() {
        this.leadService.getLeadTags().subscribe((res) => {
            res.forEach((element) => {
                this.tagOptions.push({
                    name: element.name,
                    code: element.id,
                });
            });
        });
    }

    onSubmit() {
        if (this.selectedTags.length === 0) {
            this.loading = false;
            return;
        }
        this.loading = true;
        this.bulkActionsService
            .bulkActions(this.getUserId, {
                action: this.isAdd ? BulkActionsEnum.AddTags : BulkActionsEnum.DeleteTags,
                userIds: this.selectedList,
                entityIds: this.selectedTags,
            })
            .subscribe({
                next: () => {
                    this.selectedTags = [];
                    this.loading = false;
                    this.messageService.add({
                        key: 'tst',
                        severity: 'success',
                        summary: 'Success',
                        detail: this.isAdd ? 'Tags added successfully' : 'Tags deleted successfully',
                    });
                    this.ref.close();
                },
                error: () => {
                    this.loading = false;
                },
            });
    }
}
