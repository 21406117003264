import { Component, Input, OnInit } from '@angular/core';
import { BaseComponentDirective } from '@core/component/base.component';
import { MedicareModel } from '../model/user-medicare.model';
import { UserMedicareListService } from '../services/user-medicare.service';
import moment from 'moment';
import { ConfirmationService } from 'primeng/api';
import { Subscription, takeUntil } from 'rxjs';
import { Router } from '@angular/router';
import { DialerContentService } from '@app/shared/services/dialer-content-service';

@Component({
    selector: 'app-user-medicare',
    templateUrl: './user-medicare.component.html',
    styleUrls: ['./user-medicare.component.scss'],
})
export class UserMedicareComponent extends BaseComponentDirective implements OnInit {
    @Input() leadId: string;
    medicareList = [];
    selectedRow: MedicareModel;
    showLoader = false;
    ModalTitle: string;
    display = false;
    showModal = false;
    BtnTitle = '';
    loading = false;
    dialerSubscribe: Subscription;
    constructor(private userMedicareSvc: UserMedicareListService, private confirmationService: ConfirmationService, private router: Router, private dialerContentService: DialerContentService) {
        super();
        this.dialerSubscribe = this.dialerContentService.getUpdate().subscribe((message) => {
            this.leadId = message.text;
            this.ngOnInit();
            const currentUrl = this.router.url;
            void this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
                void this.router.navigate([currentUrl]);
            });
        });
    }

    ngOnInit(): void {
        this.selectedRow = new MedicareModel();
        this.getAllUserMedicareList();
    }

    getAllUserMedicareList() {
        this.loading = true;
        this.userMedicareSvc.getAllMedicareList(this.leadId).subscribe((res) => {
            this.medicareList = res;
            this.loading = false;
        });
    }

    cancel() {
        this.display = false;
        this.showModal = false;
        this.selectedRow = new MedicareModel();
    }

    createMedicare() {
        this.ModalTitle = 'Add';
        this.BtnTitle = 'Save';
        this.display = true;
        this.showModal = true;
        this.selectedRow = new MedicareModel();
    }

    editUserMedicare(obj) {
        this.ModalTitle = 'Edit';
        this.BtnTitle = 'Update';
        this.display = true;
        this.showModal = true;
        this.selectedRow = {
            id: obj.id,
            medicareNumber: obj.medicareNumber,
            partA: obj.partA !== null ? new Date(moment(obj.partA).format('YYYY-MM-DDTHH:mm:ss')) : '',
            partB: obj.partB !== null ? new Date(moment(obj.partB).format('YYYY-MM-DDTHH:mm:ss')) : '',
            ssn: obj.ssn,
            statusId: 1,
            userId: obj.userId,
        };
    }

    submitMedicare() {
        if (this.BtnTitle === 'Update') {
            const editMedicare = {
                id: this.selectedRow.id,
                medicareNumber: this.selectedRow.medicareNumber,
                partA: this.selectedRow.partA !== null ? moment(this.selectedRow.partA).format('YYYY-MM-DDTHH:mm:ss') : this.selectedRow.partA,
                partB: this.selectedRow.partB !== null ? moment(this.selectedRow.partB).format('YYYY-MM-DDTHH:mm:ss') : this.selectedRow.partB,
                ssn: this.selectedRow.ssn,
                statusId: 1,
                userId: this.selectedRow.userId,
            };
            this.userMedicareSvc.updateMedicare(this.leadId, editMedicare).subscribe({
                next: () => {
                    this.showLoader = false;
                    this.cancel();
                    this.selectedRow = new MedicareModel();
                    this.getAllUserMedicareList();
                    this.messageService.add({
                        key: 'tst',
                        severity: 'success',
                        summary: 'Success',
                        detail: `Medicare Edited Successfully`,
                    });
                },
                error: () => {
                    this.showLoader = false;
                    this.messageService.add({
                        key: 'tst',
                        severity: 'error',
                        summary: 'Error',
                        detail: 'something went wrong, please try again',
                    });
                },
            });
        } else {
            if (this.selectedRow.medicareNumber === undefined && this.selectedRow.ssn === undefined && this.selectedRow.partA === undefined && this.selectedRow.partB === undefined) {
                this.messageService.add({
                    key: 'tst',
                    severity: 'error',
                    summary: 'Error',
                    detail: 'Please fill atleast one field',
                });
                return;
            }
            const addMedicareObj: MedicareModel = {
                medicareNumber: this.selectedRow.medicareNumber,
                partA: this.selectedRow.partA !== undefined ? moment(this.selectedRow.partA).format('YYYY-MM-DDTHH:mm:ss') : this.selectedRow.partA,
                partB: this.selectedRow.partB !== undefined ? moment(this.selectedRow.partB).format('YYYY-MM-DDTHH:mm:ss') : this.selectedRow.partB,
                ssn: this.selectedRow.ssn,
                statusId: 1,
                userId: this.leadId,
            };
            console.log(addMedicareObj);
            this.userMedicareSvc.createMedicare(this.leadId, addMedicareObj).subscribe({
                next: () => {
                    this.showLoader = false;
                    this.cancel();
                    this.selectedRow = new MedicareModel();
                    this.getAllUserMedicareList();
                    this.messageService.add({
                        key: 'tst',
                        severity: 'success',
                        summary: 'Success',
                        detail: `Medicare Added Successfully`,
                    });
                },
                error: () => {
                    this.showLoader = false;
                    this.messageService.add({
                        key: 'tst',
                        severity: 'error',
                        summary: 'Error',
                        detail: 'something went wrong, please try again',
                    });
                },
            });
        }
    }

    confirmDelete(id: string) {
        this.confirmationService.confirm({
            key: 'leadDelete',
            message: 'Are you sure that you want to proceed?',
            header: 'Confirmation',
            icon: 'pi pi-exclamation-triangle',
            accept: () => {
                this.deleteMedicare(id);
            },
        });
    }

    deleteMedicare(id: string) {
        this.userMedicareSvc
            .deleteMedicareById(this.leadId, id)
            .pipe(takeUntil(this.onDestroy$))
            .subscribe({
                next: () => {
                    this.messageService.add({
                        key: 'tst',
                        severity: 'success',
                        summary: 'Success',
                        detail: 'Successfully deleted user Medicare',
                    });

                    this.getAllUserMedicareList();
                },
                error: () => {
                    this.messageService.add({
                        key: 'tst',
                        severity: 'error',
                        summary: 'Error',
                        detail: 'Error in deleting user Medicare',
                    });
                },
            });
    }
}
