<p-dropdown
    name="leadStatusList"
    [options]="leadStatusList"
    [(ngModel)]="selectedStatus"
    placeholder="Choose Lead Status"
    optionValue="value"
    optionLabel="label"
    styleClass="w-100 input-group input-group-lg input-group-solid"
    [group]="true"
    [filter]="true"
    filterBy="label"
>
    <ng-template let-leadStatusList pTemplate="group">
        <div class="p-d-flex p-ai-center">
            <h6>{{ leadStatusList.label }}</h6>
            <span>{{ leadStatusList.items.label }}</span>
        </div>
    </ng-template>
</p-dropdown>
<div class="flex flex-column justify-content-end align-items-end">
    <button [loading]="loading" (click)="onSubmit()" pButton pRipple label="Save" icon="pi pi-check" class="p-button-text mt-2"></button>
</div>
<!-- <p-toast key="tst"></p-toast> -->
