import { Injectable } from '@angular/core';
import { HttpUtility } from '@utility/httpUtility';
import { environment } from '@environments/environment';
import { Observable } from 'rxjs';
import { VoiceGreetingModel } from '@app/user-settings/models/voice-greeting-model';
import { VoiceGreetingViewModel } from '@app/shared/models/call-log-model';
@Injectable({ providedIn: 'root' })
export class VoiceGreetingService {
    constructor(private httpUtility: HttpUtility) {}

    getAudioGreetings(): Observable<VoiceGreetingViewModel[]> {
        return this.httpUtility.get(`${environment.apiUrl}/voicegreeting`);
    }

    createAudioGreetings(form: FormData) {
        return this.httpUtility.postFile<VoiceGreetingModel>(`${environment.apiUrl}/voicegreeting`, form);
    }


    updateAudioGreetings(updatePayload) {
        return this.httpUtility.put<VoiceGreetingModel>(`${environment.apiUrl}/voicegreeting`, updatePayload);
    }

    delteAudioGreetings(id: string) {
        return this.httpUtility.delete<VoiceGreetingModel>(`${environment.apiUrl}/voicegreeting/${id}`);
    }
}
