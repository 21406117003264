/* eslint-disable @typescript-eslint/unbound-method */
import { Component, OnInit, Input } from '@angular/core';
import { BaseComponentDirective } from '@core/component/base.component';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { LeadListService } from '@app/lead/services/leadlist-service';
import { first, takeUntil } from 'rxjs/operators';
import { ConfirmationService } from 'primeng/api';
import { ActivatedRoute, Router } from '@angular/router';
import { LeadViewModel } from '@app/lead/model/lead-view.model';
import { UserMedicationModel } from '../model/user-medication-model';
import { Subscription } from 'rxjs';
import { DialerContentService } from '@app/shared/services/dialer-content-service';

@Component({
    selector: 'app-lead-medications',
    templateUrl: './lead-medications.component.html',
    styleUrls: ['./lead-medications.component.scss'],
})
export class LeadMedicationsComponent extends BaseComponentDirective implements OnInit {
    @Input() leadId: string;
    addMedicationForm: UntypedFormGroup;
    submitted = false;
    userId: string;
    leadMedications: any;
    selectedRow: UserMedicationModel;
    showModal = false;
    showLoader = false;
    display = false;
    loadingData: boolean;
    dialerSubscribe: Subscription;
    constructor(
        private leadService: LeadListService,
        private formBuilder: UntypedFormBuilder,
        private confirmationService: ConfirmationService,
        private route: ActivatedRoute,
        private router: Router,
        private dialerContentService: DialerContentService,
    ) {
        super();
        this.dialerSubscribe = this.dialerContentService.getUpdate().subscribe((message) => {
            this.leadId = message.text;
            this.ngOnInit();
            const currentUrl = this.router.url;
            void this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
                void this.router.navigate([currentUrl]);
            });
        });
    }
    get f() {
        return this.addMedicationForm.controls;
    }

    ngOnInit(): void {
        super.ngOnInit();
        this.selectedRow = new UserMedicationModel();
        this.addMedicationForm = this.formBuilder.group({
            addMedication: ['', [Validators.required]],
        });
        this.getLeadMedicationsList();
    }

    getLeadMedicationsList(): void {
        this.loadingData = true;
        this.leadService
            .getLeadMedication(this.leadId)
            .pipe(takeUntil(this.onDestroy$))
            .subscribe((result) => {
                this.loadingData = false;
                this.leadMedications = result;
            });
    }

    onSubmit(): void {
        this.submitted = true;
        this.loading = true;
        if (this.addMedicationForm.invalid) {
            this.loading = false;
            return;
        }

        this.leadService
            .addLeadMedication(this.leadId, {
                userId: this.leadId,
                value: this.f.addMedication.value,
                statusId: 1,
            })
            .pipe(first())
            .subscribe({
                next: () => {
                    this.addMedicationForm.reset();
                    this.loading = false;
                    this.addMedicationForm.controls.addMedication.setErrors(null);
                    this.addMedicationForm.updateValueAndValidity();
                    this.getLeadMedicationsList();
                },
                error: () => {
                    this.loading = false;
                },
            });
    }

    editUserMedication(UserMedicationModel: UserMedicationModel) {
        this.selectedRow = JSON.parse(JSON.stringify(UserMedicationModel));
        this.display = true;
        this.showModal = true;
    }

    deleteUserMedication(id: string) {
        const getLatestLead = JSON.parse(localStorage.getItem('Launch_Dialer')) as LeadViewModel[];
        const getParams = this.route.snapshot.params;
        if (getParams.hasOwnProperty('id')) {
            this.userId = getParams.id;
        } else if (getLatestLead) {
            this.userId = getLatestLead[0].id;
        } else {
            this.userId = this.leadId;
        }
        this.confirmationService.confirm({
            message: 'Are you sure that you want to proceed?',
            header: 'Confirmation',
            key: 'leadMedicationDelete',
            icon: 'pi pi-exclamation-triangle',
            accept: () => {
                this.leadService
                    .deleteLeadMedication(this.userId, id)
                    .pipe(takeUntil(this.onDestroy$))
                    .subscribe({
                        next: () => {
                            this.getLeadMedicationsList();
                            this.messageService.add({
                                key: 'tst',
                                severity: 'success',
                                summary: 'Success',
                                detail: 'Successfully deleted user medication(s)',
                            });
                        },
                        error: (error) => {
                            this.messageService.add({
                                key: 'tst',
                                severity: 'error',
                                summary: 'Error',
                                detail: 'Error in deleting user medication(s)',
                            });
                            this.logger.log('error user medications', error);
                        },
                    });
            },
        });
    }

    updateUserMedication() {
        const getLatestLead = JSON.parse(localStorage.getItem('Launch_Dialer')) as LeadViewModel[];
        const getParams = this.route.snapshot.params;
        if (getParams.hasOwnProperty('id')) {
            this.userId = getParams.id;
        } else if (getLatestLead) {
            this.userId = getLatestLead[0].id;
        } else {
            this.userId = this.leadId;
        }
        this.showLoader = true;
        this.leadService
            .editLeadMedication(
                this.userId,
                new UserMedicationModel({
                    id: this.selectedRow.id,
                    value: this.selectedRow.value,
                    userId: this.userId,
                    statusId: 1,
                }),
            )
            .pipe(first())
            .subscribe({
                next: () => {
                    this.addMedicationForm.reset();
                    this.loading = false;
                    this.showLoader = false;
                    this.addMedicationForm.controls.addMedication.setErrors(null);
                    this.addMedicationForm.updateValueAndValidity();
                    this.cancel();
                    this.selectedRow = new UserMedicationModel();
                    this.getLeadMedicationsList();
                    this.messageService.add({
                        key: 'tst',
                        severity: 'success',
                        summary: 'Success',
                        detail: 'user medications updated successfully',
                    });
                },
                error: () => {
                    this.loading = false;
                    this.showLoader = false;
                    this.messageService.add({
                        key: 'tst',
                        severity: 'error',
                        summary: 'Error',
                        detail: 'Something went wrong, please try again',
                    });
                },
            });
    }

    cancel() {
        this.showModal = false;
        this.display = false;
    }
}
