import { Injectable } from '@angular/core';
import { UserFile } from '@app/shared/user/model/user-file.model';
import { environment } from '@environments/environment';
import { Bearertoken } from '@shared/authentication/model/bearer-token-model';
import { HttpUtility } from '@utility/httpUtility';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class UserService {
    constructor(private http: HttpUtility, private httpUtility: HttpUtility) {}

    getAll(): Observable<Bearertoken[]> {
        return this.http.get<Bearertoken[]>(`${environment.apiUrl}/customers`);
    }

    getById(id: number): Observable<Bearertoken> {
        return this.http.get<Bearertoken>(`${environment.apiUrl}/customers/${id}`);
    }

    getByIdentifier(id: string): Observable<Bearertoken> {
        return this.httpUtility.get(`${environment.apiUrl}/users/${id}`);
    }

    getUserFiles(userId: string): Observable<UserFile[]> {
        return this.httpUtility.get(`${environment.apiUrl}/user/${userId}/user-files`);
    }

    addUserFile(userId: string, form: FormData) {
        return this.httpUtility.postFile(`${environment.apiUrl}/user/${userId}/user-files`, form);
    }

    deleteUserFile(userId: string, id: string) {
        return this.httpUtility.delete(`${environment.apiUrl}/user/${userId}/user-files/${id}`);
    }
}
