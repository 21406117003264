/* eslint-disable @typescript-eslint/unbound-method */
import { Component, OnInit, Input } from '@angular/core';
import { UserModel } from '@app/users/models/user-model';
import { UserAddressCreateModel, UserAddressViewModel } from '@app/user-settings/models/user-addresses-model';
import { UntypedFormGroup, Validators, UntypedFormBuilder } from '@angular/forms';
import { CountyService } from '@app/user-settings/services/county-service';
import { StateService } from '@app/user-settings/services/state-service';
import { CountyModel } from '@app/user-settings/models/county-model';
import { StateModel } from '@app/user-settings/models/state-model';
import { sortBy } from 'lodash';
import { LuAddressTypeModel } from '@app/user-settings/models/address-type-model';
import { PhoneService } from '@app/user-settings/services/phone-service';
import { UserPhoneCreateModel, UserPhoneModel, UserPhoneViewModel } from '@app/user-settings/models/phone-model';
import { LuPhoneTypeModel } from '@app/user-settings/models/lu-phone-type-model';
import { LuPhoneTypeService } from '@app/user-settings/services/lu-phone-types-service';
import { ConfirmationService, Message } from 'primeng/api';
import { takeUntil } from 'rxjs';
import { BaseComponentDirective } from '@core/component/base.component';
import { DialerStateService, DialerState } from '@app/shared/services/dialer-service';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { DialerContentService } from '@app/shared/services/dialer-content-service';
import { pageReloadService } from '@app/shared/services/page-reload-service';
@Component({
    selector: 'app-lead-phones',
    templateUrl: './lead-phones.component.html',
})
export class LeadPhonesComponent extends BaseComponentDirective implements OnInit {
    @Input() leadId: string;
    @Input() storeLeadSingleData: any;
    userId: string;
    user: UserModel;
    msgs: Message[] = [];
    userPhoneViewModel: UserPhoneViewModel;
    userPhonesViewModelDetails: UserPhoneViewModel[];
    showDialog = false;
    selectedusersId: string;
    formGroup: UntypedFormGroup;
    editFormGroup: UntypedFormGroup;
    counties: CountyModel[];
    states: StateModel[];
    statePrimary: StateModel;
    stateSecondary: StateModel;
    selectedState: StateModel;
    selectedCounty: CountyModel;
    userAddressCreateModel: UserAddressCreateModel;
    selectedPhoneType;
    addressTypes: LuAddressTypeModel[];
    phoneTypes: LuPhoneTypeModel[];
    deleteAddressDialog: boolean;
    loading: boolean;
    modalLoader: boolean;
    dialogType = 'create';
    dialerSubscribe: Subscription;
    currentPhoneTypes = [];
    stateList: any;

    userAddressViewModelDetails: UserAddressViewModel;
    constructor(
        private readonly phoneService: PhoneService,
        private formBuilder: UntypedFormBuilder,
        private readonly stateService: StateService,
        private readonly countyService: CountyService,
        private readonly luPhoneTypeService: LuPhoneTypeService,
        private confirmationService: ConfirmationService,
        private dailerService: DialerStateService,
        private router: Router,
        private dialerContentService: DialerContentService,
        private pageReloadSvc: pageReloadService,
    ) {
        super();
        this.dialerSubscribe = this.dialerContentService.getUpdate().subscribe((message) => {
            this.leadId = message.text;
            this.ngOnInit();
            const currentUrl = this.router.url;
            void this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
                void this.router.navigate([currentUrl]);
            });
        });
    }

    ngOnInit(): void {
        super.ngOnInit();
        this.getUserPhones();
        this.loadForm();
        this.getluStatesService();
        this.getluPhoneTypesService();
    }

    getUserPhones(): void {
        this.loading = true;
        this.phoneService.getPhonesById(this.leadId).subscribe((result) => {
            this.userPhonesViewModelDetails = result;
            this.loading = false;
            this.currentPhoneTypes = [];
            this.userPhonesViewModelDetails.forEach((element) => {
                this.currentPhoneTypes.push(element.phoneType.id);
            });
        });
    }

    openNew() {
        this.showDialog = true;
        this.dialogType = 'create';
        this.formGroup.reset();
        this.selectedState = undefined;
        this.selectedCounty = undefined;
        this.selectedPhoneType = '';
    }

    openEdit(id: string) {
        this.showDialog = true;
        this.dialogType = 'update';
        // this.formGroup.controls['stateId'].touched;
        this.selectedusersId = id;
        this.userPhonesViewModelDetails.forEach((userPhonesValue) => {
            if (userPhonesValue.id === id) {
                this.formGroup.patchValue({
                    phone: userPhonesValue.number,
                    extension: userPhonesValue.extension,
                });
                this.selectedPhoneType = userPhonesValue.phoneType.id;
            }
        });
    }

    hideDialog() {
        this.showDialog = false;
    }

    savePhones() {
        const userPhoneCreateModel: UserPhoneCreateModel = {
            userId: this.leadId,
            number: this.formGroup.value.phone,
            extension: this.formGroup.value.extension,
            phoneTypeId: this.selectedPhoneType,
        };
        if (
            this.currentPhoneTypes.some((x) => {
                return x === parseInt(this.selectedPhoneType, 10);
            })
        ) {
            this.messageService.add({
                key: 'tst',
                severity: 'error',
                summary: 'Error',
                detail: 'Phone Type is already existed, try with some other',
            });
            return false;
        }
        this.modalLoader = true;
        this.phoneService
            .addPhones(userPhoneCreateModel, this.leadId)
            .pipe(takeUntil(this.onDestroy$))
            .subscribe({
                next: () => {
                    window.scrollTo(0, 0);
                    this.modalLoader = false;
                    this.currentPhoneTypes = [];
                    this.messageService.add({
                        key: 'tst',
                        severity: 'success',
                        summary: 'Success',
                        detail: 'Added new phone',
                    });
                    this.hideDialog();
                    this.getUserPhones();
                    this.pageReloadSvc.sendUpdate('SAVED');
                },
                error: () => {
                    this.messageService.add({
                        key: 'tst',
                        severity: 'error',
                        summary: 'Error',
                        detail: 'Error in adding new user Phone',
                    });

                    window.scrollTo(0, 0);
                    this.hideDialog();
                },
            });
    }

    saveEditedPhones() {
        const userPhoneModel: UserPhoneModel = {
            userId: this.leadId,
            number: this.formGroup.value.phone,
            extension: this.formGroup.value.extension as string,
            phoneTypeId: this.selectedPhoneType,
            id: this.selectedusersId,
        };
        this.phoneService
            .editPhones(userPhoneModel, this.leadId)
            .pipe(takeUntil(this.onDestroy$))
            .subscribe({
                next: () => {
                    window.scrollTo(0, 0);
                    this.messageService.add({
                        key: 'tst',
                        severity: 'success',
                        summary: 'Success',
                        detail: 'Updated user phone',
                    });
                    this.hideDialog();
                    this.getUserPhones();
                    this.pageReloadSvc.sendUpdate('SAVED');
                },
                error: () => {
                    this.messageService.add({
                        key: 'tst',
                        severity: 'error',
                        summary: 'Error',
                        detail: 'Error in updating new user Phone',
                    });

                    window.scrollTo(0, 0);
                    this.hideDialog();
                },
            });
    }
    loadForm(): void {
        this.formGroup = this.formBuilder.group({
            phone: ['', Validators.required],
            extension: [''],
            phoneType: ['', Validators.required],
        });
    }

    deleteUserPhones(id: string) {
        this.phoneService
            .deletePhonesById(this.leadId, id)
            .pipe(takeUntil(this.onDestroy$))
            .subscribe({
                next: () => {
                    this.messageService.add({
                        key: 'tst',
                        severity: 'success',
                        summary: 'Success',
                        detail: 'Successfully deleted user Phone',
                    });

                    this.getUserPhones();
                },
                error: () => {
                    this.messageService.add({
                        key: 'tst',
                        severity: 'error',
                        summary: 'Error',
                        detail: 'Error in deleting user Phone',
                    });
                },
            });
    }

    confirmDelete(id: string) {
        this.confirmationService.confirm({
            key: 'leadDelete',
            message: 'Are you sure that you want to proceed?',
            header: 'Confirmation',
            icon: 'pi pi-exclamation-triangle',
            accept: () => {
                this.deleteUserPhones(id);
            },
        });
    }

    getCountiessService(): void {
        this.countyService.getCounties(this.formGroup.value.stateId.id).subscribe((result) => {
            this.counties = result;
        });
    }

    getluStatesService(): void {
        this.stateService.getStates().subscribe((result) => {
            this.states = result;
        });
    }

    getluPhoneTypesService(): void {
        this.luPhoneTypeService.getPhoneTypes().subscribe((result) => {
            this.phoneTypes = sortBy(result, (o) => {
                return o.description;
            });
        });
    }

    isControlValid(controlName: string): boolean {
        const control = this.formGroup.controls[controlName];
        return control.valid && (control.dirty || control.touched);
    }

    isControlInvalid(controlName: string): boolean {
        const control = this.formGroup.controls[controlName];
        return control.invalid && (control.dirty || control.touched);
    }

    enableDialer(e) {
        const getLatestLeadObj = this.storeLeadSingleData;
        let callStoreType = '';
        if (e.target.id === 'Work Phone') {
            callStoreType = 'workPhone';
        } else if (e.target.id === 'Mobile Phone') {
            callStoreType = 'cellPhone';
        } else if (e.target.id === 'Home Phone') {
            callStoreType = 'homePhone';
        }
        const filteredList = [];
        filteredList.push(getLatestLeadObj);
        this.dailerService.set(new DialerState({ showDialerBar: false }));
        localStorage.setItem('callType', callStoreType);
        localStorage.removeItem('Launch_Dialer');
        setTimeout(() => {
            if (this.router.url !== '/leads/dialer') {
                localStorage.setItem('Launch_Dialer', JSON.stringify(filteredList));
            }

            const body = document.getElementsByTagName('body')[0];
            body.classList.add('dialer-open');
            this.dailerService.set(new DialerState({ showDialerBar: true }));
        }, 100);
    }
}
