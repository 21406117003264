import { Injectable } from '@angular/core';
import { HttpUtility } from '@utility/httpUtility';
import { environment } from '@environments/environment';
import { ViewFilterModel } from '@app/calendar/model/view-filter-model';
import { Observable, BehaviorSubject } from 'rxjs';
import { AddEventModel } from '../model/addEvent-model';
import { filterEventsModel } from '../model/filterEvent-model';
import { EditEventModel } from '../model/editEvent-model';
import { BaseLookupModel } from '@app/user-settings/models/agent-lookup-model';

@Injectable({ providedIn: 'root' })
export class CalendarService {
    calendarEventUpdate$ = new BehaviorSubject<number>(0);

    constructor(private httpUtility: HttpUtility) { }

    setCalendarEventUpdate() {
        let value = this.calendarEventUpdate$.value;
        this.calendarEventUpdate$.next(++value);
    }

    getCalendarEvents(calendarGetModel: filterEventsModel) {
        return this.httpUtility.post<any>(`${environment.apiUrl}/calendar-event`, calendarGetModel);
    }

    getEventTypes(): Observable<ViewFilterModel[]> {
        return this.httpUtility.get<ViewFilterModel[]>(`${environment.apiUrl}/calendar-event-types`);
    }

    getAllCategories(): Observable<ViewFilterModel[]> {
        return this.httpUtility.get<ViewFilterModel[]>(`${environment.apiUrl}/calendar-event-categories`);
    }

    getAllAgents(): Observable<BaseLookupModel[]> {
        return this.httpUtility.get(`${environment.apiUrl}/users/agent-lookup`);
    }

    addCalendarEvent(addEventModel: AddEventModel) {
        return this.httpUtility.post(`${environment.apiUrl}/calendar-event/add`, addEventModel);
    }

    getFilterEvents(filterEvents: filterEventsModel) {
        return this.httpUtility.post<any>(`${environment.apiUrl}/calendar-event`, filterEvents);
    }

    completeEvent(id: string) {
        return this.httpUtility.put(`${environment.apiUrl}/calendar-event/${id}/complete`, id);
    }

    deleteEvent(id: string) {
        return this.httpUtility.delete(`${environment.apiUrl}/calendar-event/${id}`);
    }

    updateEvent(id: string, editEventModel: EditEventModel) {
        return this.httpUtility.put(`${environment.apiUrl}/calendar-event/${id}`, editEventModel);
    }
}
