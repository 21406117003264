import { Injectable } from '@angular/core';
import { HttpUtility } from '@utility/httpUtility';
import { Observable } from 'rxjs';
import { environment } from '@environments/environment';
import { StateModel } from '../models/state-model';
import { Cacheable } from 'ts-cacheable';

@Injectable({ providedIn: 'root' })
export class StateService {
    constructor(private httpUtility: HttpUtility) {}
    @Cacheable()
    getStates(): Observable<StateModel[]> {
        return this.httpUtility.get<StateModel[]>(`${environment.apiUrl}/states`);
    }
    @Cacheable()
    getStatesById(id: number): Observable<StateModel> {
        return this.httpUtility.get<StateModel>(`${environment.apiUrl}/states/${id}`);
    }
}
