<ng-container>
    <div class="container-fluid px-0">
        <div>
            <div class="p-0 card card-custom col-xl-12">
                <div>
                    <p-table
                        #dt
                        [value]="callLogs"
                        [rows]="10"
                        [paginator]="true"
                        [globalFilterFields]="['startTime', 'endTime', 'firstName']"
                        [rowHover]="true"
                        dataKey="id"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                        [showCurrentPageReport]="true"
                    >
                        <!-- <ng-template pTemplate="caption">
                        <div class="d-flex justify-content-between align-items-center">
                            <h5 class="p-m-0 d-inline-block">Manage Users</h5>
                            <span class="p-input-icon-left">
                                <i class="pi pi-search"></i>
                                <input pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')" placeholder="Search..."  class="form-control  h-auto py-2 px-6 rounded-lg" />
                            </span>
                        </div>
                    </ng-template> -->

                        <ng-template pTemplate="header">
                            <tr>
                                <th pSortableColumn="firstName">Name<p-sortIcon field="firstName"></p-sortIcon></th>
                                <th pSortableColumn="fromNumber">From<p-sortIcon field="fromNumber"></p-sortIcon></th>
                                <th pSortableColumn="toNumber">To <p-sortIcon field="toNumber"></p-sortIcon></th>
                                <th pSortableColumn="startTime">Start<i class="pi pi-clock ml-2 text-secondary"></i><p-sortIcon field="startTime"></p-sortIcon></th>
                                <th pSortableColumn="endTime">End <i class="pi pi-clock ml-2 text-secondary"></i><p-sortIcon field="endTime"></p-sortIcon></th>
                                <th style="width: 30%">Recording</th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-user>
                            <tr class="p-selectable-row">
                                <td>
                                    <span class="p-column-title">Full Name</span>
                                    {{ user.agent?.firstName }} {{ user.agent?.lastName }}
                                </td>
                                <td>
                                    <span class="p-column-title">From</span>
                                    {{ user.fromNumber | mask: '+0 (000) 000-0000' }}
                                </td>
                                <td>
                                    <span class="p-column-title">To</span>
                                    {{ user.toNumber | mask: '+0 (000) 000-0000' }}
                                </td>
                                <td>
                                    <span class="p-column-title">Start Time</span>
                                    <!-- <a (click)="editProduct(user.id)" class="nav-link">{{ user.firstName }} {{ user.lastName }}</a> -->
                                    {{ user.startTime | utcToLocal: 'MM-DD-YYYY h:mm A' }}
                                </td>
                                <td>
                                    <span class="p-column-title">End Time</span>
                                    {{ user.endTime | utcToLocal: 'MM-DD-YYYY h:mm A' }}
                                </td>
                                <td>
                                    <audio controls *ngIf="user.recordingPath">
                                        <source [src]="user.recordingPath" type="audio/ogg" />
                                    </audio>
                                    <span *ngIf="!user.recordingPath"> No recording Available </span>
                                </td>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="emptymessage">
                            <tr>
                                <td colspan="8">No call logs found.</td>
                            </tr>
                        </ng-template>
                    </p-table>
                </div>
            </div>
        </div>
    </div>
</ng-container>

<!-- <p-toast key="tst"></p-toast> -->
