<div class="dialer-bar-view px-3">
    <div class="py-3 d-flex flex-row align-items-center justify-content-between" *ngIf="enableDialer">
        <span></span>
        <p class="text-center m-0">You are not allowed to use dialer...</p>
        <button pButton type="button" icon="pi pi-sign-out" label="Exit Dialer" class="p-button-outlined p-button-danger" (click)="exitDialerBar()"></button>
    </div>
    <p class="text-center m-0 py-3" *ngIf="dialerLaunchMsg">Please wait dialer is launching...</p>
    <div class="d-flex flex-row align-items-center w-100 py-3 px-1 justify-content-between" *ngIf="showDialerOptions">
        <div class="d-flex flex-row align-items-center justify-content-between">
            <span class="text-dark font-weight-bold mt-2 mb-2 mr-5">DIALER</span>
            <div class="d-flex flex-row align-items-center">
                <label class="mx-2 my-0">MY CALLER ID:</label>
                <span class="font-weight-bolder" *ngIf="showSingleCallOptions">{{ singleCallerId }}</span>
                <p-dropdown
                    [options]="phonesList"
                    [(ngModel)]="selectedPhone"
                    optionLabel="name"
                    filterBy="name"
                    [showClear]="false"
                    (onChange)="selectFromNumber($event)"
                    *ngIf="showMultiCallOptions"
                    [filter]="true"
                    styleClass="dailer_dropdown"
                ></p-dropdown>
                <!-- <p-checkbox [(ngModel)]="showEveryLead" label="Show Everyone" [binary]="true" class="ml-2 my-0" (onChange)="checkValue($event)" *ngIf="isAdmin"></p-checkbox> -->
            </div>
            <div class="d-flex flex-column align-items-center mx-3">
                <div class="d-flex flex-row align-items-center w-100 justify-content-start">
                    <label class="mx-2 my-0">{{ callType }}:</label>
                    <div class="">
                        <span class="font-weight-bolder">{{ ToFillNumber }}</span>
                        <span class="font-weight-bolder"> - ({{ currentDialerName }})</span>
                    </div>
                </div>
                <div class="d-flex flex-row align-items-center w-100 justify-content-start" *ngIf="hideNextNumber">
                    <label class="mx-2 my-0">NEXT:</label>
                    <div class="">
                        <span class="font-weight-bolder">{{ ToNextNumber }}</span>
                        <span class="font-weight-bolder"> - ({{ nextDialerName }})</span>
                    </div>
                </div>
            </div>
            <div class="d-flex flex-row align-items-center">
                <div *ngIf="showCall === true" class="mx-2 d-flex flex-column">
                    <button pButton type="button" label="Call" icon="pi pi-phone" class="p-button-outlined p-button-success" (click)="callCustomer()" #autoTrigger></button>
                </div>
                <div *ngIf="showStop === true" class="mx-2 d-flex flex-column">
                    <button pButton type="button" label="Hang Up" icon="pi pi-power-off" class="p-button-outlined p-button-danger" (click)="Stop()"></button>
                </div>
                <div class="" *ngIf="showStop === true">
                    <p-dropdown
                        name="selectedVoice"
                        [options]="voiceMailList"
                        [(ngModel)]="selectedVoice"
                        optionLabel="name"
                        optionValue="code"
                        filterBy="name"
                        [showClear]="false"
                        (onChange)="selectVoiceMail($event)"
                        [filter]="true"
                        styleClass="dailer_dropdown"
                        dataKey="name"
                        placeholder="Select Voice mail"
                    ></p-dropdown>
                </div>
                <div *ngIf="showStop === true" class="mx-2 d-flex flex-column">
                    <button
                        pButton
                        type="button"
                        label="send voicemail"
                        icon="pi pi-volume-up"
                        class="p-button-outlined p-button-primary"
                        (click)="sendVoiceMail()"
                        [disabled]="enableVoiceMail"
                    ></button>
                </div>
                <div class="mx-2 d-flex flex-column" *ngIf="showNext === true">
                    <button pButton type="button" label="Next" icon="pi pi-phone" class="p-button-outlined p-button-success" (click)="nextCall()"></button>
                </div>
            </div>
        </div>
        <div>
            <button *ngIf="showDialPadIcon" class="btn btn-outline-success mx-2" style="padding: .55rem 1rem; border:1px solid #28a745" (click)="showDialerPad()"><img src="./assets/media/svg/dial-pad.svg" /></button>
            <button *ngIf="showPendingCall" pButton type="button" icon="pi pi-phone" label="View Pending Queues" class="p-button-outlined p-button-success mx-2" (click)="viewPendingCalls()"></button>
            <button *ngIf="exitDialer" pButton type="button" icon="pi pi-sign-out" label="Exit Dialer" class="p-button-outlined p-button-danger" (click)="exitDialerBar()"></button>
        </div>
    </div>
</div>

<p-sidebar [(visible)]="filterPanel" position="right" [style]="{ width: '400px' }">
    <p-table [value]="getPendingCalls" responsiveLayout="scroll">
        <ng-template pTemplate="header">
            <tr>
                <th>Name</th>
                <th>Phone</th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-data>
            <tr>
                <td>{{ data.firstName }} {{ data.lastName }}</td>
                <td>{{ data.cellPhone }}</td>
            </tr>
        </ng-template>
    </p-table>
</p-sidebar>

<div *ngIf="loading" class="spinner-view">
    <app-inline-loader></app-inline-loader>
</div>

<p-confirmDialog appendTo="body" key="enableMicroPhone" [style]="{ width: '50vw' }" [baseZIndex]="10000"></p-confirmDialog>


<!-- Dialpad -->
<div class="dial-pad-view row" *ngIf="showDialPad">
    <div class="col-12 header-dial d-flex align-items-center">
        <button (click)="hideDialPad()"><i class="pi pi-times"></i></button>
    </div>
    <div class="col-12">
    <div class="grid col-12 px-0 mx-0 py-1">
        <div class="col-4 py-1"> <button type="button" class="btn btn-primary my-1 w-100 dial-btn" (click)="sendDigits(1)">1</button></div>
        <div class="col-4 py-1"> <button type="button" class="btn btn-primary my-1 w-100 dial-btn" (click)="sendDigits(2)">2</button></div>
        <div class="col-4 py-1"> <button type="button" class="btn btn-primary my-1 w-100 dial-btn" (click)="sendDigits(3)">3</button></div>
    </div>
    <div class="grid col-12 px-0 mx-0 py-1">
        <div class="col-4 py-1"><button type="button" class="btn btn-primary my-1 w-100 dial-btn" (click)="sendDigits(4)">4</button></div>
        <div class="col-4 py-1"><button type="button" class="btn btn-primary my-1 w-100 dial-btn" (click)="sendDigits(5)">5</button></div>
        <div class="col-4 py-1"><button type="button" class="btn btn-primary my-1 w-100 dial-btn" (click)="sendDigits(6)">6</button></div>
    </div>
    <div class="grid col-12 px-0 mx-0 py-1">
        <div class="col-4 py-1"><button type="button" class="btn btn-primary my-1 w-100 dial-btn" (click)="sendDigits(7)">7</button></div>
        <div class="col-4 py-1"><button type="button" class="btn btn-primary my-1 w-100 dial-btn" (click)="sendDigits(8)">8</button></div>
        <div class="col-4 py-1"><button type="button" class="btn btn-primary my-1 w-100 dial-btn" (click)="sendDigits(9)">9</button></div>
    </div>
    <div class="grid col-12 px-0 mx-0 py-1">
        <div class="col-4 py-1"><button type="button" class="btn btn-primary my-1  w-100 dial-btn" (click)="sendDigits('*')">*</button></div>
        <div class="col-4 py-1"><button type="button" class="btn btn-primary my-1 w-100 dial-btn" (click)="sendDigits(0)">0</button></div>
        <div class="col-4 py-1"><button type="button" class="btn btn-primary my-1 w-100 dial-btn" (click)="sendDigits('#')">#</button></div>
    </div>
</div>

</div>

<p-dialog header="Dialer Error Message" [(visible)]="display"[modal]="true" [style]="{ width: '50vw' }" [baseZIndex]="10000" [draggable]="false" [resizable]="false" (onHide)="cancel()">
    <h4 class="py-3 text-center">{{errorMessage}}</h4>
</p-dialog>
