import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class VoiceMailUpdateService {
    private subjectName = new Subject<any>();

    sendVoiceMailUpdate(message: boolean) {
        this.subjectName.next({ text: message });
    }

    getVoiceMailUpdate(): Observable<any> {
        return this.subjectName.asObservable();
    }
}
