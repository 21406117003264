<ng-container>
    <div class="container-fluid px-0">
        <div>
            <div class="p-0 card card-custom col-xl-12">
                <div>
                    <p-table #dt [value]="medicareList" [rows]="10" [paginator]="true" [rowHover]="true" dataKey="id"
                        [loading]="loading"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                        [showCurrentPageReport]="true"
                        [globalFilterFields]="['ssn', 'medicareNumber', 'partA', 'partB']">
                        <ng-template pTemplate="caption">
                            <div class="d-flex justify-content-between align-items-center">
                                <h5 class="p-m-0 d-inline-block">Manage User Medicare</h5>
                                <div class="d-flex align-items-center">
                                    <span class="p-input-icon-left">
                                        <i class="pi pi-search"></i>
                                        <input pInputText type="text"
                                            (input)="dt.filterGlobal($event.target.value, 'contains')"
                                            placeholder="Search..." class="form-control h-auto py-2 px-6 rounded-lg" />
                                    </span>
                                    <i class="pi pi-plus-circle gray-icon mx-2 plus-medium"
                                        (click)="createMedicare()"></i>
                                </div>
                            </div>
                        </ng-template>
                        <ng-template pTemplate="header">
                            <tr>
                                <th width="20%">SSN</th>
                                <th width="20%">Medicare Number</th>
                                <th width="20%">Part A</th>
                                <th width="20%">Part B</th>
                                <th width="20%">Action</th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-data>
                            <tr class="p-selectable-row">
                                <td>
                                    {{ data.ssn | mask: '000-00-0000' }}
                                </td>
                                <td>
                                    {{ data.medicareNumber }}
                                </td>
                                <td>
                                    {{ data.partA | date: 'MM-dd-yyyy' }}
                                </td>
                                <td>
                                    {{ data.partB | date: 'MM-dd-yyyy' }}
                                </td>
                                <td>
                                    <i class="pi pi-pencil blue-icon mx-2" (click)="editUserMedicare(data)"></i>
                                    <i class="pi pi-trash red-icon" (click)="confirmDelete(data.id)"></i>
                                </td>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="emptymessage">
                            <tr>
                                <td colspan="5" class="text-center">No medicare found.</td>
                            </tr>
                        </ng-template>
                    </p-table>
                </div>
            </div>
        </div>
    </div>
</ng-container>

<div *ngIf="showModal">
    <p-dialog header="Header" (onHide)="cancel()" [(visible)]="display" [modal]="true" [style]="{ width: '50vw' }"
        [baseZIndex]="10000" [draggable]="false" [resizable]="false">
        <ng-template pTemplate="header" class="py-3">
            <h3 class="m-0">{{ ModalTitle }} Medicare</h3>
        </ng-template>
        <form (ngSubmit)="(f.form.valid)" #f="ngForm" novalidate class="w-100 mx-auto relative">
            <div class="row mx-0">
                <div class="col-6">
                    <div class="form-group">
                        <label for="ssn" class="text-dark">SSN <span>*</span></label>

                        <input maxlength="11" type="text" name="ssn" mask="000-00-0000" [(ngModel)]="selectedRow.ssn"
                            #name="ngModel" class="form-control form-control-lg form-control-solid" />
                    </div>
                </div>
                <div class="col-6">
                    <div class="form-group">
                        <label for="medicare" class="text-dark">Medicare Number</label>
                        <input type="text" name="medicare" [(ngModel)]="selectedRow.medicareNumber" #name="ngModel"
                            class="form-control form-control-lg form-control-solid" maxlength="50" />
                    </div>
                </div>
            </div>
            <div class="row w-100 mx-0 my-2">
                <div class="col-6">
                    <div class="form-group">
                        <label for="partA" class="text-dark block">part A</label>
                        <p-calendar appendTo="body" name="partA" [showIcon]="true" inputId="icon"
                            [(ngModel)]="selectedRow.partA" #name="ngModel" dateFormat="mm/dd/yy"></p-calendar>
                    </div>
                </div>
                <div class="col-6">
                    <div class="form-group">
                        <label for="partB" class="text-dark block">part B</label>
                        <p-calendar appendTo="body" name="partB" [showIcon]="true" inputId="icon"
                            [(ngModel)]="selectedRow.partB" #name="ngModel" dateFormat="mm/dd/yy"></p-calendar>
                    </div>
                </div>
            </div>
            <div class="mt-5 mb-2 d-flex flex-row justify-content-end">
                <button pButton pRipple label="Cancel" icon="pi pi-times" class="p-button-text text-secondary"
                    (click)="cancel()"></button>
                <button pButton pRipple [disabled]="!f.valid" label="{{ BtnTitle }}" icon="pi pi-check"
                    class="p-button-text" (click)="submitMedicare()"></button>
            </div>
        </form>
        <div *ngIf="showLoader" class="spinner-view">
            <app-inline-loader></app-inline-loader>
        </div>
    </p-dialog>
</div>
