/* eslint-disable @typescript-eslint/no-unsafe-call */
import { Injectable } from '@angular/core';
import { agentFilerModel } from '@app/calendar/model/agent-model';
import { ClientData, ClientViewModel } from '@app/client/model/client-view.model';
import { LeadStatusGroupViewModel } from '@app/lead/model/lead-status-group-view.model';
import { BaseLookupModel } from '@app/shared/models/base-look-up.model';
import { CountyModel } from '@app/user-settings/models/county-model';
import { LuPhoneTypeModel } from '@app/user-settings/models/lu-phone-type-model';
import { StateModel } from '@app/user-settings/models/state-model';
import { TagViewModel } from '@app/user-settings/models/user-tags-model';
import { environment } from '@environments/environment';
import { CheckModel } from '@shared/authentication/model/check-model';
import { HttpUtility } from '@utility/httpUtility';
import { Observable } from 'rxjs';
import { ClientCreateModel } from '../model/client-add-model';
import { ClientFilterModel } from '../model/client-filter-model';
import { ClientStatusEditModel } from '../model/client-status-edit-model';
import { Cacheable } from 'ts-cacheable';
@Injectable({ providedIn: 'root' })
export class ClientService {
    constructor(private httpUtility: HttpUtility) {}
    @Cacheable()
    getStates(): Observable<StateModel[]> {
        return this.httpUtility.get(`${environment.apiUrl}/states`);
    }

    @Cacheable()
    getCounties(id: string): Observable<CountyModel[]> {
        return this.httpUtility.get(`${environment.apiUrl}/counties?stateId=${id}`);
    }
    @Cacheable()
    getPhoneTypes(): Observable<LuPhoneTypeModel[]> {
        return this.httpUtility.get(`${environment.apiUrl}/lu-phone-types`);
    }
    getAllClients(getClientsList): Observable<ClientData> {
        return this.httpUtility.post(`${environment.apiUrl}/clients`, getClientsList);
    }

    addClient(addClient: ClientCreateModel): Observable<BaseLookupModel> {
        return this.httpUtility.post(`${environment.apiUrl}/clients/add`, addClient);
    }

    deleteClient(deleteClient: string[]): Observable<boolean> {
        return this.httpUtility.delete(`${environment.apiUrl}/clients`, deleteClient);
    }

    getAllUserNotes(Id: number) {
        return this.httpUtility.get(`${environment.apiUrl}/user/${Id}/user-notes`);
    }

    getLeadSource(): Observable<BaseLookupModel[]> {
        return this.httpUtility.get(`${environment.apiUrl}/lu-lead-sources`);
    }

    getAgentList(): Observable<BaseLookupModel[]> {
        return this.httpUtility.get(`${environment.apiUrl}/users/agent-lookup`);
    }

    leadStatusGroup(): Observable<LeadStatusGroupViewModel[]> {
        return this.httpUtility.get(`${environment.apiUrl}/lead-status-groups`);
    }

    getUserByEmail(email: string): Observable<CheckModel> {
        return this.httpUtility.get<CheckModel>(`${environment.apiUrl}/users/verify-email?email=${email}`);
    }

    getAllAgents() {
        return this.httpUtility.get<agentFilerModel[]>(`${environment.apiUrl}/users/agent-lookup`);
    }

    getTags(): Observable<TagViewModel[]> {
        return this.httpUtility.get<TagViewModel[]>(`${environment.apiUrl}/tags`);
    }

    getFilterClients(clientFilter: ClientFilterModel): Observable<ClientData> {
        return this.httpUtility.post(`${environment.apiUrl}/clients`, clientFilter);
    }

    getClientDetailsById(id: string): Observable<ClientViewModel> {
        return this.httpUtility.get(`${environment.apiUrl}/clients/${id}`);
    }

    updateLeadStatusDetails(leadStatusEditModel: ClientStatusEditModel) {
        return this.httpUtility.post(`${environment.apiUrl}/user/${leadStatusEditModel.userId}/user-agents`, leadStatusEditModel);
    }

    updateClient(updateModel, id: string) {
        return this.httpUtility.put(`${environment.apiUrl}/clients/${id}`, { ...updateModel });
    }

    getLeadTags() {
        return this.httpUtility.get<any[]>(`${environment.apiUrl}/tags`);
    }

    getUserLeadTags(id: string) {
        return this.httpUtility.get<any[]>(`${environment.apiUrl}/users/${id}/user-tags`);
    }

    addLeadTag(userId: string, tagId: string) {
        return this.httpUtility.post(`${environment.apiUrl}/users/${userId}/user-tags?tagId=${tagId}`, '');
    }

    deleteLeadTag(userId: string, tagId: string) {
        return this.httpUtility.delete(`${environment.apiUrl}/users/${userId}/user-tags/${tagId}`);
    }

    getCallQueues() {
        return this.httpUtility.get<any[]>(`${environment.apiUrl}/callqueue`);
    }

    addCallQueue(userId: string, calId: string) {
        return this.httpUtility.post(`${environment.apiUrl}/users/${userId}/user-call-queues?callQueueId=${calId}`, '');
    }

    getUserCallQueue(id: string) {
        return this.httpUtility.get<any[]>(`${environment.apiUrl}/users/${id}/user-call-queues`);
    }

    deleteLeadCall(userId: string, calId: string) {
        return this.httpUtility.delete(`${environment.apiUrl}/users/${userId}/user-call-queues/${calId}`);
    }

    checkClientExist(payload) {
        return this.httpUtility.post(`${environment.apiUrl}/clients/duplicates`, payload);
    }

    restoreClient(id: string) {
        return this.httpUtility.post(`${environment.apiUrl}/clients/${id}/restore`, {});
    }

    convertToLead(id: string) {
        return this.httpUtility.put(`${environment.apiUrl}/leads/${id}/convert-to-lead`, {});
    }
}
