/* eslint-disable @typescript-eslint/unbound-method */
import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { DialerContentService } from '@app/shared/services/dialer-content-service';
import { LuAddressTypeModel } from '@app/user-settings/models/address-type-model';
import { CountyModel } from '@app/user-settings/models/county-model';
import { StateModel } from '@app/user-settings/models/state-model';
import { UserAddressCreateModel, UserAddressViewModel } from '@app/user-settings/models/user-addresses-model';
import { AddressTypesService } from '@app/user-settings/services/addressesTypes-service';
import { CountyService } from '@app/user-settings/services/county-service';
import { StateService } from '@app/user-settings/services/state-service';
import { UserAdressService } from '@app/user-settings/services/user-address.service';
import { UserModel } from '@app/users/models/user-model';
import { BaseComponentDirective } from '@core/component/base.component';
import { sortBy } from 'lodash';
import { Subscription, takeUntil } from 'rxjs';

@Component({
    selector: 'app-lead-address',
    templateUrl: './lead-address.component.html',
    styleUrls: ['./lead-address.component.scss'],
})
export class LeadAddressComponent extends BaseComponentDirective implements OnInit {
    @Input() leadId: string;
    userId: string;
    user: UserModel;
    userAddressViewModel: UserAddressViewModel;
    userAddressViewModelDetails: UserAddressViewModel[];
    editAddressesDialog: boolean;
    formGroup: UntypedFormGroup;
    editFormGroup: UntypedFormGroup;
    counties: CountyModel[];
    states: StateModel[];
    statePrimary: StateModel;
    stateSecondary: StateModel;
    selectedState: StateModel;
    selectedCounty: CountyModel;
    userAddressCreateModel: UserAddressCreateModel;
    selectedAddressType: string;
    addressTypes: LuAddressTypeModel[];
    deleteAddressDialog: boolean;
    loading: boolean;
    dialerSubscribe: Subscription;
    constructor(
        private readonly userAdressService: UserAdressService,
        private formBuilder: UntypedFormBuilder,
        private readonly stateService: StateService,
        private readonly countyService: CountyService,
        private readonly addressTypesService: AddressTypesService,
        private dialerContentService: DialerContentService,
        private router: Router,
    ) {
        super();
        this.dialerSubscribe = this.dialerContentService.getUpdate().subscribe((message) => {
            this.leadId = message.text;
            this.ngOnInit();
            const currentUrl = this.router.url;
            void this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
                void this.router.navigate([currentUrl]);
            });
        });
    }

    ngOnInit(): void {
        super.ngOnInit();
        this.getUserAddressess();
        this.loadForm();
        this.getluStatesService();
        // this.getAddressesTypesByIdService();
        this.getAddressesTypes();
    }

    getUserAddressess(): void {
        this.loading = true;
        this.userAdressService.getaddressesById(this.leadId).subscribe((result) => {
            this.userAddressViewModelDetails = result;
            this.loading = false;
        });
    }

    openEdit(id: string) {
        this.editAddressesDialog = true;
        this.formGroup.controls.stateId.touched;
        this.userAddressViewModelDetails.forEach((userAddressValue) => {
            if (userAddressValue.id === id) {
                if (userAddressValue.address.state) {
                    this.countyService.getCounties(userAddressValue.address.state.id).subscribe((result) => {
                        this.counties = result;
                    });
                }
                this.formGroup.patchValue({
                    address1: userAddressValue.address.address1,
                    address2: userAddressValue.address.address2,
                    city: userAddressValue.address.city,
                    postalCode: userAddressValue.address.postalCode,
                    addressTypeId: userAddressValue.addressType,
                    countyId: userAddressValue.address.county,
                });
                this.selectedState = {
                    id: userAddressValue?.address?.state.id,
                    name: `${userAddressValue?.address?.state.name} (${userAddressValue?.address?.state.code})`,
                    code: userAddressValue?.address?.state.code,
                };
                this.selectedCounty = userAddressValue?.address?.county;
                this.selectedAddressType = userAddressValue.addressType.id;
            }
        });
    }

    hideDialog() {
        this.editAddressesDialog = false;
    }

    getAddressFormValue() {
        if (
            this.formGroup.get('address1').value ||
            (this.formGroup.get('city').value || null) || 
            this.formGroup.get('stateId').value.id ||
            this.formGroup.get('countyId').value.id ||
            this.formGroup.get('postalCode').value
        ) {
            return {
                address1: this.formGroup.get('address1').value as string,
                address2: this.formGroup.get('address2').value as string,
                city: this.formGroup.get('city').value as string,
                stateId: this.formGroup.get('stateId').value ? this.formGroup.get('stateId').value.id : null,
                countyId: this.formGroup.get('countyId').value ? this.formGroup.get('countyId').value.id : null,
                postalCode: this.formGroup.get('postalCode').value as string,
            };
        } else {
            return null;
        }
    }

    saveEditedAddress() {
        if (this.formGroup.valid) {
            this.loading = true;
            const userAddressViewModel: UserAddressCreateModel = {
                userId: this.leadId,
                addressId: this.leadId,
                addressTypeId: this.formGroup.value.addressTypeId,
                address: this.getAddressFormValue(),
            };
            if (this.userAddressViewModelDetails.length === 0) {
                this.userAdressService.addAddresses(userAddressViewModel, this.leadId).subscribe({
                    next: () => {
                        window.scrollTo(0, 0);
                        this.messageService.add({
                            key: 'tst',
                            severity: 'success',
                            summary: 'Success',
                            detail: 'Added new address successfully',
                        });
                        this.editAddressesDialog = false;
                        this.getUserAddressess();
                    },
                    error: () => {
                        this.messageService.add({
                            key: 'tst',
                            severity: 'error',
                            summary: 'Error',
                            detail: 'Error in adding new address',
                        });
                        window.scrollTo(0, 0);
                        this.loading = false;
                    },
                });
            } else {
                this.userAdressService.editaddresses(userAddressViewModel, this.leadId).subscribe({
                    next: () => {
                        window.scrollTo(0, 0);
                        this.messageService.add({
                            key: 'tst',
                            severity: 'success',
                            summary: 'Success',
                            detail: 'Updated address successfully',
                        });
                        this.editAddressesDialog = false;
                        this.getUserAddressess();
                    },
                    error: () => {
                        this.messageService.add({
                            key: 'tst',
                            severity: 'error',
                            summary: 'Error',
                            detail: 'Error in adding new address',
                        });
                        window.scrollTo(0, 0);
                        this.loading = false;
                    },
                });
            }
        }
    }

    loadForm(): void {
        this.formGroup = this.formBuilder.group({
            address1: [''],
            address2: [''],
            city: [''],
            stateId: [],
            countyId: [],
            postalCode: [''],
            addressTypeId: ['', Validators.required],
        });
    }

    getCountiessService(): void {
        this.countyService.getCounties(this.formGroup.value.stateId.id).subscribe((result) => {
            this.counties = sortBy(result, (o) => {
                return o.name;
            });
        });
    }

    getluStatesService(): void {
        this.states = [];
        this.stateService
            .getStates()
            .pipe(takeUntil(this.onDestroy$))
            .subscribe((result) => {
                const sortedArray = sortBy(result, (o) => {
                    return o.name;
                });
                sortedArray.forEach((element) => {
                    this.states.push({
                        name: `${element.name} (${element.code})`,
                        code: element.code,
                        id: element.id,
                    });
                });
            });
    }

    getAddressesTypes() {
        this.addressTypesService.getAddressesTypes().subscribe((result) => {
            this.addressTypes = sortBy(result, (o) => {
                return o.description;
            });
        });
    }
    isControlValid(controlName: string): boolean {
        const control = this.formGroup.controls[controlName];
        return control.valid && (control.dirty || control.touched);
    }

    isControlInvalid(controlName: string): boolean {
        const control = this.formGroup.controls[controlName];
        return control.invalid && (control.dirty || control.touched);
    }
}
