import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '@app/authentication/services/authentication.service';
import BulkActionsEnum from '@app/shared/bulk-actions/bulk-actions.enum';
import { BulkActionsService } from '@app/shared/bulk-actions/bulk-actions.service';
import { BaseComponentDirective } from '@core/component/base.component';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
    selector: 'app-bulk-add-notes',
    templateUrl: './bulk-add-notes.component.html',
})
export class BulkAddNotesComponent extends BaseComponentDirective implements OnInit {
    selectedList: Array<string>;
    submitted = false;
    loading = false;
    getUserId = this.authService.getLoggedInId();
    addNote = '';

    constructor(public config: DynamicDialogConfig, private authService: AuthenticationService, private bulkActionsService: BulkActionsService, public ref: DynamicDialogRef) {
        super();
    }

    ngOnInit() {
        super.ngOnInit();
        this.selectedList = this.config.data.selectedList as string[];
    }

    onSubmit() {
        if (!this.addNote) {
            this.loading = false;
            return;
        }
        this.loading = true;
        this.bulkActionsService
            .bulkActions(this.getUserId, {
                action: BulkActionsEnum.AddNotes,
                userIds: this.selectedList,
                description: this.addNote,
            })
            .subscribe({
                next: () => {
                    this.addNote = '';
                    this.loading = false;
                    this.messageService.add({
                        key: 'tst',
                        severity: 'success',
                        summary: 'Success',
                        detail: 'Notes added successfully',
                    });
                    this.ref.close();
                },
                error: () => {
                    this.loading = false;
                },
            });
    }
}
