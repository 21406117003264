/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from '@app/authentication/services/authentication.service';
import { TokenRefreshService } from '@app/authentication/services/token-refresh.service';
import { LeadViewModel } from '@app/lead/model/lead-view.model';
import { DialerContentService } from '@app/shared/services/dialer-content-service';
import { DialerState, DialerStateService } from '@app/shared/services/dialer-service';
import { UserPhoneDialerViewModel } from '@app/user-settings/models/phone-model';
import { BaseComponentDirective } from '@core/component/base.component';
import { VoiceMailUpdateService } from '@shared/services/voice-mailer-update.services';
import { Call, Device } from '@twilio/voice-sdk';
import { map, Subscription, takeUntil, timer } from 'rxjs';
import { VoiceMailModel } from '../model/voice-mail.model';
import { DialerService } from '../services/dialer-services';
import { environment } from '@environments/environment';
import { PhoneCallService } from '@app/communication/services/phone-call-service';
@Component({
    selector: 'app-dialer-bar',
    templateUrl: './dialer-bar.component.html',
    styleUrls: ['./dialer-bar.component.scss'],
})
export class DialerBarComponent extends BaseComponentDirective implements OnInit, OnDestroy {
    @ViewChild('autoTrigger') autoTrigger: ElementRef;
    toPhoneNumber = '';
    fromPhoneNumber = '';
    connection: any = null;
    device: Device;
    showCall = true;
    showStop = false;
    exitDialer = true;
    agentUser: string;
    getUserId: string;
    enableDialer = false;
    showDialerOptions = false;
    showSingleCallOptions = false;
    showMultiCallOptions = false;
    updatedFromNum: string;
    ToFillNumber: string;
    ToNextNumber: string;
    currentDialerName: string;
    nextDialerName: string;
    singleCallerId: string;
    enableVoiceMail = true;
    dialerLaunchMsg: boolean;
    showNext: boolean;
    phonesList = [];
    voiceMailList = [];
    selectedPhone;
    selectedVoice: string;
    arrayList = [];
    tokenSubscribe: Subscription;
    filterPanel: boolean;
    showPendingCall: boolean;

    callType = 'CALLING';
    hideNextNumber = false;
    getPendingCalls = [];
    showEveryLead: boolean;
    loading: boolean;
    fromNumberArray = [];
    isAdmin: boolean;
    showDialPad: boolean;
    showDialPadIcon: boolean;
    currentCall: Call;
    display = false;
    errorMessage = '';
    private currentCallSid: string;
    private token: string;
    private deviceOptions: Device.Options = {
        edge: 'roaming',
        sounds: {
            outgoing: environment.outgoingRingTone,
        },
        logLevel: 'debug',
        closeProtection: true,
        tokenRefreshMs: 5000,
        maxCallSignalingTimeoutMs: 30000,
        disableAudioContextSounds: true,
    };
    constructor(
        private dialerService: DialerService,
        private authService: AuthenticationService,
        private dialerStateService: DialerStateService,
        private readonly phoneCallService: PhoneCallService,
        private dialerContentService: DialerContentService,
        private router: Router,
        private readonly tokenRefreshService: TokenRefreshService,
        private voiceMailUpdateService: VoiceMailUpdateService,
    ) {
        super();
    }

    ngOnInit() {
        super.ngOnInit();
        this.getUserId = this.authService.getLoggedInId();
        this.getPhonesList();
        this.setupDevice();
        this.getVoiceMailLists();
        this.isAdmin = this.authService.isAdmin();
        const getPendingCallsQue = JSON.parse(localStorage.getItem('Launch_Dialer')) as LeadViewModel[];
        if (getPendingCallsQue.length > 1) {
            this.showPendingCall = true;
        }
    }
    cancel() {
        this.display = false;
    }

    hideDialPad() {
        this.showDialPad = false;
        this.showDialPadIcon = true;
    }

    showDialerPad() {
        this.showDialPad = true;
        this.showDialPadIcon = false;
    }
    getLeadsPhoneList(ToNum: string) {
        this.dialerService
            .getLeadDailer(false, this.authService.loggedInUser().Roles.includes('Pre-Call Manager'))
            .pipe(takeUntil(this.onDestroy$))
            .subscribe({
                next: (userPhoneDialer: UserPhoneDialerViewModel[]) => {
                    this.toPhoneNumber = ToNum;
                    this.phonesList = [];
                    userPhoneDialer.forEach((element) => {
                        this.phonesList.push({
                            name: `${element.friendlyName} (${this.transformMobileNum(element.phoneNumber)})`,
                            code: element.id,
                            // eslint-disable-next-line id-blacklist
                            number: this.transformMobileNum(element.phoneNumber),
                        });
                    });
                    if (userPhoneDialer.length === 0) {
                        this.enableDialer = true;
                        this.showDialerOptions = false;
                    } else {
                        this.enableDialer = false;
                        this.fromPhoneNumber = this.transformMobileNum(userPhoneDialer[0].phoneNumber);
                        if (userPhoneDialer.length === 1) {
                            this.showSingleCallOptions = true;
                            this.showMultiCallOptions = false;
                            this.singleCallerId = this.transformMobileNum(userPhoneDialer[0].phoneNumber);
                            this.fromNumberArray = userPhoneDialer;
                        } else {
                            this.showSingleCallOptions = false;
                            this.showMultiCallOptions = true;
                        }
                    }
                },
                error: () => {
                    this.messageService.add({
                        key: 'tst',
                        severity: 'error',
                        summary: 'Error',
                        detail: 'Something went wrong, please try again',
                    });
                },
            });
    }

    getVoiceMailLists() {
        this.dialerService
            .getVoiceMailList()
            .pipe(takeUntil(this.onDestroy$))
            .subscribe({
                next: (res) => {
                    this.voiceMailList = [];
                    res.forEach((element) => {
                        this.voiceMailList.push({
                            name: element.name,
                            code: element.id,
                        });
                    });
                },
                error: () => {
                    this.messageService.add({
                        key: 'tst',
                        severity: 'error',
                        summary: 'Error',
                        detail: 'Unable to load voice mail',
                    });
                },
            });
    }

    getPhonesList() {
        const getLatestLead = JSON.parse(localStorage.getItem('Launch_Dialer')) as LeadViewModel[];
        // if (getLatestLead && getLatestLead.length) {
        this.dialerLaunchMsg = true;
        this.dialerService.getUserPhones(getLatestLead[0].id).subscribe({
            next: (res) => {
                this.getLeadsPhoneList(this.transformMobileNum(res[0].number.toString()));
                this.currentDialerName = `${getLatestLead[0].firstName} ${getLatestLead[0].lastName}`;
                if (getLatestLead.length > 1) {
                    this.hideNextNumber = true;
                    this.nextDialerName = `${getLatestLead[1].firstName} ${getLatestLead[1].lastName}`;
                } else {
                    this.hideNextNumber = false;
                }
                if (localStorage.getItem('callType') === null) {
                    this.ToFillNumber = this.transformMobileNum(getLatestLead[0].cellPhone || getLatestLead[0].workPhone || getLatestLead[0].homePhone);
                    if (getLatestLead.length > 1) {
                        this.ToNextNumber = this.transformMobileNum(getLatestLead[1].cellPhone || getLatestLead[1].workPhone || getLatestLead[1].homePhone);
                    }
                } else {
                    if (localStorage.getItem('callType') === 'cellPhone') {
                        this.ToFillNumber = this.transformMobileNum(getLatestLead[0].cellPhone);
                    } else if (localStorage.getItem('callType') === 'workPhone') {
                        this.ToFillNumber = this.transformMobileNum(getLatestLead[0].workPhone);
                    } else if (localStorage.getItem('callType') === 'homePhone') {
                        this.ToFillNumber = this.transformMobileNum(getLatestLead[0].homePhone);
                    }
                }
                this.dialerLaunchMsg = false;
                this.showDialerOptions = true;
            },
            error: () => {
                this.messageService.add({
                    key: 'tst',
                    severity: 'error',
                    summary: 'Error',
                    detail: 'Something went wrong, please try again',
                });
            },
        });
        // }
    }

    setupDevice(): void {
        this.dialerService.getToken().subscribe((result: string) => {
            this.token = result;

            this.device = new Device(this.token, this.deviceOptions);
            this.setupHandlers();
            // this.device.register().then(() => {});
        });
    }

    setupHandlers() {
        this.device.on('registered', () => {
            console.log('The device is ready to receive incoming calls.', new Date());
        });

        this.device.on('error', (error: Error) => {
            // this.updateCallStatus(`ERROR: ${error.message}`);
            this.updateCallStatus(`ERROR: ${error.message}`);
            console.log(`this.device Error: ${error.message}`, this, new Date());
            // invalid phone number
            // setTimeout(() => {
            //     console.log('after error', this.currentCall.parameters.CallSid);
            //     this.getCallStatus(this.currentCall.parameters.CallSid);
            // }, 8000);
        });

        this.device.on('incoming', () => {
            console.log('Getting incoming call', new Date());
        });
        this.device.on('destroyed', () => {
            console.log('The device is destroyed', new Date());
        });
        this.device.on('unregistered', () => {
            console.log('The device is unregistered.', new Date());
        });
        this.device.on('registering', () => {
            console.log('The device is registering.', new Date());
        });
        this.device.on('tokenWillExpire', () => {
            console.log('The device will expire.', new Date());
        });
    }
    updateCallStatus(status: string): void {
        console.log('Status -> ', status);
    }

    sendDigits(input: string | number): void {
        this.currentCall.sendDigits(input.toString());
    }

    sendVoiceMail(): void {
        this.dialerService.sendVoiceMail(new VoiceMailModel({ callSid: this.currentCallSid, voiceGreetingId: this.selectedVoice })).subscribe(() => {
            // this.showStop = false;
            // this.showCall = true;
            // this.exitDialer = false;
            // this.showPendingCall = false;
            this.Stop();
            this.voiceMailUpdateService.sendVoiceMailUpdate(true);
        });
    }

    callCustomer() {
        let ToNumber = '';
        const getLatestLead = JSON.parse(localStorage.getItem('Launch_Dialer')) as LeadViewModel[];
        this.updateCallStatus(`Calling ${getLatestLead[0].cellPhone || getLatestLead[0].workPhone || getLatestLead[0].homePhone}...`);
        if (localStorage.getItem('callType') === null) {
            ToNumber = this.transformMobileNum(getLatestLead[0].cellPhone || getLatestLead[0].workPhone || getLatestLead[0].homePhone);
        } else {
            if (localStorage.getItem('callType') === 'cellPhone') {
                ToNumber = this.transformMobileNum(getLatestLead[0].cellPhone);
            } else if (localStorage.getItem('callType') === 'workPhone') {
                ToNumber = this.transformMobileNum(getLatestLead[0].workPhone);
            } else if (localStorage.getItem('callType') === 'homePhone') {
                ToNumber = this.transformMobileNum(getLatestLead[0].homePhone);
            }
        }

        if (localStorage.hasOwnProperty('DialerType')) {
            const getDilaerType = localStorage.getItem('DialerType');

            if (getDilaerType === 'Lead') {
                if (this.router.url === '/leads/lead-list') {
                    void this.router.navigate(['/leads/lead-details/', getLatestLead[0].id]);
                } else {
                    void this.router.navigate(['/leads/lead-details/', getLatestLead[0].id]);
                }

                if (this.router.url.includes('/leads/lead-details')) {
                    void this.router.navigate(['/leads/lead-details/', getLatestLead[0].id]);
                } else {
                    void this.router.navigate(['/leads/lead-details/', getLatestLead[0].id]);
                }
            } else {
                if (this.router.url === '/clients/client-list') {
                    void this.router.navigate(['/clients/client-details/', getLatestLead[0].id]);
                } else {
                    void this.router.navigate(['/clients/client-details/', getLatestLead[0].id]);
                }

                if (this.router.url.includes('/clients/client-details/')) {
                    void this.router.navigate(['/clients/client-details/', getLatestLead[0].id]);
                } else {
                    void this.router.navigate(['/clients/client-details/', getLatestLead[0].id]);
                }
            }
        }

        const params: Record<string, string> = {
            phoneNumber: ToNumber,
            callerId: this.fromPhoneNumber,
            agentUserId: this.getUserId,
            userId: getLatestLead[0].id,
        };

        const connectOptions: Device.ConnectOptions = { params };
        void this.device.connect(connectOptions).then((call: Call) => {
            this.currentCall = call;
            call.on('ringing', () => {
                console.log(`call parameters callSid: ${call.parameters.CallSid}`);
                console.log('To Number', params.phoneNumber);
                this.currentCallSid = call.parameters.CallSid;
                this.showStop = true;
                this.showCall = false;
                this.showNext = false;
                this.exitDialer = false;
                this.showPendingCall = false;
                this.showDialPad = true;
            });
            this.device.calls.forEach((a) => {
                return console.log(a.emit.toString());
            });
            call.on('closed', () => {
                console.log(`call closed:`);
            });
            call.on('connecting', () => {
                console.log(`call connecting:`);
            });
            call.on('open', () => {
                console.log(`call open:`);
            });
            call.on('pending', () => {
                console.log(`call pending:`);
            });
            call.on('reconnecting', () => {
                console.log(`call reconnecting:`);
            });
            call.on('disconnect', () => {
                console.log(`call disconnect:`, call);

                setTimeout(() => {
                    return this.phoneCallService
                        .callStatus(this.currentCallSid)
                        .pipe()
                        .subscribe({
                            next: (response) => {
                                console.log('response', response);
                                if (response.callStatus === 'failed') {
                                    this.messageService.add({
                                        key: 'tst',
                                        severity: 'error',
                                        summary: 'Error',
                                        detail: response.errorMessage,
                                    });
                                }
                            },
                            error: () => {
                                this.messageService.add({
                                    key: 'tst',
                                    severity: 'error',
                                    summary: 'Error',
                                    detail: 'Error in adding event.',
                                });
                            },
                            complete: () => {
                                this.Stop();
                            },
                        });
                }, 2000);
            });
        });

        this.device.calls.forEach((a) => {
            console.log(a);
        });

        this.tokenSubscribe = timer(0, 600000)
            .pipe(
                map(() => {
                    this.tokenRefreshService.getRefreshToken(this.getUserId).subscribe({
                        next: (bearertoken) => {
                            localStorage.setItem('token', JSON.stringify(bearertoken));
                        },
                    });
                }),
            )
            .subscribe();
    }
    Stop() {
        this.showDialPad = false;
        this.showDialPadIcon = false;
        this.tokenSubscribe.unsubscribe();
        setTimeout(() => {
            this.device.disconnectAll();
        }, 100);
        this.showStop = false;
        this.showCall = false;
        this.exitDialer = true;
        this.showPendingCall = true;
        this.enableVoiceMail = true;
        this.showNext = true;
        this.callType = 'LAST CALLED';
        const getLatestLocalObj = JSON.parse(localStorage.getItem('Launch_Dialer')) as LeadViewModel[];
        if (getLatestLocalObj.length === 1) {
            this.showNext = false;
            this.hideNextNumber = false;
            this.showPendingCall = false;
        }
        // this.tokenSubscribe.unsubscribe();
    }

    nextCall() {
        const getLatestLead = JSON.parse(localStorage.getItem('Launch_Dialer')) as LeadViewModel[];
        const getLatestObj = getLatestLead.slice(1);
        localStorage.setItem('Launch_Dialer', JSON.stringify(getLatestObj));
        this.selectedVoice = '';
        if (getLatestObj.length > 1) {
            this.ToNextNumber = this.transformMobileNum(getLatestObj[1].cellPhone || getLatestObj[1].workPhone || getLatestObj[1].homePhone);
            this.nextDialerName = `${getLatestObj[1].firstName} ${getLatestObj[1].lastName}`;
        }
        if (getLatestObj.length === 1) {
            this.hideNextNumber = false;
        } else {
            this.hideNextNumber = true;
        }
        this.callType = 'CALLING';
        if (getLatestObj.length) {
            if (localStorage.hasOwnProperty('DialerType')) {
                const getDilaerType = localStorage.getItem('DialerType');

                if (getDilaerType === 'Lead') {
                    if (this.router.url === '/leads/lead-list') {
                        void this.router.navigate(['/leads/lead-details/', getLatestObj[0].id]);
                    } else {
                        void this.router.navigate(['/leads/lead-details/', getLatestObj[0].id]);
                    }

                    if (this.router.url.includes('/leads/lead-details')) {
                        void this.router.navigate(['/leads/lead-details/', getLatestObj[0].id]);
                    } else {
                        void this.router.navigate(['/leads/lead-details/', getLatestObj[0].id]);
                    }
                } else {
                    if (this.router.url === '/clients/client-list') {
                        void this.router.navigate(['/clients/client-details/', getLatestObj[0].id]);
                    } else {
                        void this.router.navigate(['/clients/client-details/', getLatestObj[0].id]);
                    }

                    if (this.router.url.includes('/clients/client-details/')) {
                        void this.router.navigate(['/clients/client-details/', getLatestObj[0].id]);
                    } else {
                        void this.router.navigate(['/clients/client-details/', getLatestObj[0].id]);
                    }
                }
            }

            this.currentDialerName = `${getLatestLead[1].firstName} ${getLatestLead[1].lastName}`;
            this.dialerService.getUserPhones(getLatestObj[0].id).subscribe({
                next: () => {
                    this.ToFillNumber = this.transformMobileNum(getLatestObj[0].cellPhone || getLatestObj[0].workPhone || getLatestObj[0].homePhone);
                    this.dialerLaunchMsg = false;
                    this.dialerContentService.sendUpdate(getLatestObj[0].id);
                    this.callCustomer();
                },
                error: () => {
                    this.messageService.add({
                        key: 'tst',
                        severity: 'error',
                        summary: 'Error',
                        detail: 'Something went wrong, please try again',
                    });
                },
            });
        } else {
            this.showNext = false;
        }
    }

    disconnect(): void {
        this.tokenSubscribe.unsubscribe();
        void this.device.disconnectAll();
    }

    transformMobileNum(rawNum: string) {
        rawNum = `1${rawNum}`;

        const countryCodeStr = rawNum.slice(0, 1);
        const areaCodeStr = rawNum.slice(1, 4);
        const midSectionStr = rawNum.slice(4, 7);
        const lastSectionStr = rawNum.slice(7, 11);

        return `${countryCodeStr}-${areaCodeStr}-${midSectionStr}-${lastSectionStr}`;
    }

    selectFromNumber(e) {
        console.log(e);
        this.fromPhoneNumber = e.value.number as string;
    }

    selectVoiceMail(e) {
        if (this.selectedVoice === '') {
            this.enableVoiceMail = true;
        } else {
            this.enableVoiceMail = false;
            this.selectedVoice = e.value as string;
        }
    }
    exitDialerBar() {
        localStorage.removeItem('Launch_Dialer');
        this.dialerStateService.set(new DialerState({ showDialerBar: false }));
        this.destroyDevice();
        localStorage.removeItem('callType');
        localStorage.removeItem('DialerType');
    }

    viewPendingCalls() {
        this.filterPanel = true;
        const getLatestQue = JSON.parse(localStorage.getItem('Launch_Dialer')) as LeadViewModel[];
        this.getPendingCalls = getLatestQue.slice(1);
    }

    ngOnDestroy(): void {
        this.destroyDevice();
    }
    getCallStatus(callSid: string) {
        this.phoneCallService
            .callStatus(callSid)
            .pipe()
            .subscribe({
                next: (response) => {
                    console.log('response', response);
                    if (response.errorMessage && response.errorMessage === 'invalid phone number ') {
                        this.Stop();
                        this.display = true;
                        this.errorMessage = `${response.errorCode} - ${response.errorMessage}`;
                    }
                    // else if (!response.apiVersion || response.apiVersion.length < 0) {
                    //     console.log('call status response is null');
                    // } else {
                    //     this.Stop();
                    //     this.display = true;
                    //     let message = `Call error : There is a "possibility" that the phone number ${response.called} is invalid. Please check the number. `;

                    //     if (response.errorMessage && response.errorMessage.length > 0) {
                    //         message = `${message}. Error ${response.errorMessage}`;
                    //     }
                    //     this.errorMessage = message;
                    // }
                },
                error: () => {
                    this.messageService.add({
                        key: 'tst',
                        severity: 'error',
                        summary: 'Error',
                        detail: 'Error in adding event.',
                    });
                },
            });
    }

    // eslint-disable-next-line @typescript-eslint/member-ordering
    checkValue(event: any) {
        if (event.checked) {
            this.loading = true;
            this.dialerService
                .getLeadDailer(true, false)
                .pipe(takeUntil(this.onDestroy$))
                .subscribe({
                    next: (res: UserPhoneDialerViewModel[]) => {
                        this.phonesList = [];
                        res.forEach((element) => {
                            this.phonesList.push({
                                name: `${element.friendlyName} (${this.transformMobileNum(element.phoneNumber)})`,
                                code: element.id,
                                // eslint-disable-next-line id-blacklist
                                number: this.transformMobileNum(element.phoneNumber),
                            });
                        });
                        this.showSingleCallOptions = false;
                        this.showMultiCallOptions = true;
                        this.loading = false;
                        this.fromPhoneNumber = this.phonesList[0].number;
                    },
                    error: () => {
                        this.messageService.add({
                            key: 'tst',
                            severity: 'error',
                            summary: 'Error',
                            detail: 'Something went wrong, please try again',
                        });
                    },
                });
        } else {
            this.showSingleCallOptions = true;
            this.showMultiCallOptions = false;
            this.fromPhoneNumber = this.transformMobileNum(this.fromNumberArray[0].phoneNumber);
        }
    }
    private destroyDevice(): void {
        if (this.device) {
            this.device.disconnectAll();
            this.device.destroy();
        }
        if (this.currentCall) {
            this.currentCall = null;
        }
    }
}
