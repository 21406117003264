<div class="text-right button-align userInfoEdit">
    <i class="pi pi-pencil blue-icon" (click)="openEdit()"></i>
</div>

<div class="py-2 flex flex-column">
    <span class="text-truncate block"
        >Name: <span class="font-weight-bold font-16">{{ contactInfo?.firstName }} {{ contactInfo?.lastName }}</span></span
    >
    <span class="text-truncate block"
        >Status: <span class="font-weight-bold font-16">{{ contactInfo?.status }}</span></span
    >
    <span *ngIf="contactInfo?.dateOfBirth" class="text-truncate block"
        >Dob: <span class="font-weight-bold font-16">{{ contactInfo?.dateOfBirth | date }}</span></span
    >
</div>
<div class="py-2 flex flex-row align-items-center" *ngIf="contactInfo?.email !== ''">
    <span [ngStyle]="{ backgroundColor: 'var(--yellow-700)', color: 'white', borderRadius: '50px', padding: '2px 8px', fontSize: '13px' }" class="mr-2">E</span>
    <span class="text-truncate">{{ contactInfo?.email }}</span>
</div>

<p-dialog appendTo="body" [(visible)]="displayEditModal" [style]="{ width: '650px' }" [baseZIndex]="10000" [modal]="true">
    <ng-template pTemplate="header"> {{ type }} Details </ng-template>

    <ng-template pTemplate="content">
        <form [formGroup]="formGroup">
            <div class="card-body">
                <div class="form-group row">
                    <label class="col-xl-3 col-lg-3 col-form-label">First Name</label>
                    <div class="col-lg-6 col-xl-6">
                        <div class="input-group input-group-lg input-group-solid">
                            <input
                                type="text"
                                class="form-control form-control-lg form-control-solid"
                                placeholder="First Name"
                                formControlName="firstName"
                                [class.is-invalid]="isControlInvalid('firstName')"
                                [class.is-valid]="isControlValid('firstName')"
                                ngDefaultControl
                            />
                        </div>
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-xl-3 col-lg-3 col-form-label">Last Name</label>
                    <div class="col-lg-6 col-xl-6">
                        <div class="input-group input-group-lg input-group-solid">
                            <input
                                type="text"
                                class="form-control form-control-lg form-control-solid"
                                placeholder="Last Name"
                                formControlName="lastName"
                                [class.is-invalid]="isControlInvalid('lastName')"
                                [class.is-valid]="isControlValid('lastName')"
                                ngDefaultControl
                            />
                        </div>
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-xl-3 col-lg-3 col-form-label">Lead Source</label>
                    <div class="col-lg-6 col-xl-6">
                        <p-dropdown
                            name="leadList"
                            [options]="leadsList"
                            formControlName="leadSource"
                            placeholder="Choose Lead Source"
                            styleClass="w-100 input-group input-group-lg input-group-solid"
                            [filter]="true"
                        >
                        </p-dropdown>
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-xl-3 col-lg-3 col-form-label">Email</label>
                    <div class="col-lg-6 col-xl-6">
                        <div class="input-group input-group-lg input-group-solid">
                            <input type="text" class="form-control form-control-lg form-control-solid" placeholder="Email" formControlName="email" ngDefaultControl />
                        </div>
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-xl-3 col-lg-3 col-form-label">Dob</label>
                    <div class="col-lg-6 col-xl-6">
                        <p-calendar
                            appendTo="body"
                            formControlName="dateOfBirth"
                            dateFormat="mm/dd/yy"
                            [showIcon]="true"
                            inputId="icon"
                            styleClass="w-100 input-group input-group-lg input-group-solid"
                        >
                        </p-calendar>
                    </div>
                </div>
            </div>
        </form>
    </ng-template>

    <ng-template pTemplate="footer">
        <button pButton pRipple label="Cancel" icon="pi pi-times" class="p-button-text text-secondary" (click)="displayEditModal = false"></button>
        <button pButton pRipple label="Update" icon="pi pi-check" class="p-button-text" [disabled]="!formGroup.valid" (click)="updateLead()"></button>
    </ng-template>
</p-dialog>
