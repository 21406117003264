import { Component, Input, OnInit } from '@angular/core';
import { UserTagViewModel } from '@app/lead/model/user-tag.model';
import { LeadListService } from '@app/lead/services/leadlist-service';
import { BaseComponentDirective } from '@core/component/base.component';
import { ConfirmationService } from 'primeng/api';
import { sortBy } from 'lodash';
import { Subscription } from 'rxjs';
import { DialerContentService } from '@app/shared/services/dialer-content-service';
import { Router } from '@angular/router';

@Component({
    selector: 'app-lead-tags',
    templateUrl: './lead-tags.component.html',
    styleUrls: ['./lead-tags.component.scss'],
})
export class LeadTagsComponent extends BaseComponentDirective implements OnInit {
    @Input() leadId: string;
    selectedTag: string;
    tagOptions: { name: string; code: string }[] = [];
    userTagsList: UserTagViewModel[] = [];
    dialerSubscribe: Subscription;
    constructor(private leadService: LeadListService, private router: Router, private confirmationService: ConfirmationService, private dialerContentService: DialerContentService) {
        super();
        this.dialerSubscribe = this.dialerContentService.getUpdate().subscribe((message) => {
            this.leadId = message.text;
            this.ngOnInit();
            const currentUrl = this.router.url;
            void this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
                void this.router.navigate([currentUrl]);
            });
        });
    }

    ngOnInit(): void {
        super.ngOnInit();
        this.getLeadsList();
        this.getUserTags();
    }

    getLeadsList() {
        this.leadService.getLeadTags().subscribe((res) => {
            this.tagOptions = [];
            const sortedArray = sortBy(res, (o) => {
                return o.name;
            });
            sortedArray.forEach((element) => {
                this.tagOptions.push({
                    name: element.name,
                    code: element.id,
                });
            });
        });
    }

    getUserTags() {
        this.leadService.getUserLeadTags(this.leadId).subscribe({
            next: (res) => {
                this.userTagsList = res;
                this.selectedTag = '';
            },
            error: () => {
                this.messageService.add({
                    key: 'tst',
                    severity: 'error',
                    summary: 'Error',
                    detail: 'something went wrong to load user tags, please try again',
                });
            },
        });
    }

    addTags(id: string, tagId: string) {
        this.leadService.addLeadTag(id, tagId).subscribe({
            next: () => {
                this.getUserTags();
                this.messageService.add({
                    key: 'tst',
                    severity: 'success',
                    summary: 'Success',
                    detail: 'Tag added successfully',
                });
            },
            error: () => {
                this.messageService.add({
                    key: 'tst',
                    severity: 'error',
                    summary: 'Error',
                    detail: 'something went wrong, please try again',
                });
            },
        });
    }

    deleteSelectedTag(id: string) {
        this.leadService.deleteLeadTag(this.leadId, id).subscribe({
            next: () => {
                this.getUserTags();
                this.messageService.add({
                    key: 'tst',
                    severity: 'success',
                    summary: 'Success',
                    detail: 'Tag deleted successfully',
                });
            },
            error: () => {
                this.messageService.add({
                    key: 'tst',
                    severity: 'error',
                    summary: 'Error',
                    detail: 'something went wrong, please try again',
                });
            },
        });
    }

    confirmAddTag(e) {
        const fitleredObj = this.userTagsList.find((val) => {
            return val.tag.id === e.value.code;
        });
        if (fitleredObj === undefined) {
            this.confirmationService.confirm({
                key: 'leadTags',
                message: 'Are you sure that you want to proceed?',
                header: 'Confirmation',
                icon: 'pi pi-exclamation-triangle',
                accept: () => {
                    this.addTags(this.leadId, e.value.code);
                },
                reject: () => {
                    this.selectedTag = '';
                },
            });
        } else {
            this.confirmationService.confirm({
                key: 'leadTags',
                message: 'Tag is already added, select someother tag',
                header: 'Confirmation',
                icon: 'pi pi-exclamation-triangle',
                accept: () => {
                    this.selectedTag = '';
                },
            });
        }
    }

    confirmTagsDelete(e) {
        this.confirmationService.confirm({
            key: 'leadTags',
            message: 'Are you sure that you want to delete the Tag?',
            header: 'Confirmation',
            icon: 'pi pi-exclamation-triangle',
            accept: () => {
                this.deleteSelectedTag(e.id);
            },
        });
    }
}
