import { Injectable } from '@angular/core';
import { HttpUtility } from '@utility/httpUtility';
import { environment } from '@environments/environment';
import { EditEventModel } from '@app/calendar/model/editEvent-model';
import { AddEventModel } from '@app/calendar/model/addEvent-model';

@Injectable({ providedIn: 'root' })
export class AddEventService {
    constructor(private httpUtility: HttpUtility) {}

    addCalendarEvent(addEventModel: AddEventModel) {
        return this.httpUtility.post(`${environment.apiUrl}/calendar-event/add`, addEventModel);
    }

    completeEvent(id: string) {
        return this.httpUtility.put(`${environment.apiUrl}/calendar-event/${id}/complete`, id);
    }

    deleteEvent(id: string) {
        return this.httpUtility.delete(`${environment.apiUrl}/calendar-event/${id}`);
    }

    updateEvent(id: string, editEventModel: EditEventModel) {
        return this.httpUtility.put(`${environment.apiUrl}/calendar-event/${id}`, editEventModel);
    }
}
