import { Injectable } from '@angular/core';
import { HttpUtility } from '@utility/httpUtility';
import { environment } from '@environments/environment';
import { Observable } from 'rxjs';
import { Bearertoken } from '@shared/authentication/model/bearer-token-model';

@Injectable({ providedIn: 'root' })
export class TokenRefreshService {
    constructor(private httpUtility: HttpUtility) {}

    getRefreshToken(id?: string): Observable<Bearertoken> {
        return this.httpUtility.post(`${environment.apiUrl}/refresh-token?id=${id}`, '');
    }
}
