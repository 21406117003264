import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from '@app/authentication/services/authentication.service';
import { UsersService } from '@app/users/services/user-service';
import { BaseComponentDirective } from '@core/component/base.component';
import { Bearertoken } from '@shared/authentication/model/bearer-token-model';
import { ImpersonateUser } from '@shared/model/impersonate-user.model';
import { MegaMenuItem, MenuItem } from 'primeng/api';
import { DialerState, DialerStateService } from '../services/dialer-service';
import { MenuService } from '../services/menu-service';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
})
export class HeaderComponent extends BaseComponentDirective implements OnInit {
    user: Bearertoken;
    // items: MegaMenuItem[];
    items: MegaMenuItem[];
    items1: MenuItem[];
    activeItem: MegaMenuItem;
    profileItems: MenuItem[];
    showDialer = false;
    searchText = '';
    impersonatingUser: string;
    showImpersonate: boolean;

    constructor(
        private authenticationService: AuthenticationService,
        private readonly menuService: MenuService,
        private router: Router,
        private dialerStateService: DialerStateService,
        private readonly usersService: UsersService,
    ) {
        super();
        this.authenticationService.user.subscribe((x) => {
            return (this.user = x);
        });

        this.dialerStateService.showDialerBar.subscribe((dialerState: DialerState) => {
            this.showDialer = dialerState.showDialerBar;
        });
    }

    logout() {
        this.authenticationService.logout();
        void this.router.navigate(['login']);
    }

    changePassword() {
        void this.router.navigate(['user-settings/change-password']);
    }
    profile() {
        void this.router.navigate(['/user-settings/profile-settings'], {
            queryParams: { userId: this.authenticationService.getLoggedInId() },
        });
    }

    search() {
        if (this.searchText !== '') {
            void this.router.navigate(['/search'], { queryParams: { searchKey: this.searchText } });
            this.searchText = '';
        }
    }

    ngOnInit() {
        super.ngOnInit();
        this.menuService.updateMenus();
        this.menuService.currentMenu.subscribe((menus) => {
            this.items = menus;
        });

        this.profileItems = [
            {
                label: 'My Profile',
                icon: 'pi pi-fw pi-user ',
                styleClass: 'align-menu-items',
                command: () => {
                    this.profile();
                },
            },
            {
                label: 'Change Password',
                icon: 'pi pi-fw pi-cog ',
                styleClass: 'align-menu-items',
                command: () => {
                    this.changePassword();
                },
            },
            {
                label: 'Logout',
                icon: 'pi pi-fw pi-sign-out ',
                styleClass: 'align-menu-items',
                command: () => {
                    this.logout();
                },
            },
        ];

        if (localStorage.hasOwnProperty('userImpersonate')) {
            this.showImpersonate = true;
            this.impersonatingUser = this.authenticationService.getLoggedInUserName();
            console.log('logged in user: ', this.authenticationService.getLoggedInUserName());
        } else {
            this.showImpersonate = false;
        }
    }

    revertUserImpersonate() {
        console.log('exit user impersonate');

        const getLastUser = JSON.parse(localStorage.getItem('LastUserImpersonate')) as ImpersonateUser;

        this.usersService
            .userImpersonate(getLastUser.id)
            .subscribe((user) => {
                localStorage.setItem('token', JSON.stringify(user));
                localStorage.removeItem('userImpersonate');
                this.authenticationService.userSubject.next(user);
                this.messageService.add({
                    key: 'tst',
                    severity: 'success',
                    summary: 'Success',
                    detail: 'User Impersonate reverted successful',
                });
                void this.router.navigate(['/dashboard'], {
                    queryParams: {
                        reload: true,
                    },
                });
            })
            .add(() => {
                this.loading = false;
            });
    }
}
